import React, { memo, useRef, useState } from 'react';
import { CaptureClick, Icon, OptionMenu } from '@cognitiv/cassiopeia-ui';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { faFolderPlus } from '@fortawesome/pro-thin-svg-icons';
import classNames from 'classnames';
import { workspace_actions_edit, workspace_actions_navigate } from 'products/taurus/overlays/manage-taurus-workspaces/constants';
import { useManageWorkspacesContext } from 'products/taurus/overlays/manage-taurus-workspaces/context/TaurusWorkspacesContext';

import cn from 'products/taurus/overlays/manage-taurus-workspaces/components/workspace-folder/WorkspaceFolder.module.scss';

const theme = { icon: { height: '50px', width: '50px', font_size: '40px' } };

export const WorkspaceFolder = memo(({ workspace_id, workspace_name }: { workspace_id?: number; workspace_name: string }) => {
  const [is_open, setOpen] = useState(false);
  const iconRef = useRef<HTMLDivElement | null>(null);

  const { onSelectCurrentWorkspaceMenuItem, onSelectCurrentWorkspace, onSelectWorkspace, createWorkspace, workspace } = useManageWorkspacesContext();

  if (!workspace_id) {
    return (
      <div className={classNames(cn.folder, cn.empty)} onClick={() => createWorkspace()}>
        <Icon icon={faFolderPlus} theme={theme} />
        <p>{workspace_name}</p>
      </div>
    );
  }

  return (
    <div
      className={classNames(cn.folder, { [cn.selected]: workspace_id === workspace.workspace_id })}
      onClick={() => onSelectCurrentWorkspace(workspace_id)}
      onContextMenu={(e) => {
        e.preventDefault();
        onSelectCurrentWorkspace(workspace_id);
        setOpen(true);
      }}
      onDoubleClick={() => onSelectWorkspace(workspace_id)}
    >
      <Icon icon={faFolder} theme={theme} />
      <p>{workspace_name}</p>
      <CaptureClick
        ref={iconRef}
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div className={cn.dropdown} ref={iconRef} style={{ visibility: is_open ? 'visible' : 'hidden' }}>
          <div className={cn.wrapper}>
            {workspace_actions_edit.map((item) => {
              return (
                <OptionMenu
                  key={item.uuid}
                  icon={item.icon}
                  value={item.action}
                  onSelect={() => onSelectCurrentWorkspaceMenuItem(workspace_id, item)}
                />
              );
            })}
            <div className={cn.break} />
            <div className={cn.groupName}>Navigate</div>
            {workspace_actions_navigate.map((item) => {
              return (
                <OptionMenu
                  key={item.uuid}
                  icon={item.icon}
                  value={item.action}
                  onSelect={() => onSelectCurrentWorkspaceMenuItem(workspace_id, item)}
                />
              );
            })}
          </div>
        </div>
      </CaptureClick>
    </div>
  );
});
