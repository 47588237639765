import { TaurusOneHotMapping, TaurusOneHotMappingEntry } from '@cognitiv/galaxy-api';

export const taurus_one_hot_mapping_default: TaurusOneHotMapping = {
  entries: [],
  unique_feature_name: '',
  one_hot_mapping_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  one_hot_mapping_name: '',
  feature_identifier: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  is_feature_default: false,
  created_datetime_formatted: '',
  uuid: '',
};

export const taurus_one_hot_mapping_entry_default: TaurusOneHotMappingEntry = {
  one_hot_mapping_id: 0,
  key: '',
  mapped_index: null,
  mapped_index_formatted: '',
  description: '',
  saved: false,
  uuid: '',
};
