import { TaurusItemType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusItemType[] = [];

export const taurusItemTypesListSlice = createSlice({
  name: SLICE.TAURUS_ITEM_TYPES_LIST,
  initialState,
  reducers: {
    clearTaurusItemTypesList: () => initialState,
    setTaurusItemTypesList: (_, action: PayloadAction<TaurusItemType[]>) => action.payload,
  },
});

export const { clearTaurusItemTypesList, setTaurusItemTypesList } = taurusItemTypesListSlice.actions;

export default taurusItemTypesListSlice.reducer;
