export enum TAURUS_DATA_TYPE {
  STRING = 1,
  BOOLEAN = 2,
  BYTE = 3,
  INT_32 = 4,
  INT_64 = 5,
  UINT_16 = 6,
  UINT_32 = 7,
  UINT_64 = 8,
  FLOAT_32 = 9,
  FLOAT_64 = 10,
}
export const TAURUS_DATA_TYPE_NAME = {
  1: 'String',
  2: 'Boolean',
  3: 'Byte',
  4: 'Int32',
  5: 'Int64',
  6: 'UInt16',
  7: 'UInt32',
  8: 'UInt64',
  9: 'Float32',
  10: 'Float64',
};
