import React from 'react';
import classNames from 'classnames';
import { useMenuGroupContext } from 'components/menu/components/MenuGroup';
import { useMenuContext } from 'components/menu/context/MenuContext';
import { MenuFixedProps } from 'components/menu/types';
import { Link } from 'react-router-dom';

import cn from 'components/menu/components/Components.module.scss';

export const MenuFixed = ({ children, to, selected }: MenuFixedProps) => {
  const { label } = useMenuGroupContext();
  const { handleExpand } = useMenuContext();

  return (
    <div className={cn.menu} data-role="menu">
      <Link to={to} onClick={handleExpand}>
        <p className={classNames(cn.title, { [cn.selected]: selected })}>{label}</p>
      </Link>
      {children && (
        <>
          <div className={cn.border} />
          <div className={cn.content}>{children}</div>
        </>
      )}
    </div>
  );
};
