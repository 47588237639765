import { TaurusDatasetReadItem } from '@cognitiv/galaxy-api';

export const taurus_dataset_read_item_default: TaurusDatasetReadItem = {
  columns: [],
  dimensions: [],
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  partition_count: null,
  partition_name: null,
  partition_column_id: null,
  partition_column_name: '',
  attributes: [],
  parameters: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_id: 0,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: true,
  description: '',
  is_definition: true,
  parent_item_ids: [],
  dataset_id: 0,
  glue_table_name: null,
  row_count: 0,
  row_count_formatted: '0',
  output_type_id: 0,
  output_type_name: '',
  output_compression_format_id: 0,
  output_compression_format_name: '',
  managed_dataset_id: null,
  managed_dataset_name: '',
  dataset_definition_pipeline_id: 0,
  dataset_definition_pipeline_name: '',
  dataset_definition_pipeline_workspace_id: 0,
  dataset_definition_pipeline_workspace_name: '',
  refresh_partitions_after_days: null,
  refresh_partitions_after_days_formatted: '',
  fail_on_missing_partitions: true,
  created_datetime_formatted: '',
  saved: false,
  uuid: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  row_type_id: null,
  job_parameters: [],
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};
