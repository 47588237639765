import React, { lazy, Suspense as ReactSuspense } from 'react';
import { Loading } from 'components/layout/loading/Loading';
import { LOADING_VARIANTS } from 'components/layout/loading/types';

interface SuspenseProps {
  element: string;
}

export const Suspense = ({ element }: SuspenseProps) => {
  const LazyComponent = lazy(() => import(`./${element}`));

  return (
    <ReactSuspense fallback={<Loading variant={LOADING_VARIANTS.LIGHT} />}>
      <LazyComponent />
    </ReactSuspense>
  );
};
