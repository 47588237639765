import { TaurusSortDirection } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusSortDirection[] = [];

export const taurusSortDirectionsListSlice = createSlice({
  name: SLICE.TAURUS_SORT_DIRECTIONS_LIST,
  initialState,
  reducers: {
    clearTaurusSortDirectionsList: () => initialState,
    setTaurusSortDirectionsList: (_, action: PayloadAction<TaurusSortDirection[]>) => action.payload,
  },
});

export const { clearTaurusSortDirectionsList, setTaurusSortDirectionsList } = taurusSortDirectionsListSlice.actions;

export default taurusSortDirectionsListSlice.reducer;
