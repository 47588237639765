export enum AUTHENTICATION_RESULTS {
  UNKNOWN = 0,
  SUCCESS = 1,
  INVALID_LOGIN = 2,
  ACCOUNT_INACTIVE = 3,
  ACCOUNT_LOCKED = 4,
  INVALID_PASSWORD = 5,
  INVALID_ORGANIZATION = 6,
  HASH_MISMATCH = 7,
  INVALID_PRIVATE_KEY = 8,
  NO_DEFAULT_ORGANIZATION = 9,
  SERVICE_DENIED_LOGIN = 10,
}

export const AUTHENTICATION_ERRORS = {
  0: 'unknown',
  1: 'success login',
  2: 'invalid login attempt',
  3: 'account is inactive',
  4: 'account is locked',
  5: 'invalid password',
  6: 'invalid organization',
  7: 'hash mismatch',
  8: 'invalid private key',
  9: 'no default organization',
  10: 'service denied login',
};
