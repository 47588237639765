import { EMPTY_ARRAY, EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { taurus_workspace_default } from 'products/taurus/operators/workspace/defaults';
import { TaurusWorkspaceContextProps } from 'products/taurus/overlays/manage-taurus-workspaces/context/types';

export const taurus_workspace_context_initial_state: TaurusWorkspaceContextProps = {
  is_loading_children: false,
  is_loading_workspace: false,
  workspace: { ...taurus_workspace_default },
  current_workspace_index: TAURUS_ROOT,
  current_workspace: { ...taurus_workspace_default },
  current_workspace_children: EMPTY_ARRAY,
  onWorkspacePrev: EMPTY_FUNCTION,
  onWorkspaceNext: EMPTY_FUNCTION,
  onChangeWorkspace: EMPTY_FUNCTION,
  onSelectWorkspace: EMPTY_FUNCTION,
  onSelectCurrentWorkspace: EMPTY_FUNCTION,
  onSelectCurrentWorkspaceMenuItem: EMPTY_FUNCTION,
  editWorkspace: EMPTY_FUNCTION,
  createWorkspace: EMPTY_FUNCTION,
  onClose: EMPTY_FUNCTION,
  onSubmit: EMPTY_FUNCTION,
};
