export const PUBLIC = {
  ROOT: {
    path: '/',
    element: null,
  },
  LOGIN: {
    path: '/login',
    element: null,
  },
};

export const PRIVATE = {
  ROOT: {
    path: '/',
    element: null,
  },
};

export const TAURUS = {
  APPLICATION: {
    path: '/applications/taurus',
    element: null,
    page_type_id: null,
  },
  WORKSPACES: {
    path: 'workspaces',
    element: 'products/taurus/pages/workspaces/WorkspacesPage',
    page_type_id: null,
    helmet: 'Taurus Workspaces',
  },
  PIPELINE_QUERY_EXECUTIONS: {
    path: 'pipeline-item-queue',
    element: 'products/taurus/pages/pipeline-query-executions/PipelineQueryExecutions',
    page_type_id: null,
    helmet: 'Taurus Pipeline Query Executions',
  },
  PIPELINE_ITEMS: {
    path: 'pipeline-items',
    element: 'products/taurus/pages/pipeline-items/PipelineItemsPage',
    page_type_id: null,
    helmet: 'Taurus Pipeline Items',
  },
  PIPELINES: {
    path: 'pipelines',
    element: 'products/taurus/pages/pipelines/PipelinesPage',
    page_type_id: null,
    helmet: 'Taurus Pipelines',
  },
  PIPELINE: {
    path: 'pipelines/:pipeline_id',
    element: 'products/taurus/pages/pipeline/PipelinePage',
    page_type_id: null,
    helmet: 'Taurus Pipeline',
  },
  MODELS: {
    path: 'models',
    element: 'products/taurus/pages/models/ModelsPage',
    page_type_id: null,
    helmet: 'Taurus Models',
  },
  MODEL: {
    path: 'models/:model_id',
    element: 'products/taurus/pages/model/template/ModelTemplate',
    page_type_id: null,
    helmet: 'Taurus Model',
    DEFAULT: {
      path: '',
      element: 'products/taurus/pages/model/folders/versions/VersionsPage',
      page_type_id: null,
    },
    VERSIONS: {
      path: 'versions',
      element: 'products/taurus/pages/model/folders/versions/VersionsPage',
      page_type_id: null,
    },
    OUTPUTS: {
      path: 'outputs',
      element: 'products/taurus/pages/model/folders/outputs/OutputsPage',
      page_type_id: null,
    },
  },
  PIPELINE_DEFINITIONS: {
    path: 'pipeline-definitions',
    element: 'products/taurus/pages/pipeline-definitions/PipelineDefinitionsPage',
    page_type_id: null,
    helmet: 'Taurus Pipeline Definitions',
  },
  PIPELINE_DEFINITION: {
    path: 'pipeline-definitions/:pipeline_id',
    element: 'products/taurus/pages/pipeline-definition/PipelineDefinitionPage',
    page_type_id: null,
    helmet: 'Taurus Pipeline Definition',
  },
  SQL_TEMPLATES: {
    path: 'sql-templates',
    element: 'products/taurus/pages/sql-templates/SqlTemplatesPage',
    page_type_id: null,
    helmet: 'Taurus SQL Templates',
  },

  ONE_HOT_MAPPINGS: {
    path: 'one-hot-mappings',
    element: 'products/taurus/pages/one-hot-mappings/OneHotMappingsPage',
    page_type_id: null,
    helmet: 'Taurus One Hot Mappings',
  },
  DATASETS: {
    path: 'datasets',
    element: 'products/taurus/pages/datasets/DatasetsPage',
    page_type_id: null,
    helmet: 'Taurus Datasets',
  },
};
