import { TaurusDataset, TaurusDatasetColumn, TaurusDimension } from '@cognitiv/galaxy-api';
import { TAURUS_COMPRESSION_TYPE, TAURUS_COMPRESSION_TYPE_NAME } from 'products/taurus/operators/compression-formats-list/enums';
import { TAURUS_OUTPUT_TYPE, TAURUS_OUTPUT_TYPE_NAME } from 'products/taurus/operators/output-types-list/enums';
import { TAURUS_ROW_TYPE } from 'products/taurus/operators/row-types-list/enums';

export const taurus_dataset_dimension_default: TaurusDimension = {
  dataset_partition_dimension_id: null,
  dataset_id: null,
  dimension_index: null,
  dimension_index_formatted: '',
  dimension_name: '',
  saved: false,
  uuid: '',
};

export const taurus_dataset_column_default: TaurusDatasetColumn = {
  feature_name: '',
  unique_feature_name: '',
  column_id: 0,
  column_name: '',
  dataset_id: null,
  column_index: null,
  column_index_formatted: '',
  column_type_name: '',
  column_type_id: 0,
  feature_identifier: null,
  label_type_name: '',
  label_type_id: null,
  label_group: null,
  data_type_name: '',
  data_type_id: null,
  extended_data: '',
  array_length: null,
  array_length_formatted: '',
  is_array: false,
  saved: false,
  partition_on: false,
  partition_count: null,
  partition_count_formatted: '',
  partition_name: null,
  uuid: '',
};

export const taurus_dataset_default: TaurusDataset = {
  dataset_name: '',
  dataset_id: 0,
  definition_pipeline_name: '',
  definition_pipeline_id: null,
  row_type_id: TAURUS_ROW_TYPE.IMPRESSION,
  output_type_name: TAURUS_OUTPUT_TYPE_NAME[TAURUS_OUTPUT_TYPE.PARQUET],
  output_type_id: TAURUS_OUTPUT_TYPE.PARQUET,
  output_compression_format_name: TAURUS_COMPRESSION_TYPE_NAME[TAURUS_COMPRESSION_TYPE.SNAPPY],
  output_compression_format_id: TAURUS_COMPRESSION_TYPE.SNAPPY,
  partition_name: null,
  partition_count: 10,
  columns: [],
  dimensions: [],
  row_count: 0,
  row_count_formatted: '0',
  glue_table_name: null,
  s3_location_prefix: '',
  workspace_id: 0,
  workspace_name: '',
  is_archived: false,
  s3_bucket: '',
  s3_prefix: '',
  is_managed: false,
  created_by_user_id: 0,
  created_by: '',
  created_date: '',
  created_datetime_formatted: '',
  write_lock_item_id: 0,
  saved: false,
  uuid: '',
};
