import { TaurusPipeline } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_pipeline_default } from 'products/taurus/operators/pipeline/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPipeline = { ...taurus_pipeline_default };

export const taurusPipelineSlice = createSlice({
  name: SLICE.TAURUS_PIPELINE,
  initialState,
  reducers: {
    clearTaurusPipeline: () => initialState,
    setTaurusPipeline: (_, action: PayloadAction<TaurusPipeline>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusPipeline, setTaurusPipeline } = taurusPipelineSlice.actions;

export default taurusPipelineSlice.reducer;
