import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { parseQueries } from '@cognitiv/cicada-ui';
import { faBook, faChartNetwork, faCircleDown, faClock, faCube, faKeyboard, faRandom } from '@fortawesome/pro-regular-svg-icons';
import { faBallPile, faBars } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { MenuAction } from 'components/menu/components/MenuAction';
import { MenuGroup } from 'components/menu/components/MenuGroup';
import { MenuPage } from 'components/menu/components/MenuPage';
import { MenuSwitch } from 'components/menu/components/MenuSwitch';
import { useMenuContext } from 'components/menu/context/MenuContext';
import logoClose from 'components/menu/logo/logo-animation-close.json';
import logoOpen from 'components/menu/logo/logo-animation-open.json';
import { selectSettings } from 'ducks/settings/selectors';
import { PWA_LOCAL_STORAGE_KEY } from 'hooks/useAddToHomeScreenPrompt';
import { THEME, useGlobalTheme } from 'hooks/useGlobalTheme';
import { GROUP, PAGE_STORAGE } from 'hooks/useMenu';
import { useQuerySave } from 'hooks/useQuerySave';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { selectTaurusWorkspace } from 'products/taurus/operators/workspace/selectors';
import { Link, useLocation } from 'react-router-dom';
import { AutoSizer } from 'react-virtualized';
import { TAURUS } from 'routes';
import { useAppSelector } from 'store/hooks';
import { Storage } from 'utils';

import cn from 'components/menu/Menu.module.scss';

const { REACT_APP_VERSION } = process.env;

export const Menu = () => {
  const { theme } = useAppSelector(selectSettings);
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const { search } = useLocation();
  const { workspace_id } = parseQueries(search);

  const taurus_workspace = useAppSelector(selectTaurusWorkspace);

  const [hidden, setHidden] = useState(false);

  const { getDefaultQueries } = useQuerySave();

  const { group, getApplicationPage, is_menu_open, setMenu, is_installed } = useMenuContext();
  const [install_pwa, setInstallPWA] = useState(false);

  const { switchTheme } = useGlobalTheme();

  useEffect(() => {
    const is_pwa_installed = localStorage.getItem(PWA_LOCAL_STORAGE_KEY);
    if (!is_pwa_installed && is_installed) {
      setInstallPWA(true);
    }
  }, [is_installed]);

  const installPWA = useCallback(async () => {
    const result = await is_installed?.prompt();
    if (result?.outcome === 'accepted') {
      localStorage.setItem(PWA_LOCAL_STORAGE_KEY, 'true');
      setInstallPWA(false);
    }
  }, [is_installed]);

  const active_workspace_id = useMemo((): number => {
    if (workspace_id) return Number(workspace_id);
    return taurus_workspace.workspace_id;
  }, [taurus_workspace, workspace_id]);

  const setSearch = useCallback(
    (entity: { page: string; storage: Storage }) => {
      switch (entity.storage) {
        case PAGE_STORAGE.WORKSPACES:
        case PAGE_STORAGE.PIPELINES:
        case PAGE_STORAGE.PIPELINE_DEFINITIONS:
        case PAGE_STORAGE.PIPELINE_ITEMS:
        case PAGE_STORAGE.MODELS:
        case PAGE_STORAGE.DATASETS: {
          const queries = getDefaultQueries(entity.storage, active_workspace_id || TAURUS_ROOT);
          return `${entity.page}${queries}`;
        }
      }
      const queries = getDefaultQueries(entity.storage, TAURUS_ROOT);
      return `${entity.page}${queries}`;
    },
    [active_workspace_id, getDefaultQueries],
  );

  useEffect(() => {
    setTimeout(() => setHidden(true), 300);
  }, [is_menu_open]);

  useEffect(() => {
    // DEV-NOTE: Prevent animation from jumping on first render by setting it to the end position
    if (lottieRef.current) {
      const { goToAndStop } = lottieRef.current;
      goToAndStop(is_menu_open ? 250 : 200);
    }
  }, []);

  return (
    <nav className={classNames(cn.menu, { [cn.open]: is_menu_open, [cn.hidden]: hidden })}>
      <Link
        className={classNames(cn.image, { [cn.open]: is_menu_open })}
        to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}`, storage: PAGE_STORAGE.PIPELINES })}
      >
        <Lottie lottieRef={lottieRef} animationData={is_menu_open ? logoOpen : logoClose} loop={0} style={{ width: 200, height: 50 }} />
      </Link>
      <div className={cn.toggle}>
        <MenuAction
          onClick={() => {
            setHidden(false);
            setMenu(!is_menu_open);
          }}
          label={is_menu_open ? 'Collapse Menu' : 'Expand Menu'}
          icon={faBars}
        />
      </div>
      <div className={classNames(cn.grow, cn.mainMenuItems)}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <div className={cn.position} style={{ height, width }}>
                <MenuGroup
                  label="Workspaces"
                  to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.WORKSPACES.path}`, storage: PAGE_STORAGE.WORKSPACES })}
                  icon={faCube}
                  selected={group === GROUP.WORKSPACES}
                  active={getApplicationPage(3) === TAURUS.WORKSPACES.path}
                />
                <MenuGroup
                  label="Models"
                  to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.MODELS.path}`, storage: PAGE_STORAGE.MODELS })}
                  icon={faChartNetwork}
                  selected={group === GROUP.MODELS}
                  active={getApplicationPage(3) === TAURUS.MODELS.path}
                />
                <MenuGroup
                  label="Pipelines"
                  to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}`, storage: PAGE_STORAGE.PIPELINES })}
                  icon={faBallPile}
                  selected={group === GROUP.PIPELINES}
                  active={getApplicationPage(3) === TAURUS.PIPELINES.path}
                >
                  <MenuPage
                    label="Pipeline Definitions"
                    to={setSearch({
                      page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_DEFINITIONS.path}`,
                      storage: PAGE_STORAGE.PIPELINE_DEFINITIONS,
                    })}
                    selected={getApplicationPage(3) === TAURUS.PIPELINE_DEFINITIONS.path}
                  />
                  <MenuPage
                    label="Pipeline Items"
                    to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_ITEMS.path}`, storage: PAGE_STORAGE.PIPELINE_ITEMS })}
                    selected={getApplicationPage(3) === TAURUS.PIPELINE_ITEMS.path}
                  />
                </MenuGroup>
                <MenuGroup
                  label="Pipeline Query Executions"
                  to={setSearch({
                    page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_QUERY_EXECUTIONS.path}`,
                    storage: PAGE_STORAGE.PIPELINE_QUERY_EXECUTIONS,
                  })}
                  icon={faClock}
                  selected={group === GROUP.PIPELINE_QUERY_EXECUTIONS}
                  active={getApplicationPage(3) === TAURUS.PIPELINE_QUERY_EXECUTIONS.path}
                />
                <MenuGroup
                  label="One Hot Mappings"
                  to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.ONE_HOT_MAPPINGS.path}`, storage: PAGE_STORAGE.ONE_HOT_MAPPINGS })}
                  icon={faRandom}
                  selected={group === GROUP.ONE_HOT_MAPPINGS}
                  active={getApplicationPage(3) === TAURUS.ONE_HOT_MAPPINGS.path}
                />
                <MenuGroup
                  label="SQL Templates"
                  to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.SQL_TEMPLATES.path}`, storage: PAGE_STORAGE.SQL_TEMPLATES })}
                  icon={faKeyboard}
                  selected={group === GROUP.SQL_TEMPLATES}
                  active={getApplicationPage(3) === TAURUS.SQL_TEMPLATES.path}
                />
                <MenuGroup
                  label="Datasets"
                  to={setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.DATASETS.path}`, storage: PAGE_STORAGE.DATASETS })}
                  icon={faBook}
                  selected={group === GROUP.DATASETS}
                  active={getApplicationPage(3) === TAURUS.DATASETS.path}
                />
              </div>
            );
          }}
        </AutoSizer>
      </div>
      <div className={cn.break} />
      {install_pwa && <MenuAction label="Install PWA" icon={faCircleDown} onClick={installPWA} />}
      <div className={cn.break} />
      <MenuAction onClick={switchTheme} label={`${theme === THEME.DARK ? 'Light' : 'Dark'} Mode`} className={cn.switchWrapper}>
        <MenuSwitch checked={theme === THEME.DARK} />
      </MenuAction>
      <span className={classNames(cn.version, { [cn.open]: is_menu_open })}>v {REACT_APP_VERSION}</span>
    </nav>
  );
};
