export enum SLICE {
  TAURUS_ITEM_CATEGORICAL_STATISTICS = 'taurus-item-categorical-statistics',
  TAURUS_COLUMN_TYPES_LIST = 'taurus-column-types-list',
  TAURUS_COMPRESSION_FORMATS_LIST = 'taurus-compression-formats-list',
  TAURUS_CUSTOM_ITEM = 'taurus-custom-item',
  TAURUS_CUSTOM_ETL_ITEM = 'taurus-custom-etl-item',
  TAURUS_DATA_TYPES_LIST = 'taurus-data-types-list',
  TAURUS_DATASET = 'taurus-dataset',
  TAURUS_DATASET_READ_ITEM = 'taurus-dataset-read-item',
  TAURUS_DATASET_WRITE_ITEM = 'taurus-dataset-write-item',
  TAURUS_DATASETS = 'taurus-datasets',
  TAURUS_DATASET_PARTITIONS = 'taurus-dataset-partitions',
  TAURUS_EVALUATE_ITEM = 'taurus-evaluate-item',
  TAURUS_EVALUATION_ALGORITHMS_LIST = 'taurus-evaluation-algorithms-list',
  TAURUS_ONE_HOT_MAPPING_FEATURES_LIST = 'taurus-one-hot-mapping-features-list',
  TAURUS_FEATURES_LIST = 'taurus-features-list',
  TAURUS_FILTER_OPERATOR_TYPES = 'taurus-filter-operator-types',
  TAURUS_IMPORT_ITEM = 'taurus-import-item',
  TAURUS_ITEM = 'taurus-item',
  TAURUS_DATASET_PREVIEW = 'taurus-dataset-preview',
  TAURUS_ITEM_STATES_LIST = 'taurus-item-states-list',
  TAURUS_ITEM_TYPES_LIST = 'taurus-item-types-list',
  TAURUS_ITEMS = 'taurus-items',
  TAURUS_LABEL_TYPES_LIST = 'taurus-label-types-list',
  TAURUS_SQL_TEMPLATE_ENGINES_LIST = 'taurus-sql-template-engines-list',
  TAURUS_SQL_ENGINES_LIST = 'taurus-sql-engines-list',
  TAURUS_QUERY_ENGINES_LIST = 'taurus-query-engines-list',
  TAURUS_SEARCH_ALGORITHMS_LIST = 'taurus-search-algorithms-list',
  TAURUS_SPLIT_METHODS_LIST = 'taurus-split-methods-list',
  TAURUS_ROW_TYPES_LIST = 'taurus-row-types-list',
  TAURUS_PARTITION_RESOLUTION_COLLISIONS_LIST = 'taurus-partition-collision-resolutions-list',
  TAURUS_OUTPUT_TYPES_LIST = 'taurus-output-types-list',
  TAURUS_MODEL = 'taurus-model',
  TAURUS_MODELS = 'taurus-models',
  TAURUS_MODEL_VERSIONS = 'taurus-model-versions',
  TAURUS_ITEM_NUMERICAL_STATISTICS = 'taurus-item-numerical-statistics',
  TAURUS_ONE_HOT_MAPPING = 'taurus-one-hot-mapping',
  TAURUS_ONE_HOT_MAPPINGS = 'taurus-one-hot-mappings',
  TAURUS_PARENT_ITEMS = 'taurus-parent-items',
  TAURUS_PIPELINES = 'taurus-pipelines',
  TAURUS_PIPELINE = 'taurus-pipeline',
  TAURUS_PIPELINE_ITEMS = 'taurus-pipeline-items',
  TAURUS_PIPELINE_SCHEDULE = 'taurus-pipeline-schedule',
  TAURUS_PIPELINE_EVENTS_LIST = 'taurus-pipeline-events-list',
  TAURUS_PIPELINE_STATES_LIST = 'taurus-pipeline-states-list',
  TAURUS_QUERY_EXECUTIONS = 'taurus-query-executions',
  TAURUS_ITEM_QUEUES = 'taurus-item-queues',
  TAURUS_PROJECTION_ITEM_FEATURES = 'taurus-projection-item-features',
  TAURUS_PUBLISH_ITEM = 'taurus-publish-item',
  TAURUS_STRATEGY_PUBLISHER_ITEM = 'taurus-strategy-publisher-item',
  TAURUS_QUERY_SQL_PREVIEW = 'taurus-query-sql-preview',
  TAURUS_PROJECTION_ITEM = 'taurus-projection-item',
  TAURUS_QUERY_ITEM = 'taurus-query-item',
  TAURUS_NON_ETL_QUERY_ITEM = 'taurus-non-etl-query-item',
  TAURUS_SORT_DIRECTIONS_LIST = 'taurus-sort-directions-list',
  TAURUS_SQL_TEMPLATE = 'taurus-sql-template',
  TAURUS_SQL_TEMPLATES = 'taurus-sql-templates',
  TAURUS_SUBSCRIPTIONS = 'taurus-subscriptions',
  TAURUS_TRAIN_ITEM = 'taurus-train-item',
  TAURUS_TRANSFORM_ITEM = 'taurus-transform-item',
  TAURUS_WORKSPACE = 'taurus-workspace',
  TAURUS_WORKSPACES = 'taurus-workspaces',
  TAURUS_PREDICT_ITEM = 'taurus-predict-item',
}
