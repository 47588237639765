import { TaurusModelVersion } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusModelVersion[] = [];

export const taurusModelVersionsSlice = createSlice({
  name: SLICE.TAURUS_MODEL_VERSIONS,
  initialState,
  reducers: {
    clearTaurusModelVersions: () => initialState,
    setTaurusModelVersions: (_, action: PayloadAction<TaurusModelVersion[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusModelVersions, setTaurusModelVersions } = taurusModelVersionsSlice.actions;

export default taurusModelVersionsSlice.reducer;
