import React from 'react';
import { Action } from '@cognitiv/cassiopeia-ui';
import { faChevronRight, faFolder } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'hooks/useTheme';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { TruncatedList } from 'products/taurus/overlays/manage-taurus-workspaces/components/truncated-list/TruncatedList';
import { useManageWorkspacesContext } from 'products/taurus/overlays/manage-taurus-workspaces/context/TaurusWorkspacesContext';

import cn from 'products/taurus/overlays/manage-taurus-workspaces/components/workspace-path/WorkspacePath.module.scss';

export const WorkspacePath = () => {
  const {
    theme: { action },
  } = useTheme();
  const { onSelectWorkspace, current_workspace } = useManageWorkspacesContext();

  const workspace_length = current_workspace.workspace_path_split.length - 1;

  const renderItem = (workspace_name: string, index: number) => (
    <div key={index} className={cn.item}>
      <Action
        variant="plain"
        left_icon={faFolder}
        onClick={() => onSelectWorkspace(Number(current_workspace.workspace_id_path_split[index] || TAURUS_ROOT))}
        theme={workspace_name === current_workspace.workspace_name ? action.link : action.normal}
      >
        {workspace_name}
      </Action>
      {workspace_length !== index && <FontAwesomeIcon icon={faChevronRight} />}
    </div>
  );

  const renderTruncator = () => (
    <div className={cn.item}>
      <Action
        variant="plain"
        left_icon={faFolder}
        onClick={() => onSelectWorkspace(Number(current_workspace.workspace_id_path_split[workspace_length - 1] || TAURUS_ROOT))}
        theme={action.normal}
      >
        ...
      </Action>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );

  return (
    <div className={cn.wrapper}>
      <TruncatedList items={current_workspace.workspace_path_split} renderItem={renderItem} renderTruncator={renderTruncator} />
    </div>
  );
};
