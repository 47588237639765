import { TaurusPipeline } from '@cognitiv/galaxy-api';
import { TAURUS_PIPELINE_STATE, TAURUS_PIPELINE_STATE_NAME } from 'products/taurus/operators/enums';

export const taurus_pipeline_default: TaurusPipeline = {
  pipeline_id: 0,
  pipeline_name: '',
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  start_date_utc: null,
  finish_date_utc: null,
  items: [],
  definition_pipeline_id: null,
  definition_pipeline_name: '',
  batch_id: null,
  is_archived: false,
  description: '',
  is_definition: false,
  batch_definition_id: null,
  scheduled_batch_id: null,
  is_scheduled: false,
  last_run_date: null,
  query_priority_level_id: null,
  query_priority_level_name: '',
  pipeline_state_id: TAURUS_PIPELINE_STATE.INCOMPLETE,
  pipeline_state_name: TAURUS_PIPELINE_STATE_NAME[TAURUS_PIPELINE_STATE.INCOMPLETE],
  is_locked: false,
  bytes_scanned_limit: null,
  query_duration_seconds_limit: null,
  parameters: [],
  created_by: '',
  created_date: '',
  duration_seconds_formatted: '',
  created_datetime_formatted: '',
  start_datetime_formatted: '',
  finish_datetime_formatted: '',
  last_run_datetime_formatted: '',
  pipeline_state_name_formatted: '',
  duration_seconds: 0,
  saved: false,
  uuid: '',
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};
