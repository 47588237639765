import { TaurusWorkspace } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';
import { taurus_workspace_default } from 'products/taurus/operators/workspace/defaults';

const initialState: TaurusWorkspace = { ...taurus_workspace_default };

export const taurusWorkspaceSlice = createSlice({
  name: SLICE.TAURUS_WORKSPACE,
  initialState,
  reducers: {
    clearTaurusWorkspace: () => initialState,
    setTaurusWorkspace: (_, action: PayloadAction<TaurusWorkspace>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusWorkspace, setTaurusWorkspace } = taurusWorkspaceSlice.actions;

export default taurusWorkspaceSlice.reducer;
