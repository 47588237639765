import { TaurusProjectionItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_projection_item_default } from 'products/taurus/operators/projection-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusProjectionItem>;
}

const initialState: TaurusProjectionItem = { ...taurus_projection_item_default };

export const taurusProjectionItemSlice = createSlice({
  name: SLICE.TAURUS_PROJECTION_ITEM,
  initialState,
  reducers: {
    updateTaurusProjectionItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusProjectionItem: () => initialState,
    setTaurusProjectionItem: (_, action: PayloadAction<TaurusProjectionItem>) => action.payload,
  },
});

export const { updateTaurusProjectionItem, clearTaurusProjectionItem, setTaurusProjectionItem } = taurusProjectionItemSlice.actions;

export default taurusProjectionItemSlice.reducer;
