import { TaurusTransformItem, TaurusTransformItemColumn } from '@cognitiv/galaxy-api';
import { TAURUS_ALLOCATION_TYPE, TAURUS_ALLOCATION_TYPE_NAME } from 'products/taurus/operators/allocation-types/enum';
import { TAURUS_NULL_RESOLUTION, TAURUS_NULL_RESOLUTION_NAME } from 'products/taurus/operators/null-resolutions/enums';
import { TAURUS_OUTPUT_TYPE, TAURUS_OUTPUT_TYPE_NAME } from 'products/taurus/operators/output-types-list/enums';
import { TAURUS_NULL } from 'products/taurus/operators/sort-directions-list/enums';
import { TransformFold } from 'products/taurus/operators/transform-item/types';

export const taurus_transform_fold_default: TransformFold = {
  allocation_type_id: TAURUS_ALLOCATION_TYPE.COUNT,
  allocation_type_name: TAURUS_ALLOCATION_TYPE_NAME[TAURUS_ALLOCATION_TYPE.COUNT],
  test_fold_count: 0,
  test_fold_count_formatted: '0%',
  validate_fold_count: 0,
  validate_fold_count_formatted: '0%',
  train_fold_count: 0,
  train_fold_count_formatted: '0%',
};

export const taurus_transform_item_default: TaurusTransformItem = {
  dimensions: [],
  columns: [],
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  train_folds: '',
  validate_folds: '',
  test_folds: '',
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: '',
  is_definition: false,
  parent_item_ids: [],
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  dataset_id: 0,
  combine_features_labels: false,
  is_shuffled: false,
  randomize_folds_on_copy: false,
  split_by_feature_label: false,
  definition_item_id: null,
  s3_bucket: '',
  s3_prefix: '',
  partition_name: null,
  partition_count: null,
  glue_table_name: null,
  output_type_id: TAURUS_OUTPUT_TYPE.TFRECORD,
  output_type_name: TAURUS_OUTPUT_TYPE_NAME[TAURUS_OUTPUT_TYPE.TFRECORD],
  oversampling_rate: 0,
  oversampling_rate_formatted: '0%',
  output_compression_format_id: 0,
  output_compression_format_name: '',
  row_count: 0,
  row_count_formatted: '0',
  created_datetime_formatted: '',
  saved: false,
  uuid: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  partition_column_id: null,
  partition_column_name: '',
  row_type_id: null,
  compute_costs_estimate_usd: null,
  compute_costs_estimate_usd_formatted: '',
};

export const taurus_transform_column_default: TaurusTransformItemColumn = {
  feature_name: '',
  unique_feature_name: '',
  partition_count_formatted: '',
  partition_on: false,
  partition_name: null,
  partition_count: null,
  column_id: 0,
  dataset_id: null,
  column_index: null,
  column_type_id: 0,
  column_type_name: '',
  column_name: '',
  feature_identifier: '',
  extended_data: '',
  label_type_id: null,
  label_type_name: '',
  label_group: '',
  data_type_id: null,
  data_type_name: '',
  array_length: null,
  include_value: true,
  include_one_hot_mapping: false,
  include_multi_hot: false,
  one_hot_mapping_top_n: undefined, // important to keep undefined for one hot mapping type modal
  one_hot_mapping_top_n_formatted: '',
  one_hot_mapping_min_count: undefined, // important to keep undefined for one hot mapping type modal
  one_hot_mapping_min_count_formatted: '',
  one_hot_mapping_id: undefined, // important to keep undefined for one hot mapping type modal
  one_hot_mapping_name: '',
  sort_order: null,
  sort_order_formatted: '',
  sort_direction_id: null,
  sort_direction_name: TAURUS_NULL,
  default_value: '',
  null_resolution_id: TAURUS_NULL_RESOLUTION.USE_DEFAULT,
  null_resolution_name: TAURUS_NULL_RESOLUTION_NAME[TAURUS_NULL_RESOLUTION.USE_DEFAULT],
  is_array: false,
  array_length_formatted: '',
  column_index_formatted: '',
  saved: false,
  uuid: '',
};
