import { TaurusOneHotMappingFeature } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusOneHotMappingFeature[] = [];

export const TaurusOneHotMappingFeaturesSlice = createSlice({
  name: SLICE.TAURUS_ONE_HOT_MAPPING_FEATURES_LIST,
  initialState,
  reducers: {
    clearTaurusOneHotMappingFeaturesList: () => initialState,
    setTaurusOneHotMappingFeaturesList: (_, action: PayloadAction<TaurusOneHotMappingFeature[]>) => action.payload,
  },
});

export const { clearTaurusOneHotMappingFeaturesList, setTaurusOneHotMappingFeaturesList } = TaurusOneHotMappingFeaturesSlice.actions;

export default TaurusOneHotMappingFeaturesSlice.reducer;
