import { createSlice } from '@reduxjs/toolkit';
import { Settings } from 'ducks/settings/types';
import { SLICE } from 'ducks/slice';
import { THEME } from 'hooks/useGlobalTheme';

const initialState: Settings = {
  loading: false,
  sw_registration: null,
  is_menu_open: false,
  taurus: false,
  andromeda: false,
  theme: THEME.DARK,
};
export const settingsSlice = createSlice({
  name: SLICE.SETTINGS,
  initialState,
  reducers: {
    clearSettings: () => initialState,
    updateSettings: (state, { payload }) => {
      return { ...state, ...payload };
    },
    triggerTaurusLookups: (state) => {
      return { ...state, taurus: true };
    },
    triggerAndromedaLookups: (state) => {
      return { ...state, andromeda: true };
    },
  },
});

export const { clearSettings, updateSettings, triggerTaurusLookups, triggerAndromedaLookups } = settingsSlice.actions;

export default settingsSlice.reducer;
