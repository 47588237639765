import React, { useCallback } from 'react';
import { Button, EditorShell, Modal } from '@cognitiv/cassiopeia-ui';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'modals/utility/Modal.module.scss';

export const Stack = () => {
  const dispatch = useAppDispatch();
  const { stack } = useAppSelector((state) => selectModal(state, 'error'));
  const { is_open } = useAppSelector((state) => selectModal(state, 'stack'));

  const onClose = useCallback(() => {
    dispatch(updateModal({ stack: { is_open: false } }));
  }, [dispatch]);

  return (
    <Modal title="Stack" identifier="stack" is_open={is_open} width={800} theme={{ modal: { general: { max_height: undefined } } }} onClose={onClose}>
      <div className={cn.sql}>
        <EditorShell theme_identifier="html" min_height={400} value={stack || ''} vertical_resize horizontal_resize min_width={760} />
      </div>
      <div className={cn.footer}>
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};
