import React, { memo, useCallback } from 'react';
import { Action, Snackbar as SnackbarBase } from '@cognitiv/cassiopeia-ui';
import { selectSnackbar } from 'ducks/overlays/selectors';
import { updateSnackbar } from 'ducks/overlays/slices';
import { selectSettings } from 'ducks/settings/selectors';
import { updateSettings } from 'ducks/settings/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const SnackbarMemo = () => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectSettings);
  const snackbar = useAppSelector(selectSnackbar);

  const { title, action, is_open, duration, description, icon, color_primary } = snackbar;

  const updateServiceWorker = useCallback(() => {
    const registrationWaiting = settings?.sw_registration?.waiting;
    if (registrationWaiting) {
      dispatch(updateSettings({ loading: true }));
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, [dispatch, settings?.sw_registration?.waiting]);

  const onClose = useCallback(() => {
    dispatch(updateSnackbar({ is_open: false, title: 'Confirm Action', icon: null, description: null, action: null, duration: 0 }));
  }, [dispatch]);

  const onChange = useCallback(() => {
    if (action) {
      updateServiceWorker();
    }
    onClose();
  }, [action, onClose, updateServiceWorker]);

  return (
    <SnackbarBase
      is_open={is_open}
      title={title}
      description={description}
      duration={duration}
      icon={icon}
      color_primary={color_primary}
      position={{ top: 66, right: 16 }}
      onChange={onChange}
      onClose={onClose}
    >
      {action && <Action>{action}</Action>}
    </SnackbarBase>
  );
};

export const Snackbar = memo(SnackbarMemo);
