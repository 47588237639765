import React from 'react';
import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Chart as ChartJS, registerables } from 'chart.js';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'store';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'scss/index.scss';
import '@cognitiv/cassiopeia-ui/dist/index.css';

import 'utils/prototype';

import { App } from 'App';

library.add({ faSearch, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight } as IconPack);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
global.store = store;

ChartJS.register(...registerables);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
