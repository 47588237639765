import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { mergeSearchQuery } from '@cognitiv/cicada-ui';
import { TaurusWorkspace, useClientContext } from '@cognitiv/galaxy-api';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { PAGE_STORAGE } from 'hooks/useMenu';
import { useQuerySave } from 'hooks/useQuerySave';
import { TAURUS_CONFIRMATION_MODAL_TYPE } from 'products/taurus/modals/enums';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { taurus_workspace_context_initial_state } from 'products/taurus/overlays/manage-taurus-workspaces/context/defaults';
import { TaurusWorkspaceContextProps } from 'products/taurus/overlays/manage-taurus-workspaces/context/types';
import { WORKSPACE_ACTION } from 'products/taurus/overlays/manage-taurus-workspaces/enums';
import { TaurusWorkspaceAction } from 'products/taurus/overlays/manage-taurus-workspaces/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { TAURUS } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Storage } from 'utils';
import { uuidv4 } from 'utils/uuid';

const Context = createContext<TaurusWorkspaceContextProps>({ ...taurus_workspace_context_initial_state });

export const useManageWorkspacesContext = () => useContext(Context);

export const TaurusWorkspacesContext: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getDefaultQueries } = useQuerySave();
  const { search, pathname } = useLocation();
  const { is_open, workspace_id, current_workspace_id, update } = useAppSelector((state) =>
    selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES),
  );
  const [workspace_context, setWorkspaceContext] = useState<TaurusWorkspaceContextProps>({ ...taurus_workspace_context_initial_state });
  const { Taurus, handleError } = useClientContext();

  useEffect(() => {
    const setupPage = async () => {
      try {
        if (!is_open) return;

        setWorkspaceContext((prev) => ({ ...prev, is_loading_children: true, is_loading_workspace: true }));

        // highlights selected workspace and display parameters
        if (workspace_id) {
          const taurus_workspace = await Taurus.getTaurusWorkspace(workspace_id);
          setWorkspaceContext((prev) => ({ ...prev, workspace: taurus_workspace }));
        }

        if (current_workspace_id) {
          const taurus_current_workspace = await Taurus.getTaurusWorkspace(current_workspace_id);
          const taurus_current_workspace_children = await Taurus.getTaurusWorkspaceChildren(current_workspace_id);
          const taurus_current_workspace_index = taurus_current_workspace.workspace_path_split.length - 1;
          setWorkspaceContext((prev) => ({
            ...prev,
            current_workspace: taurus_current_workspace,
            current_workspace_children: taurus_current_workspace_children,
            current_workspace_index: taurus_current_workspace_index,
            is_loading_children: false,
            is_loading_workspace: false,
          }));
        }
        setWorkspaceContext((prev) => ({ ...prev, is_loading_children: false, is_loading_workspace: false }));
      } catch (err) {
        handleError(err);
        setWorkspaceContext({ ...taurus_workspace_context_initial_state });
      }
    };
    setupPage();
  }, [workspace_id, current_workspace_id, is_open, Taurus, handleError, update]);

  const createWorkspace = useCallback(() => {
    const { current_workspace, current_workspace_index } = workspace_context;
    const parent_workspace_id = Number(current_workspace.workspace_id_path_split[current_workspace_index]);
    dispatch(
      updateModal({
        [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACE]: {
          is_open: true,
          workspace_id: null,
          parent_workspace_id,
          is_navigation: true,
        },
      }),
    );
  }, [workspace_context, dispatch]);

  const editWorkspace = useCallback(
    (workspace: TaurusWorkspace) => {
      dispatch(
        updateModal({
          [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACE]: {
            is_open,
            workspace_id: workspace.workspace_id,
            parent_workspace_id: workspace.parent_workspace_id || null, // might be root which has not parent
            is_navigation: true,
          },
        }),
      );
    },
    [is_open, dispatch],
  );

  const onClose = useCallback(() => {
    setWorkspaceContext({ ...{ ...taurus_workspace_context_initial_state } });
    dispatch(
      updateModal({
        [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES]: {
          is_open: false,
          workspace_id: TAURUS_ROOT,
          current_workspace_id: TAURUS_ROOT,
          update: null,
        },
      }),
    );
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    const { current_workspace, current_workspace_index } = workspace_context;
    const current_workspace_id = current_workspace.workspace_id_path_split[current_workspace_index];
    const pathname_split = pathname.split('/');

    if (pathname_split.length > 4) {
      navigate(`${pathname}${mergeSearchQuery(search, { current_workspace_id })}`);
      dispatch(
        updateModal({
          confirm: {
            is_open: true,
            title: 'Workspace Navigation',
            message:
              'Are you sure you want to navigate away from the current Entity? The current Entity already has a defined Workspace so you will be redirected to the top level Entities page.',
            label: 'Ok',
            type: TAURUS_CONFIRMATION_MODAL_TYPE.WORKSPACE_NAVIGATE,
          },
        }),
      );
      return;
    }

    onClose();
    navigate(`${pathname}${mergeSearchQuery(search, { workspace_id: current_workspace_id, update: uuidv4() })}`);
  }, [dispatch, workspace_context, onClose, navigate, pathname, search]);

  const onSelectCurrentWorkspace = useCallback(
    (workspace_id: number) => {
      const { current_workspace_children } = workspace_context;
      setWorkspaceContext((prev) => ({ ...prev, is_loading_workspace: true }));
      const workspace = current_workspace_children.find((workspace_child) => workspace_child.workspace_id === workspace_id);
      if (workspace) {
        setWorkspaceContext((prev) => ({ ...prev, workspace }));
        setTimeout(() => setWorkspaceContext((prev) => ({ ...prev, is_loading_workspace: false })), 300);
      }
    },
    [workspace_context],
  );

  const onWorkspacePrev = useCallback(async () => {
    try {
      const { current_workspace_index, current_workspace } = workspace_context;
      setWorkspaceContext((prev) => ({ ...prev, is_loading_children: true }));

      const index = current_workspace_index - 1;
      const is_first = index <= 0;

      const prev_workspace_id = is_first
        ? Number(current_workspace.workspace_id_path_split[0])
        : Number(current_workspace.workspace_id_path_split[index]);

      const taurus_current_workspace_children = await Taurus.getTaurusWorkspaceChildren(Number(prev_workspace_id || TAURUS_ROOT));

      setWorkspaceContext((prev) => ({
        ...prev,
        current_workspace_children: taurus_current_workspace_children,
        current_workspace_index: is_first ? 0 : index,
        is_loading_children: false,
      }));
    } catch (err) {
      handleError(err);
    }
  }, [workspace_context, Taurus, handleError]);

  const onWorkspaceNext = useCallback(async () => {
    try {
      const { current_workspace, current_workspace_index } = workspace_context;
      setWorkspaceContext((prev) => ({ ...prev, is_loading_children: true }));

      const index = current_workspace_index + 1;
      const last_index = current_workspace.workspace_id_path_split.length - 1;
      const is_last = index >= last_index;

      const next_workspace_id = is_last
        ? Number(current_workspace.workspace_id_path_split[last_index])
        : Number(current_workspace.workspace_id_path_split[index]);

      const taurus_current_workspace_children = await Taurus.getTaurusWorkspaceChildren(Number(next_workspace_id || TAURUS_ROOT));

      setWorkspaceContext((prev) => ({
        ...prev,
        current_workspace_children: taurus_current_workspace_children,
        current_workspace_index: is_last ? last_index : index,
        is_loading_children: false,
      }));
    } catch (err) {
      handleError(err);
    }
  }, [workspace_context, Taurus, handleError]);

  const onSelectWorkspace = useCallback(
    async (workspace_id: number) => {
      try {
        setWorkspaceContext((prev) => ({ ...prev, is_loading_children: true, is_loading_workspace: true }));

        const taurus_current_workspace = await Taurus.getTaurusWorkspace(workspace_id);
        const taurus_current_workspace_children = await Taurus.getTaurusWorkspaceChildren(workspace_id);
        const taurus_current_workspace_index = taurus_current_workspace.workspace_path_split.length - 1;

        setWorkspaceContext((prev) => ({
          ...prev,
          workspace: taurus_current_workspace,
          current_workspace: taurus_current_workspace,
          current_workspace_children: taurus_current_workspace_children,
          current_workspace_index: taurus_current_workspace_index,
          is_loading_children: false,
          is_loading_workspace: false,
        }));
      } catch (err) {
        handleError(err);
      }
    },
    [Taurus, handleError],
  );

  const setSearch = useCallback(
    (entity: { page: string; storage: Storage; workspace_id: number }) => {
      switch (entity.storage) {
        case PAGE_STORAGE.PIPELINES:
        case PAGE_STORAGE.PIPELINE_DEFINITIONS:
        case PAGE_STORAGE.PIPELINE_ITEMS:
        case PAGE_STORAGE.MODELS:
        case PAGE_STORAGE.PIPELINE_QUERY_EXECUTIONS:
        case PAGE_STORAGE.SQL_TEMPLATES: {
          const queries = getDefaultQueries(entity.storage, entity.workspace_id || TAURUS_ROOT);
          onClose();
          return `${entity.page}${queries}`;
        }
      }
      const queries = getDefaultQueries(entity.storage, TAURUS_ROOT);
      return `${entity.page}${queries}`;
    },
    [getDefaultQueries],
  );

  const onSelectCurrentWorkspaceMenuItem = useCallback(
    (workspace_id: number, option: TaurusWorkspaceAction) => {
      switch (option.action) {
        case WORKSPACE_ACTION.EDIT_WORKSPACE: {
          const { workspace } = workspace_context;
          if (workspace) {
            editWorkspace(workspace);
          }
          break;
        }
        case WORKSPACE_ACTION.MODELS:
          navigate(setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.MODELS.path}`, storage: PAGE_STORAGE.MODELS, workspace_id }));
          break;
        case WORKSPACE_ACTION.PIPELINE:
          navigate(setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}`, storage: PAGE_STORAGE.PIPELINES, workspace_id }));
          break;
        case WORKSPACE_ACTION.PIPELINE_DEFINITION:
          navigate(
            setSearch({
              page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_DEFINITIONS.path}`,
              storage: PAGE_STORAGE.PIPELINE_DEFINITIONS,
              workspace_id,
            }),
          );
          break;
        case WORKSPACE_ACTION.PIPELINE_ITEMS:
          navigate(
            setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_ITEMS.path}`, storage: PAGE_STORAGE.PIPELINE_ITEMS, workspace_id }),
          );
          break;
        case WORKSPACE_ACTION.SQL_TEMPLATE:
          navigate(setSearch({ page: `${TAURUS.APPLICATION.path}/${TAURUS.SQL_TEMPLATES.path}`, storage: PAGE_STORAGE.SQL_TEMPLATES, workspace_id }));
          break;
        case WORKSPACE_ACTION.PIPELINE_QUERY_EXECUTIONS:
          navigate(
            setSearch({
              page: `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_QUERY_EXECUTIONS.path}`,
              storage: PAGE_STORAGE.PIPELINE_QUERY_EXECUTIONS,
              workspace_id,
            }),
          );
      }
    },
    [workspace_context, navigate, editWorkspace],
  );

  const value = useMemo(
    () => ({
      ...workspace_context,
      onWorkspacePrev,
      onWorkspaceNext,
      onSelectWorkspace,
      onSelectCurrentWorkspace,
      onSelectCurrentWorkspaceMenuItem,
      createWorkspace,
      editWorkspace,
      onClose,
      onSubmit,
    }),
    [
      workspace_context,
      editWorkspace,
      onWorkspacePrev,
      onSelectCurrentWorkspace,
      onSelectCurrentWorkspaceMenuItem,
      onWorkspaceNext,
      onSelectWorkspace,
      onClose,
      createWorkspace,
      onSubmit,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
