import { TaurusLabelType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusLabelType[] = [];

export const taurusLabelTypesListSlice = createSlice({
  name: SLICE.TAURUS_LABEL_TYPES_LIST,
  initialState,
  reducers: {
    clearTaurusLabelTypesList: () => initialState,
    setTaurusLabelTypesList: (_, action: PayloadAction<TaurusLabelType[]>) => action.payload,
  },
});

export const { clearTaurusLabelTypesList, setTaurusLabelTypesList } = taurusLabelTypesListSlice.actions;

export default taurusLabelTypesListSlice.reducer;
