import { useCallback, useEffect, useState } from 'react';
import { EMPTY_OBJECT } from '@cognitiv/cassiopeia-ui';
import { FORMAT_TYPE, formatDate, mergeSearchQuery, parseQueries } from '@cognitiv/cicada-ui';
import { updateModal } from 'ducks/modals/slices';
import { selectUser } from 'ducks/user/selectors';
import { useQuerySave } from 'hooks/useQuerySave';
import moment from 'moment/moment';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { AdvancedFilteringQuery } from 'products/taurus/overlays/manage-taurus-advanced-filtering/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { TAURUS } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const default_state = {
  start_date: '',
  range_name: '',
  finish_date: '',
  include_archived: false,
  created_by_me: false,
  is_scheduled: false,
  include_unmanaged: false,
  include_child_workspaces: true,
  include_parent_workspaces: false,
  sort_by_cost: false,
  max_rows: 200,
  filter: '',
};

export const useAdvancedFiltering = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const user = useAppSelector(selectUser);
  const { getDefaultQueryObject } = useQuerySave();

  const {
    range_name,
    start_date,
    finish_date,
    max_rows,
    include_archived,
    include_parent_workspaces,
    created_by,
    include_child_workspaces,
    is_scheduled,
    include_unmanaged,
    sort_by_cost,
    workspace_id,
  } = parseQueries(search);

  const [form, setForm] = useState<AdvancedFilteringQuery>({ ...default_state });

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      range_name: range_name || '',
      start_date: start_date || null,
      finish_date: finish_date || null,
      max_rows: max_rows,
      include_archived: !!include_archived || false,
      include_parent_workspaces: !!include_parent_workspaces || false,
      created_by_me: !!created_by,
      is_scheduled: !!is_scheduled || false,
      include_unmanaged: !!include_unmanaged || false,
      include_child_workspaces: include_child_workspaces ?? true,
      sort_by_cost: sort_by_cost || false,
      filter: '',
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);

  const onClose = useCallback(() => {
    dispatch(updateModal({ [TAURUS_MODALS.MANAGE_TAURUS_ADVANCED_FILTERING]: { is_open: false } }));
  }, [dispatch]);

  const onChange = useCallback((item: Partial<AdvancedFilteringQuery>) => {
    setForm((prev) => ({ ...prev, ...item }));
  }, []);

  const clearFilter = useCallback(() => {
    const defaults = () => {
      switch (pathname) {
        case `${TAURUS.APPLICATION.path}/${TAURUS.WORKSPACES.path}`:
          return getDefaultQueryObject('workspaces', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_DEFINITIONS.path}`:
          return getDefaultQueryObject('pipeline_definitions', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}`:
          return getDefaultQueryObject('pipelines', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_ITEMS.path}`:
          return getDefaultQueryObject('pipeline_items', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_QUERY_EXECUTIONS.path}`:
          return getDefaultQueryObject('pipeline_query_executions', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.MODELS.path}`:
          return getDefaultQueryObject('models', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.SQL_TEMPLATES.path}`:
          return getDefaultQueryObject('sql_templates', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.DATASETS.path}`:
          return getDefaultQueryObject('datasets', workspace_id || TAURUS_ROOT);
        case `${TAURUS.APPLICATION.path}/${TAURUS.ONE_HOT_MAPPINGS.path}`:
          return getDefaultQueryObject('one_hot_mappings', workspace_id || TAURUS_ROOT);
        default:
          return EMPTY_OBJECT;
      }
    };

    setForm({ ...default_state, ...defaults });
  }, [pathname, workspace_id, getDefaultQueryObject]);

  const setDateRange = useCallback(
    (value: string) => {
      const start_day = moment().startOf('day').format('MM/DD/YYYY hh:mm A');
      const end_day = moment().endOf('day').format('MM/DD/YYYY hh:mm A');

      const start_yesterday = moment().subtract(1, 'day').startOf('day').format('MM/DD/YYYY hh:mm A');
      const end_yesterday = moment().subtract(1, 'day').endOf('day').format('MM/DD/YYYY hh:mm A');

      const start_last_week = moment().subtract(1, 'week').startOf('day').format('MM/DD/YYYY hh:mm A');
      const end_last_week = moment().subtract(1, 'week').endOf('day').format('MM/DD/YYYY hh:mm A');

      const start_current_week = moment().startOf('week').format('MM/DD/YYYY hh:mm A');
      const start_month = moment().startOf('month').format('MM/DD/YYYY hh:mm A');

      switch (value) {
        case 'Today':
          onChange({ start_date: start_day, finish_date: end_day, range_name: value });
          break;
        case 'Yesterday':
          onChange({ start_date: start_yesterday, finish_date: end_yesterday, range_name: value });
          break;
        case 'Last Week':
          onChange({ start_date: start_last_week, finish_date: end_last_week, range_name: value });
          break;
        case 'Current Week':
          onChange({ start_date: start_current_week, finish_date: end_day, range_name: value });
          break;
        case 'Current Month':
          onChange({ start_date: start_month, finish_date: end_day, range_name: value });
          break;
        case 'Custom':
          onChange({ range_name: 'Custom' });
          break;
      }
    },
    [onChange],
  );

  const setFilter = useCallback(() => {
    const queries = mergeSearchQuery(search, {
      start_date: form.start_date ? formatDate(form.start_date, { format: FORMAT_TYPE.DATE_TIME_SHORT }) : null,
      finish_date: form.finish_date ? formatDate(form.finish_date, { format: FORMAT_TYPE.DATE_TIME_SHORT }) : null,
      range_name: form.range_name || '',
      max_rows: Number(form.max_rows || 200),
      include_archived: form.include_archived || false,
      include_parent_workspaces: form.include_parent_workspaces || false,
      include_child_workspaces: form.include_child_workspaces ?? true,
      created_by: form.created_by_me ? user.display_name : '',
      is_scheduled: form.is_scheduled ? true : null,
      include_unmanaged: form.include_unmanaged || false,
      sort_by_cost: form.sort_by_cost || false,
      workspace_id,
    });

    onClose();
    navigate(`${pathname}${queries}`);
  }, [form, navigate, onClose, pathname, search, user, workspace_id]);

  return {
    form,
    onChange,
    clearFilter,
    setDateRange,
    setFilter,
  };
};
