export enum TAURUS_OUTPUT_TYPE {
  CSV = 1,
  TFRECORD = 3,
  PARQUET = 5,
}

export const TAURUS_OUTPUT_TYPE_NAME = {
  1: 'CSV',
  3: 'TFRecord',
  5: 'Parquet',
};
