import { CONDITION_TYPE, VALIDATE } from '@cognitiv/cicada-ui';

export const validation = {
  workspace_name: {
    type: VALIDATE.STRING,
    conditions: [
      {
        error: 'Name is required',
        type: CONDITION_TYPE.MIN_LENGTH,
        values: [1],
      },
    ],
  },
};
