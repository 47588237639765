import { TaurusFeature } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusFeature[] = [];

export const taurusProjectionItemFeaturesSlice = createSlice({
  name: SLICE.TAURUS_PROJECTION_ITEM_FEATURES,
  initialState,
  reducers: {
    clearTaurusProjectionItemFeatures: () => initialState,
    setTaurusProjectionItemFeatures: (_, action: PayloadAction<TaurusFeature[]>) => action.payload,
  },
});

export const { clearTaurusProjectionItemFeatures, setTaurusProjectionItemFeatures } = taurusProjectionItemFeaturesSlice.actions;

export default taurusProjectionItemFeaturesSlice.reducer;
