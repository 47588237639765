import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { Button } from '@cognitiv/cassiopeia-ui';
import { parseQueries } from '@cognitiv/cicada-ui';
import { faCubes } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromBracket, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkspaceBreadcrumb } from 'components/layout/navigation/WorkspaceBreadcrumb';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { clearUser } from 'ducks/user/slices';
import { useTheme } from 'hooks/useTheme';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { selectTaurusWorkspace } from 'products/taurus/operators/workspace/selectors';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { deleteLocalStorage } from 'utils';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const Navigation: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { workspace_id } = parseQueries(search);
  const { user } = useAppSelector((state) => ({ user: state.user }));
  const { is_open } = useAppSelector((state) => selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES));
  const taurus_workspace = useAppSelector(selectTaurusWorkspace);
  const {
    theme: { button_navigation, button_navigation_active },
  } = useTheme();

  const { workspace_path_split, workspace_id_path_split } = taurus_workspace;

  const active_workspace_id = useMemo(() => {
    if (workspace_id) return Number(workspace_id);
    return Number(workspace_id_path_split[workspace_id_path_split.length - 1]);
  }, [workspace_id, workspace_id_path_split]);

  const onClick = () => {
    try {
      dispatch(clearUser());
      deleteLocalStorage('user');
    } catch {
      throw new Error('unable to clear store');
    }
  };

  const onManageWorkspaces = useCallback(() => {
    dispatch(
      updateModal({
        [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES]: {
          is_open: !is_open,
          workspace_id: active_workspace_id,
          current_workspace_id: active_workspace_id,
          update: null,
        },
      }),
    );
  }, [dispatch, active_workspace_id, is_open]);

  const workspace_path_exists = !!workspace_path_split.length;

  return (
    <nav className={cn.navigation}>
      <Button
        id="button-cassiopeia"
        left_icon={faCubes}
        variant="tertiary"
        min_width="32px"
        onClick={onManageWorkspaces}
        theme={is_open ? button_navigation_active : button_navigation}
      />
      {workspace_path_exists && (
        <div className={cn.wrapper}>
          {workspace_path_split.map((workspace_name: string, index: number) => {
            return (
              <WorkspaceBreadcrumb
                key={index}
                index={index}
                workspace_name={workspace_name}
                active={active_workspace_id === Number(workspace_id_path_split[index])}
              />
            );
          })}
        </div>
      )}
      {children}
      <div className={cn.grow} />
      <div className={cn.name}>
        <FontAwesomeIcon icon={faUser} />
        <p>{user.display_name || 'User'}</p>
      </div>
      <div onClick={onClick} className={cn.breadcrumb}>
        <FontAwesomeIcon icon={faArrowRightFromBracket} />
        <p>Logout</p>
      </div>
    </nav>
  );
};
