import { TaurusImportItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_import_item_default } from 'products/taurus/operators/import-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusImportItem>;
}

const initialState: TaurusImportItem = { ...taurus_import_item_default };

export const taurusImportItemSlice = createSlice({
  name: SLICE.TAURUS_IMPORT_ITEM,
  initialState,
  reducers: {
    updateTaurusImportItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusImportItem: () => initialState,
    setTaurusImportItem: (_, action: PayloadAction<TaurusImportItem>) => action.payload,
  },
});

export const { updateTaurusImportItem, clearTaurusImportItem, setTaurusImportItem } = taurusImportItemSlice.actions;

export default taurusImportItemSlice.reducer;
