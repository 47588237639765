import { TaurusCompressionFormat } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusCompressionFormat[] = [];

export const taurusCompressionFormatsListSlice = createSlice({
  name: SLICE.TAURUS_COMPRESSION_FORMATS_LIST,
  initialState,
  reducers: {
    clearTaurusCompressionFormatsList: () => initialState,
    setTaurusCompressionFormatsList: (_, action: PayloadAction<TaurusCompressionFormat[]>) => action.payload,
  },
});

export const { clearTaurusCompressionFormatsList, setTaurusCompressionFormatsList } = taurusCompressionFormatsListSlice.actions;

export default taurusCompressionFormatsListSlice.reducer;
