import { TaurusDataset, TaurusItem, TaurusParameter, TaurusTransformItemColumn } from '@cognitiv/galaxy-api';
import { Modal } from 'ducks/modals/types';
import { TAURUS_MODAL_VIEW } from 'products/taurus/modals/enums';

export interface TaurusSingleModal extends Modal {
  read_only: boolean;
}

export interface TaurusContextModal extends Modal {
  read_only: boolean;
  view: TAURUS_MODAL_VIEW;
}

export interface TaurusQueryModal extends TaurusContextModal {
  show_parents?: boolean;
}

export interface ManageValueModal extends Modal {
  read_only: boolean;
  parameter: TaurusParameter;
  onSubmit: (value: TaurusParameter) => void;
}

export interface ViewQueryModal extends Modal {
  title: string;
  value: string;
}

export interface ManageTransformColumn extends Modal {
  column: TaurusTransformItemColumn;
  onSubmit: (column: TaurusTransformItemColumn) => void;
}

export interface ManageWorkspaceModal extends Modal {
  workspace_id: number | null;
  parent_workspace_id: number | null;
  is_navigation: boolean;
}

export interface ManageWorkspacesModal extends Modal {
  workspace_id: number;
  current_workspace_id: number;
  update: string | null;
}

export interface SelectTaurusDatasetModal extends Modal {
  onSubmit: (item: TaurusDataset) => void;
}

export interface SelectTaurusSourceItemModal extends Modal {
  onSelect: (item: TaurusItem) => void;
  workspace_id: number;
}

export interface SelectTaurusModelModal extends Modal {
  onSelect: (partial: Partial<TaurusItem>) => void;
  workspace_id: number | null;
  model_id: number | null;
}

export interface ViewTaurusItemError extends Modal {
  title: string;
  error: string;
}

export interface TaurusModals {
  copy_taurus_pipeline: Modal;
  manage_pipeline_definition: Modal;
  manage_taurus_pipeline_schedule: Modal;
  manage_taurus_advanced_filtering: Modal;
  create_taurus_pipeline_definition: Modal;
  manage_taurus_custom_etl_item: TaurusContextModal;
  manage_taurus_custom_item: TaurusSingleModal;
  manage_taurus_dataset_read_item: TaurusSingleModal;
  manage_taurus_dataset_write_item: TaurusSingleModal;
  manage_taurus_dataset: TaurusContextModal;
  manage_taurus_evaluate_item: TaurusSingleModal;
  manage_taurus_import_item: TaurusContextModal;
  manage_taurus_item_configuration: TaurusSingleModal;
  manage_taurus_model: Modal;
  manage_taurus_non_etl_query_item: TaurusQueryModal;
  manage_taurus_one_hot_mapping: Modal;
  manage_taurus_parameter_value: ManageValueModal;
  manage_taurus_parent_items: Modal;
  manage_taurus_pipeline_definition: Modal;
  manage_taurus_pipeline: Modal;
  manage_taurus_predict_item: TaurusContextModal;
  manage_taurus_projection_item: TaurusContextModal;
  manage_taurus_publish_item: TaurusSingleModal;
  manage_taurus_query_executions: Modal;
  manage_taurus_query_item: TaurusQueryModal;
  manage_taurus_source_item: Modal;
  manage_taurus_sql_template: TaurusContextModal;
  view_taurus_statistics: Modal;
  manage_taurus_strategy_publisher_item: TaurusSingleModal;
  manage_taurus_train_item: TaurusSingleModal;
  manage_taurus_transform_item: TaurusContextModal;
  manage_taurus_view_query: ViewQueryModal;
  manage_taurus_workspace: ManageWorkspaceModal;
  manage_taurus_workspaces: ManageWorkspacesModal;
  manage_taurus_transform_column_mapping: ManageTransformColumn;
  parse_taurus_template: Modal;
  preview_taurus_dataset: Modal;
  preview_taurus_query_sql: Modal;
  restore_taurus_item_objects: Modal;
  run_taurus_pipeline_definition: Modal;
  select_taurus_dataset: SelectTaurusDatasetModal;
  select_taurus_item_type: Modal;
  select_taurus_parent_items: Modal;
  select_taurus_pipeline_definition: Modal;
  select_taurus_publish_model: SelectTaurusModelModal;
  select_taurus_source_item: SelectTaurusSourceItemModal;
  manage_taurus_pipeline_priority: Modal;
  view_taurus_dataset_partitions: Modal;
  view_taurus_item_error: ViewTaurusItemError;
}

export enum TAURUS_MODALS {
  COPY_TAURUS_PIPELINE = 'copy_taurus_pipeline',
  CREATE_TAURUS_PIPELINE_DEFINITION = 'create_taurus_pipeline_definition',
  MANAGE_PIPELINE_DEFINITION = 'manage_pipeline_definition',
  MANAGE_TAURUS_PIPELINE_SCHEDULE = 'manage_taurus_pipeline_schedule',
  MANAGE_TAURUS_ADVANCED_FILTERING = 'manage_taurus_advanced_filtering',
  MANAGE_TAURUS_CUSTOM_ETL_ITEM = 'manage_taurus_custom_etl_item',
  MANAGE_TAURUS_CUSTOM_ITEM = 'manage_taurus_custom_item',
  MANAGE_TAURUS_DATASET_READ_ITEM = 'manage_taurus_dataset_read_item',
  MANAGE_TAURUS_DATASET_WRITE_ITEM = 'manage_taurus_dataset_write_item',
  MANAGE_TAURUS_DATASET = 'manage_taurus_dataset',
  MANAGE_TAURUS_EVALUATE_ITEM = 'manage_taurus_evaluate_item',
  MANAGE_TAURUS_IMPORT_ITEM = 'manage_taurus_import_item',
  MANAGE_TAURUS_ITEM_CONFIGURATION = 'manage_taurus_item_configuration',
  MANAGE_TAURUS_MODEL = 'manage_taurus_model',
  MANAGE_TAURUS_NON_ETL_QUERY_ITEM = 'manage_taurus_non_etl_query_item',
  MANAGE_TAURUS_ONE_HOT_MAPPING = 'manage_taurus_one_hot_mapping',
  MANAGE_TAURUS_PARAMETER_VALUE = 'manage_taurus_parameter_value',
  MANAGE_TAURUS_PARENT_ITEMS = 'manage_taurus_parent_items',
  MANAGE_TAURUS_PIPELINE_DEFINITION = 'manage_taurus_pipeline_definition',
  MANAGE_TAURUS_PIPELINE = 'manage_taurus_pipeline',
  MANAGE_TAURUS_PREDICT_ITEM = 'manage_taurus_predict_item',
  MANAGE_TAURUS_PROJECTION_ITEM = 'manage_taurus_projection_item',
  MANAGE_TAURUS_PUBLISH_ITEM = 'manage_taurus_publish_item',
  MANAGE_TAURUS_QUERY_EXECUTIONS = 'manage_taurus_query_executions',
  MANAGE_TAURUS_QUERY_ITEM = 'manage_taurus_query_item',
  MANAGE_TAURUS_SOURCE_ITEM = 'manage_taurus_source_item',
  MANAGE_TAURUS_SQL_TEMPLATE = 'manage_taurus_sql_template',
  VIEW_TAURUS_STATISTICS = 'view_taurus_statistics',
  MANAGE_TAURUS_STRATEGY_PUBLISHER_ITEM = 'manage_taurus_strategy_publisher_item',
  MANAGE_TAURUS_TRAIN_ITEM = 'manage_taurus_train_item',
  MANAGE_TAURUS_TRANSFORM_ITEM = 'manage_taurus_transform_item',
  MANAGE_TAURUS_VIEW_QUERY = 'manage_taurus_view_query',
  MANAGE_TAURUS_WORKSPACE = 'manage_taurus_workspace',
  MANAGE_TAURUS_WORKSPACES = 'manage_taurus_workspaces',
  MANAGE_TAURUS_TRANSFORM_COLUMN_MAPPING = 'manage_taurus_transform_column_mapping',
  PARSE_TAURUS_TEMPLATE = 'parse_taurus_template',
  PREVIEW_TAURUS_DATASET = 'preview_taurus_dataset',
  PREVIEW_TAURUS_QUERY_SQL = 'preview_taurus_query_sql',
  RESTORE_TAURUS_ITEM_OBJECTS = 'restore_taurus_item_objects',
  RUN_TAURUS_PIPELINE_DEFINITION = 'run_taurus_pipeline_definition',
  SELECT_TAURUS_DATASET = 'select_taurus_dataset',
  SELECT_TAURUS_ITEM_TYPE = 'select_taurus_item_type',
  SELECT_TAURUS_PARENT_ITEMS = 'select_taurus_parent_items',
  SELECT_TAURUS_PIPELINE_DEFINITION = 'select_taurus_pipeline_definition',
  SELECT_TAURUS_PUBLISH_MODEL = 'select_taurus_publish_model',
  SELECT_TAURUS_SOURCE_ITEM = 'select_taurus_source_item',
  MANAGE_TAURUS_PIPELINE_PRIORITY = 'manage_taurus_pipeline_priority',
  VIEW_TAURUS_DATASET_PARTITIONS = 'view_taurus_dataset_partitions',
  VIEW_TAURUS_ITEM_ERROR = 'view_taurus_item_error',
}

export interface validateItemColumnsProps<T> {
  columns: T[];
}

export interface PartitionedColumnsProps<T> {
  columns: T[];
}

export interface PartitionedColumnsReturnType<T> {
  columns: T[];
  message: string;
  error: boolean;
}

export interface TaurusDefaultTitleProps {
  item_id: number;
  read_only: boolean;
  saved: boolean;
  title: string;
}
