import { TaurusEtlItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusEtlItem[] = [];

export const taurusParentItemsSlice = createSlice({
  name: SLICE.TAURUS_PARENT_ITEMS,
  initialState,
  reducers: {
    clearTaurusParentItems: () => initialState,
    setTaurusParentItems: (_, action: PayloadAction<TaurusEtlItem[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusParentItems, setTaurusParentItems } = taurusParentItemsSlice.actions;

export default taurusParentItemsSlice.reducer;
