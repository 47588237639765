export const TAURUS_NULL = '(NULL)';

export enum TAURUS_SORT_DIRECTION {
  ASC = 1,
  DESC = 2,
}

export const TAURUS_SORT_DIRECTION_NAME = {
  1: 'ASC',
  2: 'DESC',
};
