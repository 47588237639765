import { TaurusParameter } from '@cognitiv/galaxy-api';
import { CELL_HEIGHT, HEADER_HEIGHT } from 'products/taurus/components/tables/constants';

export const TABLE_SETTINGS = {
  header: {
    height: HEADER_HEIGHT,
  },
  cell: {
    height: CELL_HEIGHT,
    ellipsis: true,
  },
};

export const parameter_default: TaurusParameter = {
  parameter_name: '',
  parameter_value: '',
  parameter_upstream_value: null,
  parameter_placeholder: '',
  parameter_striked: false,
  uuid: '',
};
