import { TaurusDataset } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusDataset[] = [];

export const taurusDatasetsSlice = createSlice({
  name: SLICE.TAURUS_DATASETS,
  initialState,
  reducers: {
    clearTaurusDatasets: () => initialState,
    setTaurusDatasets: (_, action: PayloadAction<TaurusDataset[]>) => action.payload,
  },
});

export const { clearTaurusDatasets, setTaurusDatasets } = taurusDatasetsSlice.actions;

export default taurusDatasetsSlice.reducer;
