import { TaurusSqlTemplate } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';
import { taurus_sql_template_default } from 'products/taurus/operators/sql-template/defaults';

const initialState: TaurusSqlTemplate = {
  ...taurus_sql_template_default,
};

export const taurusSqlTemplateSlice = createSlice({
  name: SLICE.TAURUS_SQL_TEMPLATE,
  initialState,
  reducers: {
    clearTaurusSqlTemplate: () => initialState,
    setTaurusSqlTemplate: (_, action: PayloadAction<TaurusSqlTemplate>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusSqlTemplate, setTaurusSqlTemplate } = taurusSqlTemplateSlice.actions;

export default taurusSqlTemplateSlice.reducer;
