import { TaurusCustomItem } from '@cognitiv/galaxy-api';

export const taurus_custom_item_default: TaurusCustomItem = {
  start_date_utc: '',
  finish_date_utc: '',
  job_uri: '',
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: '',
  is_definition: false,
  parent_item_ids: [],
  row_count: 0,
  row_count_formatted: '0',
  definition_item_id: null,
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  created_datetime_formatted: '',
  saved: false,
  uuid: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  start_datetime_formatted: '',
  finish_datetime_formatted: '',
  duration_seconds: 0,
  duration_seconds_formatted: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  storage_size_bytes: null,
  item_post_process_state_id: null,
  item_post_process_state_name: null,
  initialized_date: null,
  data_state_id: null,
  data_state_name: null,
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  compute_costs_estimate_usd: null,
  compute_costs_estimate_usd_formatted: '',
};
