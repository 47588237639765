export const CELL_HEIGHT = 32;
export const HEADER_HEIGHT = 32;
export const ACCORDION_METRIC_HEIGHT = 81;
export const ACCORDION_HEIGHT = 72;
export const FILTER_ROW_HEIGHT = 36;
export const ACCORDION_GAP = 16;
export const BORDER_HEIGHT = 2;
export const PAGINATION_HEIGHT = 48;

export const TABLE_SETTINGS = {
  table: {
    filters: true,
    pagination: false,
  },
  pagination: {
    rows: 50,
  },
  header: {
    height: HEADER_HEIGHT,
  },
  cell: {
    height: CELL_HEIGHT,
    ellipsis: true,
  },
};
