import { faBallPile, faChartNetwork, faClock, faEdit, faKeyboard } from '@fortawesome/pro-regular-svg-icons';

export enum WORKSPACE_ACTION {
  EDIT_WORKSPACE = 'Edit Workspace',
  MODELS = 'Models',
  PIPELINE = 'Pipeline',
  PIPELINE_DEFINITION = 'Pipeline Definition',
  PIPELINE_ITEMS = 'Pipeline Items',
  SQL_TEMPLATE = 'SQL Template',
  PIPELINE_QUERY_EXECUTIONS = 'Pipeline Query Executions',
}

export const WORKSPACE_ITEM_ACTION_ICON = {
  [WORKSPACE_ACTION.EDIT_WORKSPACE]: faEdit,
  [WORKSPACE_ACTION.MODELS]: faChartNetwork,
  [WORKSPACE_ACTION.PIPELINE]: faBallPile,
  [WORKSPACE_ACTION.PIPELINE_DEFINITION]: faBallPile,
  [WORKSPACE_ACTION.PIPELINE_ITEMS]: faBallPile,
  [WORKSPACE_ACTION.SQL_TEMPLATE]: faKeyboard,
  [WORKSPACE_ACTION.PIPELINE_QUERY_EXECUTIONS]: faClock,
};
