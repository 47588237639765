import { TaurusPipeline } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPipeline[] = [];

export const taurusPipelinesSlice = createSlice({
  name: SLICE.TAURUS_PIPELINES,
  initialState,
  reducers: {
    clearTaurusPipelines: () => initialState,
    setTaurusPipelines: (_, action: PayloadAction<TaurusPipeline[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusPipelines, setTaurusPipelines } = taurusPipelinesSlice.actions;

export default taurusPipelinesSlice.reducer;
