import { TaurusStrategyPublisherItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';
import { taurus_strategy_publisher_item_default } from 'products/taurus/operators/strategy-publisher-item/defaults';

interface Payload {
  payload: Partial<TaurusStrategyPublisherItem>;
}

const initialState: TaurusStrategyPublisherItem = { ...taurus_strategy_publisher_item_default };

export const TaurusStrategyPublisherItemSlice = createSlice({
  name: SLICE.TAURUS_STRATEGY_PUBLISHER_ITEM,
  initialState,
  reducers: {
    updateTaurusStrategyPublisherItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusStrategyPublisherItem: () => initialState,
    setTaurusStrategyPublisherItem: (_, action: PayloadAction<TaurusStrategyPublisherItem>) => action.payload,
  },
});

export const { updateTaurusStrategyPublisherItem, clearTaurusStrategyPublisherItem, setTaurusStrategyPublisherItem } =
  TaurusStrategyPublisherItemSlice.actions;

export default TaurusStrategyPublisherItemSlice.reducer;
