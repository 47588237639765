export type Storage =
  | 'user'
  | 'settings'
  | 'pipelines'
  | 'pipeline_definitions'
  | 'one_hot_mappings'
  | 'pipeline_items'
  | 'datasets'
  | 'sql_templates'
  | 'models'
  | 'pipeline_query_executions'
  | 'workspaces';

type Store = { [key: string]: any } | any[] | boolean;

export const getLocalStorage = (item: Storage = 'user') => {
  let store = null;
  const stored_item = localStorage.getItem(item);
  if (stored_item) {
    store = JSON.parse(stored_item);
  }
  if (store) {
    return store;
  }
  return {};
};

export const setLocalStorage = (item: Storage = 'user', store: Store) => {
  const data = JSON.stringify(store);
  localStorage.setItem(item, data);
};

export const deleteLocalStorage = (item: Storage = 'user') => {
  localStorage.setItem(item, '{}');
};
