import { createSlice } from '@reduxjs/toolkit';
import { Modals } from 'ducks/modals/types';
import { SLICE } from 'ducks/slice';
import { taurus_modals } from 'products/taurus/modals/constants';

export const initialState: Modals = {
  error: {
    is_open: false,
    error: null,
    name: null,
    message: null,
    stack: null,
    status: null,
    raw: null,
  },
  confirm: {
    is_open: false,
    title: 'Confirm Action',
    message: 'Please confirm action before proceeding.',
    label: 'Confirm',
    type: null,
  },
  stack: { is_open: false },
  search: { is_open: false },
  ...taurus_modals,
};

export const modalsSlice = createSlice({
  name: SLICE.MODALS,
  initialState,
  reducers: {
    clearModals: () => initialState,
    updateModal: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { clearModals, updateModal } = modalsSlice.actions;

export default modalsSlice.reducer;
