import React, { memo, useContext } from 'react';
import { CARET, Cell, CellAction, RowContext } from '@cognitiv/cassiopeia-ui';
import { TaurusParameter } from '@cognitiv/galaxy-api';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from 'hooks/useTheme';

export const CellParameterRemove = memo(
  ({ onClick, read_only, is_static }: { onClick: (item: TaurusParameter) => void; read_only?: boolean; is_static?: boolean }) => {
    const { row } = useContext(RowContext);
    const {
      theme: { cell_remove },
    } = useTheme();

    if (!row.parameter_name || row.parameter_name === '' || read_only || is_static) {
      return <Cell col_key="" />;
    }

    return <CellAction icon={faTrash} message="remove parameter" caret={CARET.BOTTOM} onClick={() => onClick(row)} theme={cell_remove} />;
  },
);
