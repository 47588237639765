import { TaurusCustomEtlItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_custom_etl_item_default } from 'products/taurus/operators/custom-etl-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusCustomEtlItem>;
}

const initialState: TaurusCustomEtlItem = { ...taurus_custom_etl_item_default };

export const taurusCustomEtlItemSlice = createSlice({
  name: SLICE.TAURUS_CUSTOM_ETL_ITEM,
  initialState,
  reducers: {
    updateTaurusCustomEtlItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusCustomEtlItem: () => initialState,
    setTaurusCustomEtlItem: (_, action: PayloadAction<TaurusCustomEtlItem>) => action.payload,
  },
});

export const { updateTaurusCustomEtlItem, clearTaurusCustomEtlItem, setTaurusCustomEtlItem } = taurusCustomEtlItemSlice.actions;

export default taurusCustomEtlItemSlice.reducer;
