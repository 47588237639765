import { TaurusItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_item_default } from 'products/taurus/operators/item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusItem = { ...taurus_item_default };

export const taurusItemSlice = createSlice({
  name: SLICE.TAURUS_ITEM,
  initialState,
  reducers: {
    updateTaurusItem: (state, { payload }) => {
      return { ...state, ...payload };
    },
    clearTaurusItem: () => initialState,
    setTaurusItem: (_, action: PayloadAction<TaurusItem>) => action.payload,
  },
});

export const { setTaurusItem, updateTaurusItem, clearTaurusItem } = taurusItemSlice.actions;

export default taurusItemSlice.reducer;
