import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'ducks/slice';
import { User } from 'ducks/user/types';

const initialState: User = {
  error: null,
};

export const userSlice = createSlice({
  name: SLICE.USER,
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearUser: () => initialState,
  },
});

export const { clearUser, setUser } = userSlice.actions;

export default userSlice.reducer;
