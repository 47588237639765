import { TaurusSqlTemplateEngine } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusSqlTemplateEngine[] = [];

export const taurusSqlTemplateEnginesListSlice = createSlice({
  name: SLICE.TAURUS_SQL_TEMPLATE_ENGINES_LIST,
  initialState,
  reducers: {
    clearTaurusSqlTemplateEnginesList: () => initialState,
    setTaurusSqlTemplateEnginesList: (_, action: PayloadAction<TaurusSqlTemplateEngine[]>) => action.payload,
  },
});

export const { clearTaurusSqlTemplateEnginesList, setTaurusSqlTemplateEnginesList } = taurusSqlTemplateEnginesListSlice.actions;

export default taurusSqlTemplateEnginesListSlice.reducer;
