import { TaurusItemNumericalStatistic } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusItemNumericalStatistic[] = [];

export const taurusItemCategoricalStatisticsSlice = createSlice({
  name: SLICE.TAURUS_ITEM_CATEGORICAL_STATISTICS,
  initialState,
  reducers: {
    clearTaurusItemCategoricalStatistics: () => initialState,
    setTaurusItemCategoricalStatistics: (_, action: PayloadAction<TaurusItemNumericalStatistic[]>) => action.payload,
  },
});

export const { clearTaurusItemCategoricalStatistics, setTaurusItemCategoricalStatistics } = taurusItemCategoricalStatisticsSlice.actions;

export default taurusItemCategoricalStatisticsSlice.reducer;
