import { TaurusAllocationType } from 'products/taurus/operators/allocation-types/types';

export enum TAURUS_ALLOCATION_TYPE {
  COUNT = 1,
  FOLD = 2,
}

export const TAURUS_ALLOCATION_TYPE_NAME = {
  1: 'Count',
  2: 'Fold',
};

export const taurus_allocation_types: TaurusAllocationType[] = [
  {
    allocation_type_name: TAURUS_ALLOCATION_TYPE_NAME[TAURUS_ALLOCATION_TYPE.COUNT],
    allocation_type_id: TAURUS_ALLOCATION_TYPE.COUNT,
    uuid: 1,
  },
  {
    allocation_type_name: TAURUS_ALLOCATION_TYPE_NAME[TAURUS_ALLOCATION_TYPE.FOLD],
    allocation_type_id: TAURUS_ALLOCATION_TYPE.FOLD,
    uuid: 2,
  },
];
