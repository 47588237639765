import { TaurusPipelineState } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPipelineState[] = [];

export const taurusPipelineStatesListSlice = createSlice({
  name: SLICE.TAURUS_PIPELINE_STATES_LIST,
  initialState,
  reducers: {
    clearTaurusPipelineStatesList: () => initialState,
    setTaurusPipelineStatesList: (_, action: PayloadAction<TaurusPipelineState[]>) => action.payload,
  },
});

export const { clearTaurusPipelineStatesList, setTaurusPipelineStatesList } = taurusPipelineStatesListSlice.actions;

export default taurusPipelineStatesListSlice.reducer;
