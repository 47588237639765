import { TaurusItem } from '@cognitiv/galaxy-api';

export const reduceItemsCount = (items: TaurusItem[]) => {
  return items.reduce(
    (acc, next) => {
      const { item_state_id, is_archived, progress } = next;

      acc.count = acc.count + 1;
      acc.progress = acc.progress + Number(Math.round(progress || 0));
      acc.state_count = acc.state_count + Number(item_state_id || 0);

      // need to find the matching statistic item after filter removed
      // acc.statistics_state_count = acc.statistics_state_count + Number(statistics_state_id || 0);

      if (is_archived) {
        acc.archived_count = acc.archived_count + 1;
      }
      return acc;
    },
    {
      progress: 0,
      archived_count: 0,
      state_count: 0,
      statistics_state_count: 0,
      count: 0,
    },
  );
};
