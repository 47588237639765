export enum TAURUS_MODAL_VIEW {
  CREATE = 'Create',
  SQL = 'SQL',
  COLUMNS = 'Columns',
}

export enum TAURUS_CONFIRMATION_MODAL_TYPE {
  COPY_MESSAGE = 'copy-message',
  ONE_HOT_MAPPING_STATUS = 'one-hot-mapping-status',
  PIPELINE_STATUS = 'pipeline-status',
  PIPELINE_RESET = 'pipeline-reset',
  PIPELINE_STOP = 'pipeline-stop',
  PIPELINE_RESET_TERMINAL = 'pipeline-reset-terminal',
  SQL_TEMPLATE_STATUS = 'sql-template-status',
  MODEL_ACTIVATE = 'model-activate',
  MODEL_ARCHIVE = 'model-archive',
  ITEM_CASCADE_DELETE = 'item-cascade-delete',
  ITEM_CASCADE_ARCHIVE = 'item-cascade-archive',
  ITEM_CASCADE_RESET = 'item-cascade-reset',
  ITEM_CASCADE_STOP = 'item-cascade-stop',
  WORKSPACE_NAVIGATE = 'workspace-navigate',
  WORKSPACE_ARCHIVE = 'workspace-archive',
  WORKSPACE_RESTORE = 'workspace-restore',
}
