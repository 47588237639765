import { useCallback, useEffect } from 'react';
import { EMPTY_OBJECT } from '@cognitiv/cassiopeia-ui';
import { mergeSearchQuery, parseQueries, replaceQuery } from '@cognitiv/cicada-ui';
import { TAURUS_QUERY_ENGINE_TYPE } from 'products/taurus/operators/query-engine-types/enums';
import { useLocation } from 'react-router-dom';
import { getLocalStorage, setLocalStorage, Storage } from 'utils';

export const useQuerySave = (page?: Storage) => {
  const { search } = useLocation();
  useEffect(() => {
    if (page) {
      const queries_parsed = parseQueries(search);
      const queries_stored = getLocalStorage(page);
      const queries_merged = { ...queries_stored, ...queries_parsed };

      Object.keys(queries_parsed).forEach((key) => {
        if (queries_parsed[key] === null) {
          delete queries_merged[key];
        }
      });

      setLocalStorage(page, queries_merged);
    }
  }, [page, search]);

  const mergeQueries = useCallback(
    (entity: Storage, queries = EMPTY_OBJECT) => {
      const local_queries = getLocalStorage(entity);
      const merged_queries = { ...local_queries, ...queries };
      return mergeSearchQuery(search, merged_queries);
    },
    [search],
  );

  const getDefaultQueryObject = useCallback((entity: Storage, workspace_id: number) => {
    const queries_stored = getLocalStorage(entity);
    const defaults = {
      filter: '',
      include_archived: false,
      include_parent_workspaces: false,
      include_child_workspaces: true,
      start_date: null,
      finish_date: null,
    };
    switch (entity) {
      case 'models':
        return {
          ...defaults,
          workspace_id,
          created_by: queries_stored.created_by || '',
          max_rows: queries_stored.max_rows || 200,
          include_parent_workspaces: queries_stored.include_parent_workspaces || false,
          include_child_workspaces: queries_stored.include_child_workspaces ?? true,
        };
      case 'pipeline_query_executions':
        return { query_engine_type_id: TAURUS_QUERY_ENGINE_TYPE.CLICKHOUSE, workspace_id };
      case 'pipeline_items':
        return {
          ...defaults,
          workspace_id,
          created_by: queries_stored.created_by || '',
          max_rows: queries_stored.max_rows || 200,
          include_parent_workspaces: queries_stored.include_parent_workspaces || false,
          include_child_workspaces: queries_stored.include_child_workspaces ?? true,
          item_type_id: null,
        };
      case 'datasets':
        return {
          ...defaults,
          workspace_id,
          created_by: queries_stored.created_by || '',
          max_rows: queries_stored.max_rows || 200,
          include_parent_workspaces: queries_stored.include_parent_workspaces || false,
          include_child_workspaces: queries_stored.include_child_workspaces ?? true,
          include_unmanaged: queries_stored.include_unmanaged || false,
        };
      case 'pipelines':
        return {
          ...defaults,
          workspace_id,
          created_by: queries_stored.created_by || '',
          max_rows: queries_stored.max_rows || 200,
          include_parent_workspaces: queries_stored.include_parent_workspaces || false,
          include_child_workspaces: queries_stored.include_child_workspaces ?? true,
          pipeline_state_id: queries_stored.pipeline_state_id || null,
        };
      case 'pipeline_definitions':
        return {
          ...defaults,
          workspace_id,
          created_by: queries_stored.created_by || '',
          max_rows: queries_stored.max_rows || 200,
          include_parent_workspaces: queries_stored.include_parent_workspaces || false,
          include_child_workspaces: queries_stored.include_child_workspaces ?? true,
          is_scheduled: queries_stored.is_scheduled ? true : null,
        };
      case 'workspaces':
        return { workspace_id: workspace_id };
      default:
        return {
          ...defaults,
          workspace_id,
          created_by: queries_stored.created_by || '',
          max_rows: queries_stored.max_rows || 200,
          include_parent_workspaces: queries_stored.include_parent_workspaces || false,
          include_child_workspaces: queries_stored.include_child_workspaces ?? true,
        };
    }
  }, []);

  const getDefaultQueries = useCallback(
    (entity: Storage, workspace_id: number) => {
      const query_object = getDefaultQueryObject(entity, workspace_id);
      return replaceQuery(query_object);
    },
    [getDefaultQueryObject],
  );

  return { mergeQueries, getDefaultQueries, getDefaultQueryObject };
};
