import { TaurusPipelineSubscription } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPipelineSubscription[] = [];

export const taurusSubscriptionsSlice = createSlice({
  name: SLICE.TAURUS_SUBSCRIPTIONS,
  initialState,
  reducers: {
    clearTaurusSubscriptions: () => initialState,
    setTaurusSubscriptions: (_, action: PayloadAction<TaurusPipelineSubscription[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusSubscriptions, setTaurusSubscriptions } = taurusSubscriptionsSlice.actions;

export default taurusSubscriptionsSlice.reducer;
