import { TaurusFeature } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusFeature[] = [];

export const taurusFeaturesListSlice = createSlice({
  name: SLICE.TAURUS_FEATURES_LIST,
  initialState,
  reducers: {
    clearTaurusFeaturesList: () => initialState,
    setTaurusFeaturesList: (_, action: PayloadAction<TaurusFeature[]>) => action.payload,
  },
});

export const { clearTaurusFeaturesList, setTaurusFeaturesList } = taurusFeaturesListSlice.actions;

export default taurusFeaturesListSlice.reducer;
