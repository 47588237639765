import { colors } from 'theme/colors';

export const STATE_THEME = {
  // BLUE GREY
  initialized: colors.blue_grey[5],
  incomplete: colors.blue_grey[5],
  // ORANGE
  queued_for_start: colors.orange[5],
  queued_for_statistics: colors.orange[5],
  queued_for_reset: colors.orange[5],
  queued_for_stop: colors.orange[5],
  // BLUE
  starting: colors.blue[5],
  running: colors.blue[5],
  running_statistics: colors.blue[5],
  // YELLOW
  resetting: colors.yellow[5],
  // GREEN
  succeeded: colors.green[5],
  // RED
  stopping: colors.red[5],
  stopped: colors.red[5],
  failed: colors.red[5],
};
