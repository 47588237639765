import { TaurusTransformItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';
import { taurus_transform_item_default } from 'products/taurus/operators/transform-item/defaults';

interface Payload {
  payload: Partial<TaurusTransformItem>;
}

const initialState: TaurusTransformItem = { ...taurus_transform_item_default };

export const TaurusTransformItemSlice = createSlice({
  name: SLICE.TAURUS_TRANSFORM_ITEM,
  initialState,
  reducers: {
    updateTaurusTransformItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusTransformItem: () => initialState,
    setTaurusTransformItem: (_, action: PayloadAction<TaurusTransformItem>) => action.payload,
  },
});

export const { updateTaurusTransformItem, clearTaurusTransformItem, setTaurusTransformItem } = TaurusTransformItemSlice.actions;

export default TaurusTransformItemSlice.reducer;
