import React from 'react';
import { EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { faMoon, faSun } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuSwitchProps } from 'components/menu/types';
import { useTheme } from 'hooks/useTheme';
import Switch from 'react-switch';

import cn from 'components/menu/components/Components.module.scss';

export const MenuSwitch = ({ checked }: MenuSwitchProps) => {
  const {
    theme: { generic },
  } = useTheme();
  return (
    <Switch
      checked={checked}
      onChange={EMPTY_FUNCTION}
      onColor={generic.on_color}
      offColor={generic.off_color}
      handleDiameter={14}
      checkedIcon={false}
      uncheckedIcon={false}
      checkedHandleIcon={
        <div className={cn.icon}>
          <FontAwesomeIcon icon={faMoon} />
        </div>
      }
      uncheckedHandleIcon={
        <div className={cn.icon}>
          <FontAwesomeIcon icon={faSun} />
        </div>
      }
      activeBoxShadow=""
      height={16}
      width={32}
    />
  );
};
