import React, { useEffect } from 'react';
import { Button } from '@cognitiv/cassiopeia-ui';
import { Page } from 'components/layout/page/Page';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { triggerTaurusLookups, updateSettings } from 'ducks/settings/slices';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'hooks/useTheme';

import cn from 'products/public/pages/error/ErrorPage.module.scss';
import { selectSettings } from 'ducks/settings/selectors';
import { Navigation } from 'components/layout/navigation/Navigation';
import { StaticBreadcrumb } from 'components/layout/navigation/StaticBreadcrumb';
import { batch } from 'react-redux';

const { PUBLIC_URL } = process.env;

export default ({ page, path }: { page: string; path: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { theme_variant } = useTheme();

  const { loading } = useAppSelector(selectSettings);

  useEffect(() => {
    if (loading) {
      batch(() => {
        dispatch(updateSettings({ loading: false }));
        dispatch(triggerTaurusLookups());
      })
    }
  }, [dispatch, loading]);

  return (
    <>
      <Navigation>
        <StaticBreadcrumb>
          <p>404</p>
        </StaticBreadcrumb>
      </Navigation>
      <Page>
        <div className={cn.content}>
          <div className={cn.box}>
            <div className={cn.imageWrapper}>
              <img src={`${PUBLIC_URL}/404-logo-${theme_variant}.svg`} alt="Cognitiv 404 Error" width={70} />
              <img src={`${PUBLIC_URL}/brain-blue-logo-${theme_variant}.svg`} alt="Cognitiv 404 Error" width={150} />
              <img src={`${PUBLIC_URL}/404-logo-${theme_variant}.svg`} alt="Cognitiv 404 Error" width={70} />
            </div>
            <p>
              Oops, looks like you are stranded!
              <br />
              The page you are trying to access doesn't exist.
              <br />
              Please navigate your way back to a working page.
            </p>
            <Button min_width="80px" onClick={() => navigate(path)}>
              View {page}
            </Button>
          </div>
        </div>
      </Page>
    </>
  );
};
