import { TaurusDataType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusDataType[] = [];

export const taurusDataTypesListSlice = createSlice({
  name: SLICE.TAURUS_DATA_TYPES_LIST,
  initialState,
  reducers: {
    clearTaurusDataTypesList: () => initialState,
    setTaurusDataTypesList: (_, action: PayloadAction<TaurusDataType[]>) => action.payload,
  },
});

export const { clearTaurusDataTypesList, setTaurusDataTypesList } = taurusDataTypesListSlice.actions;

export default taurusDataTypesListSlice.reducer;
