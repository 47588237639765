export {};

declare global {
  interface Array<T> {
    sortObject<TKey extends keyof T>(key: TKey, direction?: 'asc' | 'desc'): T[];
    filterObject<TKey extends keyof T>(key: TKey, filter?: string): T[];
  }
}

Array.prototype.sortObject = function <T, TKey extends keyof T>(key: TKey, direction: 'asc' | 'desc' = 'asc') {
  const compare = (a: T, b: T) => {
    const a_value = (a[key] || '').toString().toLowerCase();
    const b_value = (b[key] || '').toString().toLowerCase();

    if (direction === 'asc') {
      return a_value.localeCompare(b_value, 'en-US', {
        numeric: true,
        sensitivity: 'base',
      });
    }
    return b_value.localeCompare(a_value, 'en-US', {
      numeric: true,
      sensitivity: 'base',
    });
  };

  return this.sort(compare);
};

Array.prototype.filterObject = function <T, TKey extends keyof T>(key: TKey, filter?: string) {
  const compare = (obj: T) => {
    const value = (obj[key] || '').toString().toLowerCase();
    const string = String(filter || '').toLowerCase();
    return value.includes(string);
  };

  return this.filter(compare);
};
