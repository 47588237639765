import { TaurusDatasetWriteItem } from '@cognitiv/galaxy-api';

export const taurus_dataset_write_item_default: TaurusDatasetWriteItem = {
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: 0,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  parameters: [],
  job_id: 0,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: true,
  description: '',
  is_definition: true,
  parent_item_ids: [],
  managed_dataset_id: 0,
  managed_dataset_name: '',
  dataset_definition_pipeline_id: 0,
  dataset_definition_pipeline_name: '',
  dataset_definition_pipeline_workspace_id: 0,
  dataset_definition_pipeline_workspace_name: '',
  partition_collision_resolution_id: 2,
  partition_collision_resolution_name: 'Ignore',
  write_empty_partitions: true,
  created_datetime_formatted: '',
  saved: false,
  uuid: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};
