import { useCallback, useRef } from 'react';
import { HEADER_HEIGHT } from 'products/taurus/components/tables/constants';

export const useScrollToRow = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  const scrollToRow = useCallback(({ index, header_height = HEADER_HEIGHT }: { index: number; header_height?: number }) => {
    setTimeout(() => {
      const tables = ref.current?.getElementsByTagName('table');
      if (!tables) return;
      const [table] = Array.from(tables);
      const rowToScroll = table.rows[index + 1];
      const targetY = rowToScroll.offsetTop - header_height;
      const startY = table.parentElement!.scrollTop;

      let startTime = 0;
      function animateScroll(timestamp: DOMHighResTimeStamp) {
        if (!startTime) startTime = timestamp;
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / 100, 1);
        const currentY = startY + (targetY - startY) * progress;
        table.parentElement!.scrollTop = currentY;
        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      }
      requestAnimationFrame(animateScroll);
    }, 100);
  }, []);

  const scrollToLastRow = useCallback(() => {
    setTimeout(() => {
      const tables = ref.current?.getElementsByTagName('table');
      if (!tables) return;
      const [table] = Array.from(tables);
      table.rows[table.rows.length - 1].scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, []);

  return { ref, scrollToRow, scrollToLastRow };
};
