import { TaurusRowType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusRowType[] = [];

export const taurusRowTypesListSlice = createSlice({
  name: SLICE.TAURUS_ROW_TYPES_LIST,
  initialState,
  reducers: {
    clearTaurusRowTypesList: () => initialState,
    setTaurusRowTypesList: (_, action: PayloadAction<TaurusRowType[]>) => action.payload,
  },
});

export const { clearTaurusRowTypesList, setTaurusRowTypesList } = taurusRowTypesListSlice.actions;

export default taurusRowTypesListSlice.reducer;
