import React, { memo, useContext, useMemo } from 'react';
import { CellInput, RowContext } from '@cognitiv/cassiopeia-ui';
import { TaurusParameter } from '@cognitiv/galaxy-api';

export const CellParameterValue = memo(
  ({ onChange, dimensions, read_only = false }: { onChange: (item: TaurusParameter) => void; dimensions: boolean; read_only: boolean }) => {
    const { row } = useContext(RowContext);

    const error = useMemo(() => {
      if (!dimensions) {
        return false;
      }
      if (!row.parameter_name) {
        return false;
      }
      if (row.parameter_upstream_value || row.parameter_value) {
        return false;
      }
      return true;
    }, [row, dimensions]);

    return (
      <CellInput
        debounce={200} // users tab through these quickly
        onChange={({ value }) => onChange({ ...row, parameter_value: value })}
        value={row.parameter_value}
        disabled={read_only}
        placeholder={row.parameter_upstream_value}
        text_decoration={row.parameter_striked ? 'line-through' : undefined}
        error={error}
      />
    );
  },
);
