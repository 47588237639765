import { TaurusOneHotMapping } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_one_hot_mapping_default } from 'products/taurus/operators/one-hot-mapping/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusOneHotMapping = { ...taurus_one_hot_mapping_default };

export const taurusOneHotMappingSlice = createSlice({
  name: SLICE.TAURUS_ONE_HOT_MAPPING,
  initialState,
  reducers: {
    clearTaurusOneHotMapping: () => initialState,
    setTaurusOneHotMapping: (_, action: PayloadAction<TaurusOneHotMapping>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusOneHotMapping, setTaurusOneHotMapping } = taurusOneHotMappingSlice.actions;

export default taurusOneHotMappingSlice.reducer;
