export enum TAURUS_FILTER_OPERATOR {
  IS_NULL = 1,
  IS_NOT_NULL = 2,
  LESS_THAN = 3,
  LESS_THAN_OR_EQUAL = 4,
  EQUAL = 5,
  NOT_EQUAL = 6,
  GREATER_THAN_OR_EQUAL = 7,
  GREATER_THAN = 8,
  BETWEEN = 9,
  IS_IN = 10,
  IS_NOT_IN = 11,
}

export const TAURUS_FILTER_OPERATOR_CONSTRAINT = {
  1: 0,
  2: 0,
  3: 1,
  4: 1,
  5: 1,
  6: 1,
  7: 1,
  8: 1,
  9: 2,
  10: null,
  11: null,
};

export const TAURUS_FILTER_OPERATOR_NAME = {
  1: 'Is Null',
  2: 'Is Not Null',
  3: 'Less Than',
  4: 'Less Than Or Equal',
  5: 'Equal',
  6: 'Not Equal',
  7: 'Greater Than Or Equal',
  8: 'Greater Than',
  9: 'Between',
  10: 'Is In',
  11: 'Is Not In',
};
