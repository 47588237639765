import { TaurusCustomItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_custom_item_default } from 'products/taurus/operators/custom-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusCustomItem>;
}

const initialState: TaurusCustomItem = { ...taurus_custom_item_default };

export const taurusCustomItemSlice = createSlice({
  name: SLICE.TAURUS_CUSTOM_ITEM,
  initialState,
  reducers: {
    updateTaurusCustomItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusCustomItem: () => initialState,
    setTaurusCustomItem: (_, action: PayloadAction<TaurusCustomItem>) => action.payload,
  },
});

export const { updateTaurusCustomItem, clearTaurusCustomItem, setTaurusCustomItem } = taurusCustomItemSlice.actions;

export default taurusCustomItemSlice.reducer;
