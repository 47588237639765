import { TaurusDatasetWriteItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_dataset_write_item_default } from 'products/taurus/operators/dataset-write-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';
interface Payload {
  payload: Partial<TaurusDatasetWriteItem>;
}

const initialState: TaurusDatasetWriteItem = { ...taurus_dataset_write_item_default };

export const taurusDatasetWriteItemSlice = createSlice({
  name: SLICE.TAURUS_DATASET_WRITE_ITEM,
  initialState,
  reducers: {
    updateTaurusDatasetWriteItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusDatasetWriteItem: () => initialState,
    setTaurusDatasetWriteItem: (_, action: PayloadAction<TaurusDatasetWriteItem>) => action.payload,
  },
});

export const { updateTaurusDatasetWriteItem, clearTaurusDatasetWriteItem, setTaurusDatasetWriteItem } = taurusDatasetWriteItemSlice.actions;

export default taurusDatasetWriteItemSlice.reducer;
