import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { EMPTY_FUNCTION } from '@cognitiv/cassiopeia-ui';
import { MenuContextProps } from 'components/menu/types';
import { useAddToHomeScreenPrompt } from 'hooks/useAddToHomeScreenPrompt';
import { useMenu } from 'hooks/useMenu';

const Context = createContext<MenuContextProps>({
  group: '',
  getApplicationPage: () => '',
  is_menu_open: false,
  setMenu: EMPTY_FUNCTION,
  is_installed: null,
  expanded_menu_group: undefined,
  handleExpand: EMPTY_FUNCTION,
});

export const useMenuContext = () => useContext(Context);

export const MenuContext: FC<PropsWithChildren> = ({ children }) => {
  const is_installed = useAddToHomeScreenPrompt();
  const { group, is_menu_open, setMenu, getApplicationPage, expanded_menu_group, handleExpand } = useMenu();

  const value = useMemo(
    () => ({
      group,
      getApplicationPage,
      is_menu_open,
      setMenu,
      expanded_menu_group,
      is_installed,
      handleExpand,
    }),
    [group, getApplicationPage, is_menu_open, setMenu, is_installed, expanded_menu_group, handleExpand],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
