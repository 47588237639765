import React, { useCallback, useMemo, useState } from 'react';
import { Action, CARET, Column, EMPTY_ARRAY, EMPTY_FUNCTION, Label, Table, Tooltip } from '@cognitiv/cassiopeia-ui';
import { TaurusParameter } from '@cognitiv/galaxy-api';
import { faChevronDown, faChevronUp, faCopy } from '@fortawesome/pro-regular-svg-icons';
import copy from 'copy-to-clipboard';
import { updateModal } from 'ducks/modals/slices';
import { updateSnackbar } from 'ducks/overlays/slices';
import { useScrollToRow } from 'hooks/useScrollToRow';
import { CellParameterExpand } from 'products/taurus/components/parameters/cells/CellParameterExpand';
import { CellParameterKey } from 'products/taurus/components/parameters/cells/CellParameterKey';
import { CellParameterRemove } from 'products/taurus/components/parameters/cells/CellParameterRemove';
import { CellParameterValue } from 'products/taurus/components/parameters/cells/CellParameterValue';
import { parameter_default, TABLE_SETTINGS } from 'products/taurus/components/parameters/constants';
import { ParameterProps } from 'products/taurus/components/parameters/types';
import { ManageTaurusParameterValue } from 'products/taurus/modals/manage-taurus-parameter-value/ManageTaurusParameterValue';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { getTableHeight, getTableMinHeight } from 'products/taurus/modals/utils';
import { useAppDispatch } from 'store/hooks';
import { uuidv4 } from 'utils/uuid';

import cn from 'products/taurus/components/parameters/Parameters.module.scss';

export const Parameters = ({
  label = 'Parameters',
  parameters = EMPTY_ARRAY,
  parameters_override = EMPTY_ARRAY,
  onChange = EMPTY_FUNCTION,
  is_static = false,
  read_only = false,
  margin = '16px 0px 0px 0px',
  default_open = true,
  dimensions = false,
  separator = false,
}: ParameterProps) => {
  const dispatch = useAppDispatch();
  const [is_expanded, setIsExpanded] = useState(default_open);

  const { ref } = useScrollToRow();

  const data = useMemo((): TaurusParameter[] => {
    const parameters_striked = parameters.map((parameter) => {
      const parameter_striked = parameters_override.find((parameter_override) => parameter_override.parameter_name === parameter.parameter_name);
      return { ...parameter, parameter_striked: !!parameter_striked };
    });
    if (parameters_striked.length === 0) {
      parameters_striked.push({ ...parameter_default, uuid: uuidv4() });
      return parameters_striked;
    }

    if (is_static || read_only) {
      return parameters_striked;
    }

    const last_parameter = parameters_striked[parameters_striked.length - 1];

    if (last_parameter.parameter_name) {
      parameters_striked.push({ ...parameter_default, uuid: uuidv4() });
      return parameters_striked;
    }

    return parameters_striked;
  }, [parameters, parameters_override, is_static, read_only]);

  const onChangeParameter = useCallback(
    (parameter: TaurusParameter) => {
      const parameters_merged = [...data];
      const index = parameters_merged.findIndex((parameter_merged) => parameter_merged.uuid === parameter.uuid);

      if (index !== -1) {
        parameters_merged.splice(index, 1, parameter);
      }

      onChange(parameters_merged);
    },
    [data, onChange],
  );

  const onClickExpand = useCallback(
    (parameter: TaurusParameter) => {
      dispatch(
        updateModal({
          [TAURUS_MODALS.MANAGE_TAURUS_PARAMETER_VALUE]: { is_open: true, parameter, read_only, onSubmit: onChangeParameter },
        }),
      );
    },
    [dispatch, read_only, onChangeParameter],
  );

  const onClickCopy = useCallback(() => {
    copy(JSON.stringify(data));
    dispatch(updateSnackbar({ title: `${label} copied to clipboard` }));
  }, [dispatch, label, data]);

  const onClickRemove = useCallback(
    (parameter: TaurusParameter) => {
      const parameters_merged = [...data];
      const index = parameters_merged.findIndex((parameter_merged) => parameter_merged.uuid === parameter.uuid);

      if (index !== -1) {
        parameters_merged.splice(index, 1);
      }

      onChange(parameters_merged);
    },
    [data, onChange],
  );

  const min_height = getTableMinHeight();
  const height = getTableHeight({ rows: data.length, min_height: 32 });

  return (
    <div style={{ margin }}>
      <div className={cn.row}>
        <Label label={label} margin={0} />
        <Action left_icon={faCopy} onClick={onClickCopy}>
          <Tooltip message={`Copy ${label}`} caret={CARET.BOTTOM} />
        </Action>
        <div className={cn.grow} />
        <Action left_icon={is_expanded ? faChevronUp : faChevronDown} onClick={() => setIsExpanded((prev) => !prev)}>
          {is_expanded ? 'Hide' : 'Show'}
        </Action>
      </div>
      {is_expanded && (
        <div ref={ref} className={cn.table} style={{ minHeight: min_height - 1, height: height }}>
          <Table rows={data} settings={TABLE_SETTINGS} height={height}>
            <Column col_name="Key" col_width={200} col_uuid={1}>
              <CellParameterKey onChange={onChangeParameter} read_only={read_only} />
            </Column>
            <Column col_name="Value" col_min_width={100} col_width={null} col_uuid={2}>
              <CellParameterValue onChange={onChangeParameter} dimensions={dimensions} read_only={read_only} />
            </Column>
            <Column col_name="" col_width={32} col_align="center" col_uuid={3}>
              <CellParameterExpand onClick={onClickExpand} read_only={read_only} />
            </Column>
            <Column col_name="" col_width={32} col_align="center" col_uuid={4}>
              <CellParameterRemove onClick={onClickRemove} read_only={read_only} is_static={is_static} />
            </Column>
          </Table>
          <ManageTaurusParameterValue />
        </div>
      )}
      {separator && <div className={cn.break} />}
    </div>
  );
};
