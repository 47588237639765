import { TaurusItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reduceItemsCount } from 'products/taurus/operators/pipeline-items/utils';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusItem[] = [];

export const taurusPipelineItemsSlice = createSlice({
  name: SLICE.TAURUS_PIPELINE_ITEMS,
  initialState,
  reducers: {
    setTaurusPipelineItems: (state, { payload }) => {
      const { progress, archived_count, state_count, count, statistics_state_count } = reduceItemsCount(state);
      const counts = reduceItemsCount(payload);
      if (
        counts.count !== count ||
        counts.progress !== progress ||
        counts.archived_count !== archived_count ||
        counts.state_count !== state_count ||
        counts.statistics_state_count !== statistics_state_count
      ) {
        return payload;
      }
      return state;
    },
    clearTaurusPipelineItems: () => initialState,
    setTaurusPipelineDefaultItems: (_, action: PayloadAction<TaurusItem[]>) => {
      return action.payload;
    },
  },
});

export const { setTaurusPipelineItems, clearTaurusPipelineItems, setTaurusPipelineDefaultItems } = taurusPipelineItemsSlice.actions;

export default taurusPipelineItemsSlice.reducer;
