import { FORMAT_TYPE, formatValue } from '@cognitiv/cicada-ui';
import { TaurusPipelineSchedule } from '@cognitiv/galaxy-api';

export const taurus_pipeline_schedule_default: TaurusPipelineSchedule = {
  interval_name: 'One Time',
  interval_id: 1,
  interval_frequency: 1,
  interval_frequency_formatted: '1',
  interval_bitmask: '',
  start_datetime_utc: new Date().toString(),
  start_datetime_formatted: formatValue(new Date().toString(), { format: FORMAT_TYPE.DATE_TIME_SHORT }),
  finish_datetime_utc: null,
  finish_datetime_formatted: '',
  last_datetime_utc: null,
  last_datetime_formatted: '',
  next_datetime_utc: null,
  next_datetime_formatted: '',
  uuid: '',
};
