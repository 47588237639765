import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useMenuContext } from 'components/menu/context/MenuContext';

import cn from 'components/layout/content/Content.module.scss';

export const Content: FC<PropsWithChildren> = ({ children }) => {
  const { is_menu_open } = useMenuContext();
  return <div className={classNames(cn.content, { [cn.slim]: is_menu_open })}>{children}</div>;
};
