import React from 'react';
import { ThemeProvider, useRootTheme } from '@cognitiv/cassiopeia-ui';
import { Loading } from 'components/layout/loading/Loading';
import { LOADING_VARIANTS } from 'components/layout/loading/types';
import { Snackbar } from 'components/layout/snackbar/Snackbar';
import { selectSettings } from 'ducks/settings/selectors';
import { selectUserAuthentication } from 'ducks/user/selectors';
import { useTheme } from 'hooks/useTheme';
import { Confirm } from 'modals/utility/Confirm';
import { Error } from 'modals/utility/Error';
import { Warning } from 'products/public/components/warning/Warning';
import { Navigate, Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TAURUS } from 'routes';
import { useAppSelector } from 'store/hooks';

import cn from 'templates/Template.module.scss';

export const PublicTemplate = () => {
  const location = useLocation();
  const { loading, theme } = useAppSelector(selectSettings);
  const authenticated = useAppSelector((state) => selectUserAuthentication(state));

  const {
    theme: { framework },
  } = useTheme();
  useRootTheme(theme, 'taurus');

  if (authenticated) {
    return <Navigate to={location.state?.referer || `${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}`} />;
  }

  return (
    <ThemeProvider theme_variant={theme} theme={framework}>
      <div className={cn.public}>
        <Outlet />
        <Snackbar />
        <Warning />
        <Error />
        <Confirm />
        {loading && <Loading variant={LOADING_VARIANTS.DARK} />}
      </div>
    </ThemeProvider>
  );
};
