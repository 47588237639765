import { TaurusBaseItem, TaurusEtlItem, TaurusItem } from '@cognitiv/galaxy-api';
import { TAURUS_COMPRESSION_TYPE, TAURUS_COMPRESSION_TYPE_NAME } from 'products/taurus/operators/compression-formats-list/enums';
import { ITEM_CARD_TYPE, TaurusGraphItem } from 'products/taurus/operators/item/types';
import { TAURUS_OUTPUT_TYPE, TAURUS_OUTPUT_TYPE_NAME } from 'products/taurus/operators/output-types-list/enums';

export const taurus_base_item_default: TaurusBaseItem = {
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  is_definition: false,
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: '',
  parent_item_ids: [],
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_to: '',
  item_id_from: '',
  created_datetime_formatted: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  saved: false,
  uuid: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};

export const taurus_item_default: TaurusItem = {
  start_date_utc: '',
  finish_date_utc: null,
  job_uri: null,
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  is_definition: false,
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: '',
  parent_item_ids: [],
  row_count: 0,
  row_count_formatted: '0',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_to: '',
  item_id_from: '',
  definition_item_id: null,
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  duration_seconds_formatted: '',
  start_datetime_formatted: '',
  finish_datetime_formatted: '',
  created_datetime_formatted: '',
  duration_seconds: 0,
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  saved: false,
  uuid: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  storage_size_bytes: null,
  item_post_process_state_id: null,
  item_post_process_state_name: null,
  initialized_date: null,
  data_state_id: null,
  data_state_name: null,
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  compute_costs_estimate_usd: null,
  compute_costs_estimate_usd_formatted: '',
};

export const taurus_item_etl_default: TaurusEtlItem = {
  dimensions: [],
  partition_column_id: null,
  partition_column_name: '',
  partition_name: null,
  partition_count: null,
  parent_item_ids: [],
  columns: [],
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: null,
  is_definition: false,
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  dataset_id: 0,
  definition_item_id: null,
  s3_bucket: '',
  s3_prefix: '',
  row_type_id: null,
  glue_table_name: null,
  output_type_id: TAURUS_OUTPUT_TYPE.PARQUET,
  output_type_name: TAURUS_OUTPUT_TYPE_NAME[TAURUS_OUTPUT_TYPE.PARQUET],
  output_compression_format_id: TAURUS_COMPRESSION_TYPE.SNAPPY,
  output_compression_format_name: TAURUS_COMPRESSION_TYPE_NAME[TAURUS_COMPRESSION_TYPE.SNAPPY],
  row_count: 0,
  row_count_formatted: '0',
  cpu_override: null,
  cpu_override_formatted: '',
  gpu_override: null,
  gpu_override_formatted: '',
  memory_gb_override: null,
  memory_gb_override_formatted: '',
  storage_gb_override: null,
  storage_gb_override_formatted: '',
  saved: false,
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  created_datetime_formatted: '',
  uuid: '',
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};

export const taurus_graph_item_default: TaurusGraphItem = {
  ...taurus_item_default,
  child_item_id: null,
  children_item_ids: [],
  item_card_type: ITEM_CARD_TYPE.NODE,
};
