import { TaurusSqlEngine } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusSqlEngine[] = [];

export const taurusSqlEnginesListSlice = createSlice({
  name: SLICE.TAURUS_SQL_ENGINES_LIST,
  initialState,
  reducers: {
    clearTaurusSqlEnginesList: () => initialState,
    setTaurusSqlEnginesList: (_, action: PayloadAction<TaurusSqlEngine[]>) => action.payload,
  },
});

export const { clearTaurusSqlEnginesList, setTaurusSqlEnginesList } = taurusSqlEnginesListSlice.actions;

export default taurusSqlEnginesListSlice.reducer;
