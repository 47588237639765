import { TaurusDataset } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_dataset_default } from 'products/taurus/operators/dataset/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusDataset = { ...taurus_dataset_default };

export const taurusDatasetSlice = createSlice({
  name: SLICE.TAURUS_DATASET,
  initialState,
  reducers: {
    clearTaurusDataset: () => initialState,
    setTaurusDataset: (_, action: PayloadAction<TaurusDataset>) => action.payload,
  },
});

export const { clearTaurusDataset, setTaurusDataset } = taurusDatasetSlice.actions;

export default taurusDatasetSlice.reducer;
