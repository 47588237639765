import { useCallback } from 'react';
import { mergeSearchQuery, parseQueries } from '@cognitiv/cicada-ui';
import { useClientContext } from '@cognitiv/galaxy-api';
import { faArchive } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateLeft, faCheck } from '@fortawesome/pro-solid-svg-icons';
import copy from 'copy-to-clipboard';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { updateSnackbar } from 'ducks/overlays/slices';
import { updateSettings } from 'ducks/settings/slices';
import { useQuerySave } from 'hooks/useQuerySave';
import { TAURUS_CONFIRMATION_MODAL_TYPE } from 'products/taurus/modals/enums';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { selectTaurusItem } from 'products/taurus/operators/item/selectors';
import { selectTaurusModel } from 'products/taurus/operators/model/selectors';
import { selectTaurusOneHotMapping } from 'products/taurus/operators/one-hot-mapping/selectors';
import { selectTaurusPipeline } from 'products/taurus/operators/pipeline/selectors';
import { selectTaurusSqlTemplate } from 'products/taurus/operators/sql-template/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { TAURUS } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { uuidv4 } from 'utils/uuid';

export const useConfirmActions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { getDefaultQueries } = useQuerySave();
  const { current_workspace_id } = parseQueries(search);
  const { Taurus, handleError } = useClientContext();
  const taurus_one_hot_mapping = useAppSelector(selectTaurusOneHotMapping);
  const taurus_sql_template = useAppSelector(selectTaurusSqlTemplate);
  const taurus_pipeline = useAppSelector(selectTaurusPipeline);
  const taurus_model = useAppSelector(selectTaurusModel);
  const taurus_item = useAppSelector(selectTaurusItem);

  const { type, message } = useAppSelector((state) => selectModal(state, 'confirm'));

  const refreshPage = useCallback(() => {
    const queries = mergeSearchQuery(search, { update: uuidv4() });

    // something sus is going on when resetting from an item modal and the loading isnt turning off
    dispatch(updateSettings({ loading: false }));
    navigate(`${pathname}${queries}`);
  }, [navigate, dispatch, pathname, search]);

  const onConfirm = useCallback(async () => {
    switch (type) {
      case TAURUS_CONFIRMATION_MODAL_TYPE.COPY_MESSAGE: {
        copy(message || '');
        dispatch(updateModal({ confirm: { is_open: false } }));
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.ONE_HOT_MAPPING_STATUS: {
        try {
          const { is_archived, one_hot_mapping_id, one_hot_mapping_name } = taurus_one_hot_mapping;
          dispatch(updateSettings({ loading: true }));

          if (is_archived) {
            await Taurus.unarchiveTaurusOneHotMapping(one_hot_mapping_id);
          } else {
            await Taurus.archiveTaurusOneHotMapping(one_hot_mapping_id);
          }
          const title = is_archived ? `${one_hot_mapping_name} was restored` : `${one_hot_mapping_name} was archived`;
          const icon = is_archived ? faArrowRotateLeft : faArchive;
          dispatch(updateSnackbar({ title, icon, color_primary: '#fff' }));

          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.PIPELINE_STATUS: {
        try {
          const { is_archived, pipeline_id, pipeline_name } = taurus_pipeline;
          dispatch(updateSettings({ loading: true }));

          if (is_archived) {
            await Taurus.unarchiveTaurusPipeline(pipeline_id);
          } else {
            await Taurus.archiveTaurusPipeline(pipeline_id);
          }

          const title = is_archived ? `${pipeline_name} was restored` : `${pipeline_name} was archived`;
          const icon = is_archived ? faArrowRotateLeft : faArchive;

          dispatch(updateSnackbar({ title, icon, color_primary: '#fff' }));
          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.WORKSPACE_NAVIGATE:
        // pages that have an entity page
        switch (true) {
          case pathname.includes(TAURUS.MODELS.path):
            {
              const queries = getDefaultQueries('models', current_workspace_id || TAURUS_ROOT);
              navigate(`${TAURUS.APPLICATION.path}/${TAURUS.MODELS.path}${queries}`);
            }
            break;
          case pathname.includes(TAURUS.PIPELINES.path):
            {
              const queries = getDefaultQueries('pipelines', current_workspace_id || TAURUS_ROOT);
              navigate(`${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}${queries}`);
            }
            break;
          case pathname.includes(TAURUS.PIPELINE_DEFINITIONS.path):
            {
              const queries = getDefaultQueries('pipeline_definitions', current_workspace_id || TAURUS_ROOT);
              navigate(`${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_DEFINITIONS.path}${queries}`);
            }
            break;
        }
        dispatch(
          updateModal({
            [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES]: {
              is_open: false,
              workspace_id: TAURUS_ROOT,
              current_workspace_id: TAURUS_ROOT,
              update: null,
            },
          }),
        );

        break;
      case TAURUS_CONFIRMATION_MODAL_TYPE.WORKSPACE_ARCHIVE: {
        try {
          dispatch(updateSettings({ loading: true }));
          await Taurus.archiveTaurusWorkspace(current_workspace_id);
          dispatch(updateSnackbar({ title: `Workspace was archived`, icon: faArchive, color_primary: '#fff' }));
          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.WORKSPACE_RESTORE: {
        try {
          dispatch(updateSettings({ loading: true }));
          await Taurus.unarchiveTaurusWorkspace(current_workspace_id);
          dispatch(updateSnackbar({ title: `Workspace was restored`, icon: faArrowRotateLeft, color_primary: '#fff' }));
          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.SQL_TEMPLATE_STATUS: {
        try {
          const { is_archived, sql_template_name, sql_template_id } = taurus_sql_template;
          dispatch(updateSettings({ loading: true }));

          if (is_archived) {
            await Taurus.unarchiveTaurusSqlTemplate(sql_template_id);
          } else {
            await Taurus.archiveTaurusSqlTemplate(sql_template_id);
          }

          const title = is_archived ? `${sql_template_name} was restored` : `${sql_template_name} was archived`;
          const icon = is_archived ? faArrowRotateLeft : faArchive;

          dispatch(updateSnackbar({ title, icon, color_primary: '#fff' }));
          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.MODEL_ACTIVATE: {
        try {
          const { model_id, model_name } = taurus_model;
          dispatch(updateSettings({ loading: true }));

          await Taurus.activateTaurusModel(model_id);
          dispatch(updateSnackbar({ title: `${model_name} was activated`, icon: faCheck, color_primary: '#fff' }));
          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.MODEL_ARCHIVE: {
        try {
          const { is_archived, model_id, model_name } = taurus_model;
          dispatch(updateSettings({ loading: true }));

          if (is_archived) {
            await Taurus.unarchiveTaurusModel(model_id);
          } else {
            await Taurus.archiveTaurusModel(model_id);
          }

          const title = is_archived ? `${model_name} was restored` : `${model_name} was archived`;
          const icon = is_archived ? faArrowRotateLeft : faArchive;

          dispatch(updateSnackbar({ title, icon, color_primary: '#fff' }));
          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.ITEM_CASCADE_DELETE: {
        try {
          const { item_id, item_name } = taurus_item;
          dispatch(updateSettings({ loading: true }));

          await Taurus.cascadeDeleteTaurusItem(item_id);
          dispatch(updateSnackbar({ title: `${item_name} and children have been removed`, icon: faCheck, color_primary: '#fff' }));
          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.ITEM_CASCADE_ARCHIVE: {
        try {
          const { item_id, item_name } = taurus_item;
          dispatch(updateSettings({ loading: true }));

          await Taurus.archiveTaurusItem(item_id);
          dispatch(updateSnackbar({ title: `${item_name} and children have been archived`, icon: faCheck, color_primary: '#fff' }));

          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.ITEM_CASCADE_RESET: {
        try {
          const { item_id, item_name } = taurus_item;
          dispatch(updateSettings({ loading: true }));

          await Taurus.cascadeResetTaurusItem(item_id);
          dispatch(updateSnackbar({ title: `${item_name} and children have been reset`, icon: faCheck, color_primary: '#fff' }));

          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.ITEM_CASCADE_STOP: {
        try {
          const { item_id, item_name } = taurus_item;
          dispatch(updateSettings({ loading: true }));

          await Taurus.stopTaurusItem(item_id);
          dispatch(updateSnackbar({ title: `${item_name} and children have been stopped`, icon: faCheck, color_primary: '#fff' }));

          refreshPage();
        } catch (err) {
          handleError(err);
        }
        break;
      }
      case TAURUS_CONFIRMATION_MODAL_TYPE.PIPELINE_RESET:
        {
          const { pipeline_id, pipeline_name } = taurus_pipeline;
          try {
            dispatch(updateSettings({ loading: true }));
            await Taurus.resetTaurusPipeline(pipeline_id);

            dispatch(updateSnackbar({ title: `${pipeline_name} has been reset`, icon: faCheck, color_primary: '#fff' }));
            refreshPage();
          } catch (err) {
            handleError(err);
          }
        }
        break;
      case TAURUS_CONFIRMATION_MODAL_TYPE.PIPELINE_STOP:
        {
          const { pipeline_id, pipeline_name } = taurus_pipeline;

          try {
            dispatch(updateSettings({ loading: true }));
            await Taurus.stopTaurusPipeline(pipeline_id);

            dispatch(updateSnackbar({ title: `${pipeline_name} has been stopped`, icon: faCheck, color_primary: '#fff' }));
            refreshPage();
          } catch (err) {
            handleError(err);
          }
        }
        break;
      case TAURUS_CONFIRMATION_MODAL_TYPE.PIPELINE_RESET_TERMINAL:
        {
          const { pipeline_id, pipeline_name } = taurus_pipeline;

          try {
            dispatch(updateSettings({ loading: true }));
            await Taurus.resetTerminalTaurusPipeline(pipeline_id);

            dispatch(updateSnackbar({ title: `${pipeline_name} items have been reset`, icon: faCheck, color_primary: '#fff' }));
            refreshPage();
          } catch (err) {
            handleError(err);
          }
        }
        break;
      default: {
        dispatch(updateModal({ confirm: { is_open: false } }));
      }
    }
  }, [
    Taurus,
    navigate,
    pathname,
    taurus_pipeline,
    taurus_item,
    current_workspace_id,
    getDefaultQueries,
    taurus_sql_template,
    taurus_one_hot_mapping,
    taurus_model,
    dispatch,
    handleError,
    refreshPage,
    message,
    type,
  ]);

  return { onConfirm };
};
