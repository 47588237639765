import { TaurusSqlTemplate } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusSqlTemplate[] = [];

export const taurusSqlTemplatesSlice = createSlice({
  name: SLICE.TAURUS_SQL_TEMPLATES,
  initialState,
  reducers: {
    clearTaurusSqlTemplates: () => initialState,
    setTaurusSqlTemplates: (_, action: PayloadAction<TaurusSqlTemplate[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusSqlTemplates, setTaurusSqlTemplates } = taurusSqlTemplatesSlice.actions;

export default taurusSqlTemplatesSlice.reducer;
