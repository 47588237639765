import { TaurusItemNumericalStatistic } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusItemNumericalStatistic[] = [];

export const taurusItemNumericalStatisticsSlice = createSlice({
  name: SLICE.TAURUS_ITEM_NUMERICAL_STATISTICS,
  initialState,
  reducers: {
    clearTaurusItemNumericalStatistics: () => initialState,
    setTaurusItemNumericalStatistics: (_, action: PayloadAction<TaurusItemNumericalStatistic[]>) => action.payload,
  },
});

export const { clearTaurusItemNumericalStatistics, setTaurusItemNumericalStatistics } = taurusItemNumericalStatisticsSlice.actions;

export default taurusItemNumericalStatisticsSlice.reducer;
