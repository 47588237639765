import React, { memo, useContext } from 'react';
import { CARET, CellAction, RowContext } from '@cognitiv/cassiopeia-ui';
import { TaurusParameter } from '@cognitiv/galaxy-api';
import { faExpand } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from 'hooks/useTheme';

export const CellParameterExpand = memo(({ onClick }: { onClick: (item: TaurusParameter) => void; read_only?: boolean }) => {
  const { row } = useContext(RowContext);
  const {
    theme: { cell_remove_disabled },
  } = useTheme();

  if (!row.parameter_name || row.parameter_name === '') {
    return <CellAction icon={faExpand} theme={cell_remove_disabled} />;
  }

  return <CellAction icon={faExpand} message="View Expansion" caret={CARET.BOTTOM} onClick={() => onClick(row)} />;
});
