import React, { FC, PropsWithChildren } from 'react';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const StaticBreadcrumb: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <div className={classNames(cn.breadcrumb, cn.static)}>{children}</div>
      <FontAwesomeIcon icon={faCaretRight} className={cn.icon} />
    </>
  );
};
