import { useCallback, useState } from 'react';
import { selectSettings } from 'ducks/settings/selectors';
import { updateSettings } from 'ducks/settings/slices';
import { useLocation } from 'react-router-dom';
import { TAURUS } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export enum GROUP {
  MODELS = 'models',
  PIPELINES = 'pipelines',
  PIPELINE_QUERY_EXECUTIONS = 'pipeline_query_executions',
  ONE_HOT_MAPPINGS = 'one_hot_mappings',
  SQL_TEMPLATES = 'sql_templates',
  DATASETS = 'datasets',
  WORKSPACES = 'workspaces',
}

export enum PAGE_STORAGE {
  MODELS = 'models',
  PIPELINES = 'pipelines',
  PIPELINE_DEFINITIONS = 'pipeline_definitions',
  PIPELINE_ITEMS = 'pipeline_items',
  PIPELINE_QUERY_EXECUTIONS = 'pipeline_query_executions',
  ONE_HOT_MAPPINGS = 'one_hot_mappings',
  SQL_TEMPLATES = 'sql_templates',
  DATASETS = 'datasets',
  WORKSPACES = 'workspaces',
}

export const useMenu = () => {
  const { pathname } = useLocation();
  const { is_menu_open } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();

  const [expanded_menu_group, openMenuGroup] = useState<GROUP | undefined | GROUP[]>(undefined);

  const getApplicationPage = (deep: number) => {
    const paths = pathname.split('/');
    const application = paths[deep] || '';

    if (application.includes('?')) {
      return application.split('?')[0];
    }
    return application;
  };

  const handleOpenMenuGroup = useCallback(
    (menu?: GROUP) => {
      if (is_menu_open) {
        if (menu && expanded_menu_group?.includes(menu)) {
          openMenuGroup((prevState) => {
            return [...((prevState as GROUP[]) || [])?.filter((item) => item !== menu)];
          });
        } else {
          openMenuGroup((prevState) => [...((prevState as GROUP[]) || []), menu as GROUP]);
        }
        return;
      }

      openMenuGroup(menu);
    },
    [expanded_menu_group, is_menu_open],
  );

  const handleExpand = useCallback(() => {
    if (is_menu_open) return;
    handleOpenMenuGroup();
  }, [handleOpenMenuGroup, is_menu_open]);

  const getApplicationGroup = () => {
    const paths = pathname.split('/');

    switch (true) {
      case paths[3] === TAURUS.PIPELINES.path:
      case paths[3] === TAURUS.PIPELINE_DEFINITIONS.path:
      case paths[3] === TAURUS.PIPELINE_ITEMS.path:
        return GROUP.PIPELINES;
      case paths[3] === TAURUS.PIPELINE_QUERY_EXECUTIONS.path:
        return GROUP.PIPELINE_QUERY_EXECUTIONS;
      case paths[3] === TAURUS.WORKSPACES.path:
        return GROUP.WORKSPACES;
      case paths[3] === TAURUS.MODELS.path:
        return GROUP.MODELS;
      case paths[3] === TAURUS.ONE_HOT_MAPPINGS.path:
        return GROUP.ONE_HOT_MAPPINGS;
      case paths[3] === TAURUS.SQL_TEMPLATES.path:
        return GROUP.SQL_TEMPLATES;
      case paths[3] === TAURUS.DATASETS.path:
        return GROUP.DATASETS;
      default:
        return '';
    }
  };

  const group = getApplicationGroup();

  const setMenu = useCallback(
    (is_open: boolean) => {
      dispatch(updateSettings({ is_menu_open: is_open }));
    },
    [dispatch],
  );

  return {
    group,
    getApplicationPage,
    is_menu_open,
    setMenu,
    expanded_menu_group,
    handleOpenMenuGroup,
    handleExpand,
  };
};
