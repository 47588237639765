import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Button, Form, InputText, Modal } from '@cognitiv/cassiopeia-ui';
import { getValidationError, mergeSearchQuery } from '@cognitiv/cicada-ui';
import { TaurusParameter, TaurusWorkspace, useClientContext } from '@cognitiv/galaxy-api';
import { selectModal } from 'ducks/modals/selectors';
import { updateModal } from 'ducks/modals/slices';
import { updateSettings } from 'ducks/settings/slices';
import { useValidate } from 'hooks/useValidate';
import { Parameters } from 'products/taurus/components/parameters/Parameters';
import { validation } from 'products/taurus/modals/manage-taurus-workspace/validation';
import { TAURUS_MODALS } from 'products/taurus/modals/types';
import { createParameters } from 'products/taurus/modals/utils';
import { TAURUS_ROOT } from 'products/taurus/operators/enums';
import { taurus_workspace_default } from 'products/taurus/operators/workspace/defaults';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { uuidv4 } from 'utils/uuid';

import cn from 'products/taurus/modals/manage-taurus-workspace/ManageTaurusWorkspace.module.scss';

export const ManageTaurusWorkspace = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { Taurus, handleError } = useClientContext();

  const manage_taurus_workspaces = useAppSelector((state) => selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES));
  const { is_open, workspace_id, parent_workspace_id, is_navigation } = useAppSelector((state) =>
    selectModal(state, TAURUS_MODALS.MANAGE_TAURUS_WORKSPACE),
  );

  const [parent_workspace, setParentWorkspace] = useState<TaurusWorkspace>({ ...taurus_workspace_default });
  const [workspace, setWorkspace] = useState<TaurusWorkspace>({ ...taurus_workspace_default });

  const { validate, errors, resetErrors } = useValidate(workspace, validation);

  useEffect(() => {
    const setupPage = async () => {
      try {
        if (!is_open) return;

        dispatch(updateSettings({ loading: true }));

        // parent might not exists if root
        if (parent_workspace_id) {
          const taurus_parent_workspace = await Taurus.getTaurusWorkspace(parent_workspace_id);
          setParentWorkspace(() => ({ ...taurus_parent_workspace }));
        }

        // workspace wont exist on create
        if (workspace_id) {
          const taurus_workspace = await Taurus.getTaurusWorkspace(workspace_id);
          setWorkspace(() => ({ ...taurus_workspace }));
        } else {
          setWorkspace(() => ({ ...taurus_workspace_default }));
        }
        dispatch(updateSettings({ loading: false }));
      } catch (err) {
        handleError(err);
      }
    };
    setupPage();
  }, [dispatch, workspace_id, parent_workspace_id, is_open, handleError, Taurus]);

  const onClose = useCallback(() => {
    resetErrors();
    setWorkspace({ ...taurus_workspace_default });
    setParentWorkspace({ ...taurus_workspace_default });
    dispatch(
      updateModal({
        [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACE]: {
          is_open: false,
          workspace_id: null,
          parent_workspace_id: null,
          is_navigation: false,
        },
      }),
    );
  }, [dispatch, resetErrors]);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();

        const is_valid = validate();
        if (!is_valid) return;

        dispatch(updateSettings({ loading: true }));
        const { workspace_name, parameters } = workspace;

        const payload = {
          parent_workspace_id: parent_workspace.workspace_id || TAURUS_ROOT,
          workspace_name,
          parameters: createParameters(parameters),
        };

        // navigation menu has different logic from normal create/update workflows
        if (is_navigation) {
          if (workspace.workspace_id) {
            await Taurus.updateTaurusWorkspace(workspace.workspace_id, payload);
            dispatch(
              updateModal({
                [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES]: {
                  is_open: manage_taurus_workspaces.is_open,
                  workspace_id: workspace.workspace_id,
                  current_workspace_id: parent_workspace_id,
                  update: uuidv4(),
                },
              }),
            );
          }
          if (!workspace.workspace_id) {
            const entity_id = await Taurus.createTaurusWorkspace(parent_workspace.workspace_id || TAURUS_ROOT, payload);
            dispatch(
              updateModal({
                [TAURUS_MODALS.MANAGE_TAURUS_WORKSPACES]: {
                  is_open: manage_taurus_workspaces.is_open,
                  workspace_id: entity_id,
                  current_workspace_id: parent_workspace_id,
                  update: uuidv4(),
                },
              }),
            );
          }
        }

        // normal create/update workflow
        if (!is_navigation) {
          if (workspace.workspace_id) {
            await Taurus.updateTaurusWorkspace(workspace.workspace_id, payload);
            navigate(`${pathname}${mergeSearchQuery(search, { update: uuidv4() })}`);
          }
          if (!workspace.workspace_id) {
            const entity_id = await Taurus.createTaurusWorkspace(parent_workspace.workspace_id || TAURUS_ROOT, payload);
            navigate(`${pathname}${mergeSearchQuery(search, { workspace_id: entity_id, update: uuidv4() })}`);
          }
        }

        dispatch(updateSettings({ loading: false }));
        onClose();
      } catch (err) {
        handleError(err);
      }
    },
    [
      validate,
      dispatch,
      parent_workspace_id,
      manage_taurus_workspaces,
      parent_workspace,
      workspace,
      is_navigation,
      Taurus,
      onClose,
      handleError,
      navigate,
      pathname,
      search,
    ],
  );

  const onChangeParameters = useCallback(
    (parameters: TaurusParameter[]) => {
      resetErrors();
      setWorkspace((prev) => ({ ...prev, parameters }));
    },
    [resetErrors],
  );

  const onChange = useCallback(
    (item: Partial<TaurusWorkspace>) => {
      resetErrors();
      setWorkspace((prev) => ({ ...prev, ...item }));
    },
    [resetErrors],
  );

  return (
    <Modal
      identifier={TAURUS_MODALS.MANAGE_TAURUS_WORKSPACE}
      is_open={is_open}
      width={740}
      onClose={onClose}
      title={workspace.workspace_id ? 'Update Workspace' : 'Create Workspace'}
    >
      <Form onSubmit={onSubmit}>
        <div className={cn.body}>
          <InputText
            label="Name"
            onChange={({ value }) => onChange({ workspace_name: value })}
            value={workspace.workspace_name}
            auto_focus
            error={getValidationError({ errors, key: 'workspace_name' })}
          />
          <Parameters label="Inherited Parameters" parameters={parent_workspace.parameters} parameters_override={workspace.parameters} read_only />
          <Parameters parameters={workspace.parameters} onChange={onChangeParameters} />
        </div>
        <div className={cn.footer}>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit">{workspace.workspace_id ? 'Save' : 'Create'}</Button>
        </div>
      </Form>
    </Modal>
  );
};
