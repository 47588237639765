import { TaurusOneHotMapping } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusOneHotMapping[] = [];

export const taurusOneHotMappingsSlice = createSlice({
  name: SLICE.TAURUS_ONE_HOT_MAPPINGS,
  initialState,
  reducers: {
    clearTaurusOneHotMappings: () => initialState,
    setTaurusOneHotMappings: (_, action: PayloadAction<TaurusOneHotMapping[]>) => action.payload,
  },
});

export const { clearTaurusOneHotMappings, setTaurusOneHotMappings } = taurusOneHotMappingsSlice.actions;

export default taurusOneHotMappingsSlice.reducer;
