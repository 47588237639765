import { TaurusPartitionCollisionResolution } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPartitionCollisionResolution[] = [];

export const taurusPartitionCollisionResolutionsListSlice = createSlice({
  name: SLICE.TAURUS_PARTITION_RESOLUTION_COLLISIONS_LIST,
  initialState,
  reducers: {
    clearTaurusPartitionCollisionResolutionsList: () => initialState,
    setTaurusPartitionCollisionResolutionsList: (_, action: PayloadAction<TaurusPartitionCollisionResolution[]>) => action.payload,
  },
});

export const { clearTaurusPartitionCollisionResolutionsList, setTaurusPartitionCollisionResolutionsList } =
  taurusPartitionCollisionResolutionsListSlice.actions;

export default taurusPartitionCollisionResolutionsListSlice.reducer;
