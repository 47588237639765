import { TaurusItemState } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusItemState[] = [];

export const taurusItemStatesListSlice = createSlice({
  name: SLICE.TAURUS_ITEM_STATES_LIST,
  initialState,
  reducers: {
    clearTaurusItemStatesList: () => initialState,
    setTaurusItemStatesList: (_, action: PayloadAction<TaurusItemState[]>) => action.payload,
  },
});

export const { clearTaurusItemStatesList, setTaurusItemStatesList } = taurusItemStatesListSlice.actions;

export default taurusItemStatesListSlice.reducer;
