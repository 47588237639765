import { TaurusEvaluateItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_evaluate_item_default } from 'products/taurus/operators/evaluate-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusEvaluateItem>;
}

const initialState: TaurusEvaluateItem = { ...taurus_evaluate_item_default };

export const taurusEvaluateItemSlice = createSlice({
  name: SLICE.TAURUS_EVALUATE_ITEM,
  initialState,
  reducers: {
    updateTaurusEvaluateItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusEvaluateItem: () => initialState,
    setTaurusEvaluateItem: (_, action: PayloadAction<TaurusEvaluateItem>) => action.payload,
  },
});

export const { updateTaurusEvaluateItem, clearTaurusEvaluateItem, setTaurusEvaluateItem } = taurusEvaluateItemSlice.actions;

export default taurusEvaluateItemSlice.reducer;
