import { TaurusItem } from '@cognitiv/galaxy-api';

export enum ITEM_CARD_TYPE {
  NODE = 'Node',
  ROOT_NODE = 'Root Node',
  INVISIBLE_NODE = 'Invisible Node',
  PHANTOM_NODE = 'Phantom Node',
  CONNECTED_PHANTOM_NODE = 'Connected Child Node',
}
export interface TaurusGraphItem extends TaurusItem {
  child_item_id: number | null;
  children_item_ids: number[];
  item_card_type: ITEM_CARD_TYPE;
}
