import React from 'react';

import cn from 'products/public/components/warning/Warning.module.scss';
const { REACT_APP_ENVIRONMENT } = process.env;

export const Warning = () => {
  if (REACT_APP_ENVIRONMENT === 'production') {
    return (
      <div className={cn.warning}>
        <p>v{process.env.REACT_APP_VERSION}</p>
      </div>
    );
  }
  return (
    <div className={cn.warning}>
      <p>v{process.env.REACT_APP_VERSION} All data within this portal is for test purposes and will not affect the production environment.</p>
    </div>
  );
};
