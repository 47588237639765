import { TaurusPipelineEventType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPipelineEventType[] = [];

export const taurusPipelineEventsListSlice = createSlice({
  name: SLICE.TAURUS_PIPELINE_EVENTS_LIST,
  initialState,
  reducers: {
    clearTaurusPipelineEventsList: () => initialState,
    setTaurusPipelineEventsList: (_, action: PayloadAction<TaurusPipelineEventType[]>) => action.payload,
  },
});

export const { clearTaurusPipelineEventsList, setTaurusPipelineEventsList } = taurusPipelineEventsListSlice.actions;

export default taurusPipelineEventsListSlice.reducer;
