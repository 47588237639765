import { TaurusEvaluationAlgorithm } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusEvaluationAlgorithm[] = [];

export const taurusEvaluationAlgorithmsListSlice = createSlice({
  name: SLICE.TAURUS_EVALUATION_ALGORITHMS_LIST,
  initialState,
  reducers: {
    clearTaurusEvaluationAlgorithmsList: () => initialState,
    setTaurusEvaluationAlgorithmsList: (_, action: PayloadAction<TaurusEvaluationAlgorithm[]>) => action.payload,
  },
});

export const { clearTaurusEvaluationAlgorithmsList, setTaurusEvaluationAlgorithmsList } = taurusEvaluationAlgorithmsListSlice.actions;

export default taurusEvaluationAlgorithmsListSlice.reducer;
