import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DropdownContext, Option, OptionNone, Select } from '@cognitiv/cassiopeia-ui';
import { useClientContext } from '@cognitiv/galaxy-api';
import { SmallSpinner } from 'components/layout/loading/Loading';
import { selectTaurusWorkspace } from 'products/taurus/operators/workspace/selectors';
import { selectTaurusWorkspaces } from 'products/taurus/operators/workspaces/selectors';
import { setTaurusWorkspaces } from 'products/taurus/operators/workspaces/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const WorkspaceBreadcrumbSelect = ({ index, active_index }: { index: number; active_index: number }) => {
  const dispatch = useAppDispatch();
  const taurus_workspace = useAppSelector(selectTaurusWorkspace);
  const taurus_workspaces = useAppSelector(selectTaurusWorkspaces);
  const { Taurus, handleError } = useClientContext();

  const { setOpen } = useContext(DropdownContext);
  const [loading, setLoading] = useState(false);

  const { workspace_id_path_split } = taurus_workspace;

  const onMouseEnter = useCallback(async () => {
    if (
      taurus_workspaces.length &&
      taurus_workspaces.every((workspace) => String(workspace.parent_workspace_id) === workspace_id_path_split[index])
    ) {
      setOpen(true);

      return;
    }

    try {
      setLoading(true);
      setOpen(true);
      const taurus_workspaces = await Taurus.getTaurusWorkspaceChildren(Number(workspace_id_path_split[index]));
      dispatch(setTaurusWorkspaces(taurus_workspaces));
      setLoading(false);
    } catch (err) {
      handleError(err);
    }
  }, [Taurus, dispatch, handleError, index, setOpen, taurus_workspaces, workspace_id_path_split]);

  const onMouseLeave = useCallback(() => {
    if (index === active_index) {
      setOpen(true);
      return;
    }
    setOpen(false);
    setLoading(false);
  }, [active_index, index, setOpen]);

  useEffect(() => {
    if (index === active_index) {
      onMouseEnter();
      return;
    }
    if (index !== active_index) {
      onMouseLeave();
    }
  }, [active_index]);

  return (
    <div className={cn.breadcrumb}>
      <Select>
        {!taurus_workspaces.length && !loading && <OptionNone />}
        {!loading &&
          taurus_workspaces.map((workspace) => {
            return <Option key={workspace.workspace_id} option={workspace} />;
          })}
        {loading && (
          <div className={cn.loader}>
            <SmallSpinner />
          </div>
        )}
      </Select>
    </div>
  );
};
