export enum TAURUS_QUERY_ENGINE_TYPE {
  ATHENA = 1,
  SPARK = 2,
  CLICKHOUSE = 3,
}

export const TAURUS_QUERY_ENGINE_TYPE_NAME = {
  1: 'Athena',
  2: 'Spark',
  3: 'Clickhouse',
};
