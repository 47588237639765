import { TaurusQueryItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_query_item_default } from 'products/taurus/operators/query-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusQueryItem>;
}

const initialState: TaurusQueryItem = { ...taurus_query_item_default };

export const taurusQueryItemSlice = createSlice({
  name: SLICE.TAURUS_QUERY_ITEM,
  initialState,
  reducers: {
    updateTaurusQueryItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusQueryItem: () => initialState,
    setTaurusQueryItem: (_, action: PayloadAction<TaurusQueryItem>) => action.payload,
  },
});

export const { updateTaurusQueryItem, clearTaurusQueryItem, setTaurusQueryItem } = taurusQueryItemSlice.actions;

export default taurusQueryItemSlice.reducer;
