import { TaurusSearchAlgorithm } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusSearchAlgorithm[] = [];

export const taurusSearchAlgorithmsListSlice = createSlice({
  name: SLICE.TAURUS_SEARCH_ALGORITHMS_LIST,
  initialState,
  reducers: {
    clearTaurusSearchAlgorithmsList: () => initialState,
    setTaurusSearchAlgorithmsList: (_, action: PayloadAction<TaurusSearchAlgorithm[]>) => action.payload,
  },
});

export const { clearTaurusSearchAlgorithmsList, setTaurusSearchAlgorithmsList } = taurusSearchAlgorithmsListSlice.actions;

export default taurusSearchAlgorithmsListSlice.reducer;
