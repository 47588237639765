import { TaurusDatasetReadItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_dataset_read_item_default } from 'products/taurus/operators/dataset-read-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusDatasetReadItem>;
}

const initialState: TaurusDatasetReadItem = { ...taurus_dataset_read_item_default };

export const taurusDatasetReadItemSlice = createSlice({
  name: SLICE.TAURUS_DATASET_READ_ITEM,
  initialState,
  reducers: {
    updateTaurusDatasetReadItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusDatasetReadItem: () => initialState,
    setTaurusDatasetReadItem: (_, action: PayloadAction<TaurusDatasetReadItem>) => action.payload,
  },
});

export const { updateTaurusDatasetReadItem, clearTaurusDatasetReadItem, setTaurusDatasetReadItem } = taurusDatasetReadItemSlice.actions;

export default taurusDatasetReadItemSlice.reducer;
