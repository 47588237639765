import { CONDITION_TYPE, VALIDATE } from '@cognitiv/cicada-ui';
import { WORKSPACE_ACTION, WORKSPACE_ITEM_ACTION_ICON } from 'products/taurus/overlays/manage-taurus-workspaces/enums';
import { TaurusWorkspaceAction } from 'products/taurus/overlays/manage-taurus-workspaces/types';
import { uuidv4 } from 'utils/uuid';

export const validation = {
  workspace_name: {
    type: VALIDATE.STRING,
    conditions: [
      {
        error: 'Name is required',
        type: CONDITION_TYPE.MIN_LENGTH,
        values: [1],
      },
    ],
  },
};

export const workspace_actions_edit: TaurusWorkspaceAction[] = [
  {
    action: WORKSPACE_ACTION.EDIT_WORKSPACE,
    icon: WORKSPACE_ITEM_ACTION_ICON[WORKSPACE_ACTION.EDIT_WORKSPACE],
    uuid: uuidv4(),
  },
];

export const workspace_actions_navigate: TaurusWorkspaceAction[] = [
  {
    action: WORKSPACE_ACTION.MODELS,
    icon: WORKSPACE_ITEM_ACTION_ICON[WORKSPACE_ACTION.MODELS],
    uuid: uuidv4(),
  },
  {
    action: WORKSPACE_ACTION.PIPELINE,
    icon: WORKSPACE_ITEM_ACTION_ICON[WORKSPACE_ACTION.PIPELINE],
    uuid: uuidv4(),
  },
  {
    action: WORKSPACE_ACTION.PIPELINE_DEFINITION,
    icon: WORKSPACE_ITEM_ACTION_ICON[WORKSPACE_ACTION.PIPELINE_DEFINITION],
    uuid: uuidv4(),
  },
  {
    action: WORKSPACE_ACTION.PIPELINE_ITEMS,
    icon: WORKSPACE_ITEM_ACTION_ICON[WORKSPACE_ACTION.PIPELINE_ITEMS],
    uuid: uuidv4(),
  },
  {
    action: WORKSPACE_ACTION.SQL_TEMPLATE,
    icon: WORKSPACE_ITEM_ACTION_ICON[WORKSPACE_ACTION.SQL_TEMPLATE],
    uuid: uuidv4(),
  },
  {
    action: WORKSPACE_ACTION.PIPELINE_QUERY_EXECUTIONS,
    icon: WORKSPACE_ITEM_ACTION_ICON[WORKSPACE_ACTION.PIPELINE_QUERY_EXECUTIONS],
    uuid: uuidv4(),
  },
];
