import { TaurusPredictItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_predict_item_default } from 'products/taurus/operators/predict-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusPredictItem>;
}

const initialState: TaurusPredictItem = { ...taurus_predict_item_default };

export const TaurusPredictItemSlice = createSlice({
  name: SLICE.TAURUS_PREDICT_ITEM,
  initialState,
  reducers: {
    updateTaurusPredictItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusPredictItem: () => initialState,
    setTaurusPredictItem: (_, action: PayloadAction<TaurusPredictItem>) => action.payload,
  },
});

export const { updateTaurusPredictItem, clearTaurusPredictItem, setTaurusPredictItem } = TaurusPredictItemSlice.actions;

export default TaurusPredictItemSlice.reducer;
