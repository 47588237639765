import { TaurusTrainItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';
import { taurus_train_item_default } from 'products/taurus/operators/train-item/defaults';

interface Payload {
  payload: Partial<TaurusTrainItem>;
}

const initialState: TaurusTrainItem = { ...taurus_train_item_default };

export const taurusTrainItemSlice = createSlice({
  name: SLICE.TAURUS_TRAIN_ITEM,
  initialState,
  reducers: {
    updateTaurusTrainItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusTrainItem: () => initialState,
    setTaurusTrainItem: (_, action: PayloadAction<TaurusTrainItem>) => action.payload,
  },
});

export const { updateTaurusTrainItem, clearTaurusTrainItem, setTaurusTrainItem } = taurusTrainItemSlice.actions;

export default taurusTrainItemSlice.reducer;
