import taurusColumnTypesListSlice from 'products/taurus/operators/column-types-list/slices';
import taurusCompressionFormatsListSlice from 'products/taurus/operators/compression-formats-list/slices';
import taurusCustomEtlItemSlice from 'products/taurus/operators/custom-etl-item/slices';
import taurusCustomItemSlice from 'products/taurus/operators/custom-item/slices';
import taurusDataTypesListSlice from 'products/taurus/operators/data-types-list/slices';
import taurusDatasetSlice from 'products/taurus/operators/dataset/slices';
import taurusDatasetPartitionsSlice from 'products/taurus/operators/dataset-partitions/slices';
import taurusDatasetPreviewSlice from 'products/taurus/operators/dataset-preview/slices';
import taurusDatasetReadItemSlice from 'products/taurus/operators/dataset-read-item/slices';
import taurusDatasetWriteItemSlice from 'products/taurus/operators/dataset-write-item/slices';
import taurusDatasetsSlice from 'products/taurus/operators/datasets/slices';
import taurusEvaluateItemSlice from 'products/taurus/operators/evaluate-item/slices';
import taurusEvaluationAlgorithmsListSlice from 'products/taurus/operators/evaluation-algorithms-list/slices';
import taurusFeaturesListSlice from 'products/taurus/operators/features-list/slices';
import taurusFilterOperatorTypesListSlice from 'products/taurus/operators/filter-operator-types-list/slices';
import taurusImportItemSlice from 'products/taurus/operators/import-item/slices';
import taurusItemSlice from 'products/taurus/operators/item/slices';
import taurusItemCategoricalStatisticsSlice from 'products/taurus/operators/item-categorical-statistics/slices';
import taurusItemNumericalStatisticsSlice from 'products/taurus/operators/item-numerical-statistics/slices';
import taurusItemQueuesSlice from 'products/taurus/operators/item-queues/slices';
import taurusItemStatesListSlice from 'products/taurus/operators/item-states-list/slices';
import taurusItemTypesListSlice from 'products/taurus/operators/item-types-list/slices';
import taurusItemsSlice from 'products/taurus/operators/items/slices';
import taurusLabelTypesListSlice from 'products/taurus/operators/label-types-list/slices';
import taurusModelSlice from 'products/taurus/operators/model/slices';
import taurusModelVersionsSlice from 'products/taurus/operators/model-versions/slices';
import taurusModelsSlice from 'products/taurus/operators/models/slices';
import TaurusNonEtlQueryItemSlice from 'products/taurus/operators/non-etl-query-item/slices';
import taurusOneHotMappingSlice from 'products/taurus/operators/one-hot-mapping/slices';
import TaurusOneHotMappingFeaturesSlice from 'products/taurus/operators/one-hot-mapping-features-list/slices';
import taurusOneHotMappingsSlice from 'products/taurus/operators/one-hot-mappings/slices';
import taurusOutputTypesListSlice from 'products/taurus/operators/output-types-list/slices';
import taurusParentItemsSlice from 'products/taurus/operators/parent-items/slices';
import taurusPartitionCollisionResolutionsListSlice from 'products/taurus/operators/partition-collision-resolutions-list/slices';
import taurusPipelineSlice from 'products/taurus/operators/pipeline/slices';
import taurusPipelineEventsSlice from 'products/taurus/operators/pipeline-events-list/slices';
import taurusPipelineItemsSlice from 'products/taurus/operators/pipeline-items/slices';
import taurusPipelineScheduleSlice from 'products/taurus/operators/pipeline-schedule/slices';
import taurusPipelineStatesListSlice from 'products/taurus/operators/pipeline-states-list/slices';
import taurusPipelinesSlice from 'products/taurus/operators/pipelines/slices';
import TaurusPredictItemSlice from 'products/taurus/operators/predict-item/slices';
import taurusProjectionItemSlice from 'products/taurus/operators/projection-item/slices';
import taurusProjectionItemFeaturesSlice from 'products/taurus/operators/projection-item-features/slices';
import TaurusPublishItemSlice from 'products/taurus/operators/publish-item/slices';
import taurusQueryEnginesListSlice from 'products/taurus/operators/query-engines-list/slices';
import taurusQueryExecutionsSlice from 'products/taurus/operators/query-executions/slices';
import taurusQueryItemSlice from 'products/taurus/operators/query-item/slices';
import taurusQuerySqlPreviewSlice from 'products/taurus/operators/query-sql-preview/slices';
import taurusRowTypesListSlice from 'products/taurus/operators/row-types-list/slices';
import taurusSearchAlgorithmsListSlice from 'products/taurus/operators/search-algorithms-list/slices';
import taurusSortDirectionsListSlice from 'products/taurus/operators/sort-directions-list/slices';
import TaurusSplitMethodsListSlice from 'products/taurus/operators/split-methods-list/slices';
import taurusSqlEnginesListSlice from 'products/taurus/operators/sql-engines-list/slices';
import taurusSqlTemplateSlice from 'products/taurus/operators/sql-template/slices';
import taurusSqlTemplateEnginesListSlice from 'products/taurus/operators/sql-template-engines-list/slices';
import taurusSqlTemplatesSlice from 'products/taurus/operators/sql-templates/slices';
import TaurusStrategyPublisherItemSlice from 'products/taurus/operators/strategy-publisher-item/slices';
import taurusSubscriptionsSlice from 'products/taurus/operators/subscriptions/slices';
import taurusTrainItemSlice from 'products/taurus/operators/train-item/slices';
import TaurusTransformItemSlice from 'products/taurus/operators/transform-item/slices';
import taurusWorkspaceSlice from 'products/taurus/operators/workspace/slices';
import taurusWorkspacesSlice from 'products/taurus/operators/workspaces/slices';

export const taurus = {
  taurus_filter_operators_types_list: taurusFilterOperatorTypesListSlice,
  taurus_data_types_list: taurusDataTypesListSlice,
  taurus_column_types_list: taurusColumnTypesListSlice,
  taurus_compression_formats_list: taurusCompressionFormatsListSlice,
  taurus_custom_item: taurusCustomItemSlice,
  taurus_custom_etl_item: taurusCustomEtlItemSlice,
  taurus_output_types_list: taurusOutputTypesListSlice,
  taurus_row_types_list: taurusRowTypesListSlice,
  taurus_sql_engines_list: taurusSqlEnginesListSlice,
  taurus_query_engines_list: taurusQueryEnginesListSlice,
  taurus_sql_template_engines_list: taurusSqlTemplateEnginesListSlice,
  taurus_item: taurusItemSlice,
  taurus_one_hot_mapping: taurusOneHotMappingSlice,
  taurus_one_hot_mappings: taurusOneHotMappingsSlice,
  taurus_item_types_list: taurusItemTypesListSlice,
  taurus_query_executions: taurusQueryExecutionsSlice,
  taurus_pipelines: taurusPipelinesSlice,
  taurus_pipeline: taurusPipelineSlice,
  taurus_pipeline_schedule: taurusPipelineScheduleSlice,
  taurus_pipeline_items: taurusPipelineItemsSlice,
  taurus_item_queues: taurusItemQueuesSlice,
  taurus_predict_item: TaurusPredictItemSlice,
  taurus_query_sql_preview: taurusQuerySqlPreviewSlice,
  taurus_dataset_preview: taurusDatasetPreviewSlice,
  taurus_item_numerical_statistics: taurusItemNumericalStatisticsSlice,
  taurus_item_categorical_statistics: taurusItemCategoricalStatisticsSlice,
  taurus_evaluate_item: taurusEvaluateItemSlice,
  taurus_publish_item: TaurusPublishItemSlice,
  taurus_strategy_publisher_item: TaurusStrategyPublisherItemSlice,
  taurus_models: taurusModelsSlice,
  taurus_model: taurusModelSlice,
  taurus_model_versions: taurusModelVersionsSlice,
  taurus_train_item: taurusTrainItemSlice,
  taurus_search_algorithms_list: taurusSearchAlgorithmsListSlice,
  taurus_evaluation_algorithms_list: taurusEvaluationAlgorithmsListSlice,
  taurus_query_item: taurusQueryItemSlice,
  taurus_non_etl_query_item: TaurusNonEtlQueryItemSlice,
  taurus_import_item: taurusImportItemSlice,
  taurus_projection_item: taurusProjectionItemSlice,
  taurus_split_methods_list: TaurusSplitMethodsListSlice,
  taurus_features_list: taurusFeaturesListSlice,
  taurus_one_hot_mapping_features_list: TaurusOneHotMappingFeaturesSlice,
  taurus_projection_item_features: taurusProjectionItemFeaturesSlice,
  taurus_label_types_list: taurusLabelTypesListSlice,
  taurus_items: taurusItemsSlice,
  taurus_item_states_list: taurusItemStatesListSlice,
  taurus_transform_item: TaurusTransformItemSlice,
  taurus_sql_template: taurusSqlTemplateSlice,
  taurus_sql_templates: taurusSqlTemplatesSlice,
  taurus_workspace: taurusWorkspaceSlice,
  taurus_workspaces: taurusWorkspacesSlice,
  taurus_subscriptions: taurusSubscriptionsSlice,
  taurus_pipeline_events_list: taurusPipelineEventsSlice,
  taurus_pipeline_states_list: taurusPipelineStatesListSlice,
  taurus_datasets: taurusDatasetsSlice,
  taurus_dataset: taurusDatasetSlice,
  taurus_dataset_read_item: taurusDatasetReadItemSlice,
  taurus_dataset_write_item: taurusDatasetWriteItemSlice,
  taurus_partition_collision_resolutions_list: taurusPartitionCollisionResolutionsListSlice,
  taurus_sort_directions_list: taurusSortDirectionsListSlice,
  taurus_parent_items: taurusParentItemsSlice,
  taurus_dataset_partitions: taurusDatasetPartitionsSlice,
};
