import React from 'react';
import { Menu } from 'components/menu/Menu';
import { Outlet } from 'react-router';
import cn from 'products/public/pages/templates/Template.module.scss';
import { Content } from 'components/layout/content/Content';

export default () => {
  return (
    <div className={cn.template}>
      <Menu />
      <Content>
        <Outlet />
      </Content>
    </div>
  );
};
