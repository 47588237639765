import React, { useCallback, useEffect } from 'react';
import { ClientProvider } from '@cognitiv/galaxy-api';
import { updateModal } from 'ducks/modals/slices';
import { updateSettings } from 'ducks/settings/slices';
import { selectUser } from 'ducks/user/selectors';
import { clearUser } from 'ducks/user/slices';
import { THEME } from 'hooks/useGlobalTheme';
import ErrorPage from 'products/public/pages/error/ErrorPage';
import LoginPage from 'products/public/pages/login/LoginPage';
import ErrorTemplate from 'products/public/pages/templates/ErrorTemplate';
import TaurusTemplate from 'products/taurus/template/ApplicationTemplate';
import { shallowEqual } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PRIVATE, PUBLIC, TAURUS } from 'routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { PrivateTemplate } from 'templates/PrivateTemplate';
import { PublicTemplate } from 'templates/PublicTemplate';
import { deleteLocalStorage, getLocalStorage } from 'utils';

import { Suspense } from 'Suspense';

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_VERSION,
  REACT_APP_SERVICE,
  REACT_APP_SOL,
  REACT_APP_GEMINI,
  REACT_APP_HYPERION,
  REACT_APP_TITAN,
  REACT_APP_LIBRA,
  REACT_APP_ORION,
  REACT_APP_VIRGO,
  REACT_APP_TAURUS,
} = process.env;

export const App = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser, shallowEqual);

  const errorCallback = useCallback(
    (err: any) => {
      dispatch(updateSettings({ loading: false }));

      if (err.status === 401 || err.error === 'HashMismatch' || err.message === 'Network Error') {
        dispatch(clearUser());
        deleteLocalStorage('user');
        return err;
      }
      dispatch(
        updateModal({
          error: {
            is_open: true,
            name: err.name || null,
            message: err.message || null,
            error: err.error || null,
            status: err.status || null,
            stack: err.stack || null,
            raw: err.raw || null,
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const { private_key, public_key } = user;
    const settings = getLocalStorage('settings');
    const theme = settings.theme || THEME.DARK;

    if (private_key && public_key) {
      dispatch(updateSettings({ ...settings, theme }));
      return;
    }
    dispatch(updateSettings({ theme }));
  }, [dispatch, user, user.private_key, user.public_key]);

  return (
    <ClientProvider
      service={REACT_APP_SERVICE || 'error'}
      environment={REACT_APP_ENVIRONMENT || 'development'}
      version={REACT_APP_VERSION || '0.0.0'}
      host={{
        SOL: REACT_APP_SOL,
        GEMINI: REACT_APP_GEMINI,
        HYPERION: REACT_APP_HYPERION,
        TITAN: REACT_APP_TITAN,
        LIBRA: REACT_APP_LIBRA,
        ORION: REACT_APP_ORION,
        VIRGO: REACT_APP_VIRGO,
        TAURUS: REACT_APP_TAURUS,
      }}
      errorCallback={errorCallback}
    >
      <BrowserRouter>
        <Routes>
          <Route path={PRIVATE.ROOT.path} element={<Navigate to={PUBLIC.LOGIN.path} />} />
          <Route path={PUBLIC.ROOT.path} element={<PublicTemplate />}>
            <Route path={PUBLIC.LOGIN.path} element={<LoginPage />} />
          </Route>
          <Route path={PRIVATE.ROOT.path} element={<PrivateTemplate />}>
            <Route path={TAURUS.APPLICATION.path} element={<TaurusTemplate />}>
              <Route path={TAURUS.MODELS.path} element={<Suspense element={TAURUS.MODELS.element} />} />
              <Route path={TAURUS.MODEL.path} element={<Suspense element={TAURUS.MODEL.element} />}>
                <Route path={TAURUS.MODEL.DEFAULT.path} element={<Suspense element={TAURUS.MODEL.DEFAULT.element} />} />
                <Route path={TAURUS.MODEL.VERSIONS.path} element={<Suspense element={TAURUS.MODEL.VERSIONS.element} />} />
                <Route path={TAURUS.MODEL.OUTPUTS.path} element={<Suspense element={TAURUS.MODEL.OUTPUTS.element} />} />
              </Route>
              <Route path={TAURUS.PIPELINES.path} element={<Suspense element={TAURUS.PIPELINES.element} />} />
              <Route path={TAURUS.PIPELINE_ITEMS.path} element={<Suspense element={TAURUS.PIPELINE_ITEMS.element} />} />
              <Route path={TAURUS.PIPELINE_DEFINITIONS.path} element={<Suspense element={TAURUS.PIPELINE_DEFINITIONS.element} />} />
              <Route path={TAURUS.PIPELINE_DEFINITION.path} element={<Suspense element={TAURUS.PIPELINE_DEFINITION.element} />} />
              <Route path={TAURUS.PIPELINE_QUERY_EXECUTIONS.path} element={<Suspense element={TAURUS.PIPELINE_QUERY_EXECUTIONS.element} />} />
              <Route path={TAURUS.PIPELINE.path} element={<Suspense element={TAURUS.PIPELINE.element} />} />
              <Route path={TAURUS.SQL_TEMPLATES.path} element={<Suspense element={TAURUS.SQL_TEMPLATES.element} />} />
              <Route path={TAURUS.ONE_HOT_MAPPINGS.path} element={<Suspense element={TAURUS.ONE_HOT_MAPPINGS.element} />} />
              <Route path={TAURUS.DATASETS.path} element={<Suspense element={TAURUS.DATASETS.element} />} />
              <Route path={TAURUS.WORKSPACES.path} element={<Suspense element={TAURUS.WORKSPACES.element} />} />
            </Route>
            <Route path="*" element={<ErrorTemplate />}>
              <Route path="*" element={<ErrorPage path={`${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}`} page="Pipelines" />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ClientProvider>
  );
};
