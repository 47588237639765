import { TaurusColumnType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusColumnType[] = [];

export const taurusColumnTypesListSlice = createSlice({
  name: SLICE.TAURUS_COLUMN_TYPES_LIST,
  initialState,
  reducers: {
    clearTaurusColumnTypesList: () => initialState,
    setTaurusColumnTypesList: (_, action: PayloadAction<TaurusColumnType[]>) => action.payload,
  },
});

export const { clearTaurusColumnTypesList, setTaurusColumnTypesList } = taurusColumnTypesListSlice.actions;

export default taurusColumnTypesListSlice.reducer;
