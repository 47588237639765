import { TaurusWorkspace } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusWorkspace[] = [];

export const taurusWorkspacesSlice = createSlice({
  name: SLICE.TAURUS_WORKSPACES,
  initialState,
  reducers: {
    setTaurusWorkspaces: (_, action: PayloadAction<TaurusWorkspace[]>) => {
      return action.payload;
    },
    clearTaurusWorkspaces: () => initialState,
  },
});

export const { setTaurusWorkspaces, clearTaurusWorkspaces } = taurusWorkspacesSlice.actions;

export default taurusWorkspacesSlice.reducer;
