import React, { useCallback, useRef, useState } from 'react';
import { DropdownWrapper, SELECT_POSITION } from '@cognitiv/cassiopeia-ui';
import { mergeSearchQuery } from '@cognitiv/cicada-ui';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkspaceBreadcrumbSelect } from 'components/layout/navigation/WorkspaceBreadcrumbSelect';
import { useQuerySave } from 'hooks/useQuerySave';
import { useTheme } from 'hooks/useTheme';
import { selectTaurusWorkspace } from 'products/taurus/operators/workspace/selectors';
import { selectTaurusWorkspaces } from 'products/taurus/operators/workspaces/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { TAURUS } from 'routes';
import { useAppSelector } from 'store/hooks';

import cn from 'components/layout/navigation/Navigation.module.scss';

export const WorkspaceBreadcrumb = ({ workspace_name, index, active }: { workspace_name: string; index: number; active: boolean }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const taurus_workspace = useAppSelector(selectTaurusWorkspace);
  const taurus_workspaces = useAppSelector(selectTaurusWorkspaces);
  const { getDefaultQueries } = useQuerySave();

  const {
    theme: { breadcrumb, breadcrumb_active },
  } = useTheme();

  const [active_index, setActiveIndex] = useState(-1);
  const ref = useRef<HTMLInputElement | null>(null);

  const { workspace_id_path_split } = taurus_workspace;

  const navigateWorkspaces = useCallback(
    (breadcrumb_workspace_id: number) => {
      // pages that have an entity page
      switch (true) {
        case pathname.includes(TAURUS.MODELS.path):
          {
            const queries = getDefaultQueries('models', breadcrumb_workspace_id);
            navigate(`${TAURUS.APPLICATION.path}/${TAURUS.MODELS.path}${queries}`);
          }
          break;
        case pathname.includes(TAURUS.PIPELINES.path):
          {
            const queries = getDefaultQueries('pipelines', breadcrumb_workspace_id);
            navigate(`${TAURUS.APPLICATION.path}/${TAURUS.PIPELINES.path}${queries}`);
          }
          break;
        case pathname.includes(TAURUS.PIPELINE_DEFINITIONS.path):
          {
            const queries = getDefaultQueries('pipeline_definitions', breadcrumb_workspace_id);
            navigate(`${TAURUS.APPLICATION.path}/${TAURUS.PIPELINE_DEFINITIONS.path}${queries}`);
          }
          break;
        default:
          navigate(`${pathname}${mergeSearchQuery(search, { workspace_id: breadcrumb_workspace_id })}`);
      }
    },
    [getDefaultQueries, navigate, pathname, search],
  );

  return (
    <>
      <div className={cn.breadcrumb} onMouseEnter={() => setActiveIndex(index)} onMouseLeave={() => setActiveIndex(-1)}>
        <DropdownWrapper
          ref={ref}
          onClick={() => {
            navigateWorkspaces(Number(workspace_id_path_split[index]));
            ref.current?.blur();
          }}
          onSelect={(option) => {
            navigateWorkspaces(option.workspace_id);
            setActiveIndex(-1);
          }}
          value={workspace_name}
          theme={active ? breadcrumb_active : breadcrumb}
          select_position={SELECT_POSITION.BOTTOM_FIT}
          autosize
          options={taurus_workspaces}
          option_key="workspace_name"
        >
          <WorkspaceBreadcrumbSelect index={index} active_index={active_index} />
        </DropdownWrapper>
      </div>
      <FontAwesomeIcon icon={faCaretRight} className={cn.icon} />
    </>
  );
};
