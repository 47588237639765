import { TaurusTrainItem } from '@cognitiv/galaxy-api';

export const taurus_train_item_default: TaurusTrainItem = {
  fits: [],
  item_id: 0,
  pipeline_id: 0,
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  item_name: '',
  item_type_id: 0,
  item_type_name: '',
  job_definition_id: null,
  attributes: [],
  required_parent_attributes: [],
  required_parent_count: null,
  job_parameters: [],
  job_id: null,
  item_state_id: 0,
  item_state_name: '',
  progress: 0,
  message: '',
  created_by: '',
  created_date: '',
  is_archived: false,
  description: '',
  is_definition: false,
  parent_item_ids: [],
  search_algorithm_id: null,
  search_algorithm_name: null,
  search_algorithm_json: {},
  cpu: 4,
  cpu_formatted: '4',
  gpu: 0,
  gpu_formatted: '0',
  memory_gb: 16,
  memory_gb_formatted: '16',
  storage_gb: 64,
  storage_gb_formatted: '64',
  location_group_id: 1,
  experiment_group_id: null,
  created_datetime_formatted: '',
  saved: false,
  uuid: '',
  item_name_formatted: '',
  pipeline_name: '',
  pipeline_name_formatted: '',
  progress_formatted: '',
  item_state_name_formatted: '',
  item_id_from: '',
  item_id_to: '',
  cpu_override_formatted: '',
  gpu_override_formatted: '',
  memory_gb_override_formatted: '',
  storage_gb_override_formatted: '',
  cpu_override: null,
  gpu_override: null,
  memory_gb_override: null,
  storage_gb_override: null,
  apply_expiration_policy: false,
  expiration_ttl_days: null,
  expiration_ttl_days_formatted: '',
  compute_costs_estimate_usd_formatted: '',
  compute_costs_estimate_usd: null,
};
