import { TaurusModel, TaurusModelOutput } from '@cognitiv/galaxy-api';

export const taurus_model_output_default: TaurusModelOutput = {
  model_output_name: '',
  model_output_id: 0,
  model_index: 0,
  model_index_formatted: '',
  model_id: 0,
  saved: false,
  uuid: '',
};

export const taurus_model_default: TaurusModel = {
  created_by: '',
  created_by_user_id: 0,
  created_date: '',
  current_model_version_id: null,
  definition_pipeline_id: null,
  definition_pipeline_name: null,
  is_archived: false,
  model_id: 0,
  model_name: '',
  outputs: [],
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  is_active: false,
  current_model_version_created_by_user_id: null,
  current_model_version_created_by: null,
  current_model_version_created_date: null,
  current_model_version_created_datetime_formatted: '',
  created_datetime_formatted: '',
  uuid: '',
};
