import { TaurusQueryExecution } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusQueryExecution[] = [];

export const taurusQueryExecutionsSlice = createSlice({
  name: SLICE.TAURUS_QUERY_EXECUTIONS,
  initialState,
  reducers: {
    clearTaurusQueryExecutions: () => initialState,
    setTaurusQueryExecutions: (_, action: PayloadAction<TaurusQueryExecution[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusQueryExecutions, setTaurusQueryExecutions } = taurusQueryExecutionsSlice.actions;

export default taurusQueryExecutionsSlice.reducer;
