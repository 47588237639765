import { TaurusSplitMethod } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusSplitMethod[] = [];

export const TaurusSplitMethodsListSlice = createSlice({
  name: SLICE.TAURUS_SPLIT_METHODS_LIST,
  initialState,
  reducers: {
    clearTaurusSplitMethodsList: () => initialState,
    setTaurusSplitMethodsList: (_, action: PayloadAction<TaurusSplitMethod[]>) => action.payload,
  },
});

export const { clearTaurusSplitMethodsList, setTaurusSplitMethodsList } = TaurusSplitMethodsListSlice.actions;

export default TaurusSplitMethodsListSlice.reducer;
