import { TaurusModel } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_model_default } from 'products/taurus/operators/model/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusModel = { ...taurus_model_default };

export const taurusModelSlice = createSlice({
  name: SLICE.TAURUS_MODEL,
  initialState,
  reducers: {
    clearTaurusModel: () => initialState,
    setTaurusModel: (_, action: PayloadAction<TaurusModel>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusModel, setTaurusModel } = taurusModelSlice.actions;

export default taurusModelSlice.reducer;
