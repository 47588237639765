import { TaurusModel } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusModel[] = [];

export const taurusModelsSlice = createSlice({
  name: SLICE.TAURUS_MODELS,
  initialState,
  reducers: {
    clearTaurusModels: () => initialState,
    setTaurusModels: (_, action: PayloadAction<TaurusModel[]>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusModels, setTaurusModels } = taurusModelsSlice.actions;

export default taurusModelsSlice.reducer;
