import React, { memo, useContext } from 'react';
import { CellInput, RowContext } from '@cognitiv/cassiopeia-ui';
import { TaurusParameter } from '@cognitiv/galaxy-api';

export const CellParameterKey = memo(({ onChange, read_only = false }: { onChange: (item: TaurusParameter) => void; read_only: boolean }) => {
  const { row } = useContext(RowContext);
  return (
    <CellInput
      debounce={200} // users tab through these quickly
      onChange={({ value }) => onChange({ ...row, parameter_name: String(value) })}
      value={row.parameter_name}
      disabled={read_only}
      text_decoration={row.parameter_striked ? 'line-through' : undefined}
    />
  );
});
