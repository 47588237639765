import { TaurusPublishItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_publish_item_default } from 'products/taurus/operators/publish-item/defaults';
import { SLICE } from 'products/taurus/operators/slice';

interface Payload {
  payload: Partial<TaurusPublishItem>;
}

const initialState: TaurusPublishItem = { ...taurus_publish_item_default };

export const TaurusPublishItemSlice = createSlice({
  name: SLICE.TAURUS_PUBLISH_ITEM,
  initialState,
  reducers: {
    updateTaurusPublishItem: (state, { payload }: Payload) => {
      return { ...state, ...payload };
    },
    clearTaurusPublishItem: () => initialState,
    setTaurusPublishItem: (_, action: PayloadAction<TaurusPublishItem>) => action.payload,
  },
});

export const { updateTaurusPublishItem, clearTaurusPublishItem, setTaurusPublishItem } = TaurusPublishItemSlice.actions;

export default TaurusPublishItemSlice.reducer;
