import { AUTHENTICATION_RESULTS } from 'ducks/user/constants';
import { User, UserAPI } from 'ducks/user/types';
import { setLocalStorage } from 'utils';
import { uuidv4 } from 'utils/uuid';

export const normalizeUser = (user: UserAPI): User => {
  if (user.authentication_result === AUTHENTICATION_RESULTS.SUCCESS) {
    setLocalStorage('user', user);
  }
  return {
    ...user,
    error: null,
    uuid: uuidv4(),
  };
};
