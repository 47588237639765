import { TaurusDatasetPreview } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_dataset_preview_default } from 'products/taurus/operators/dataset-preview/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusDatasetPreview = { ...taurus_dataset_preview_default };

export const taurusDatasetPreviewSlice = createSlice({
  name: SLICE.TAURUS_DATASET_PREVIEW,
  initialState,
  reducers: {
    clearTaurusDatasetPreview: () => initialState,
    setTaurusDatasetPreview: (_, action: PayloadAction<TaurusDatasetPreview>) => {
      return action.payload;
    },
    setTaurusItemDatasetPreview: (_, action: PayloadAction<TaurusDatasetPreview>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusDatasetPreview, setTaurusDatasetPreview, setTaurusItemDatasetPreview } = taurusDatasetPreviewSlice.actions;

export default taurusDatasetPreviewSlice.reducer;
