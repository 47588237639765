import { CONDITION_TYPE, VALIDATE } from '@cognitiv/cicada-ui';

export const validation = {
    email_address: {
        type: VALIDATE.STRING,
        conditions: [
            {
                error: 'Email address field is mandatory. Please enter your email.',
                type: CONDITION_TYPE.MIN_LENGTH,
                values: [1],
            },
        ],
    },
    password: {
        type: VALIDATE.STRING,
        conditions: [
            {
                error: 'Password field is mandatory. Please enter your password.',
                type: CONDITION_TYPE.MIN_LENGTH,
                values: [1],
            },
        ],
    },
};
