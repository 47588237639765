import { TaurusQueryEngine } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusQueryEngine[] = [];

export const taurusQueryEnginesListSlice = createSlice({
  name: SLICE.TAURUS_QUERY_ENGINES_LIST,
  initialState,
  reducers: {
    clearTaurusQueryEnginesList: () => initialState,
    setTaurusQueryEnginesList: (_, action: PayloadAction<TaurusQueryEngine[]>) => action.payload,
  },
});

export const { clearTaurusQueryEnginesList, setTaurusQueryEnginesList } = taurusQueryEnginesListSlice.actions;

export default taurusQueryEnginesListSlice.reducer;
