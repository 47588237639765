import { TaurusQuerySqlPreview } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_query_sql_preview_default } from 'products/taurus/operators/query-sql-preview/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusQuerySqlPreview = { ...taurus_query_sql_preview_default };

export const taurusQuerySqlPreviewSlice = createSlice({
  name: SLICE.TAURUS_QUERY_SQL_PREVIEW,
  initialState,
  reducers: {
    clearTaurusQuerySqlPreview: () => initialState,
    setTaurusQuerySqlPreview: (_, action: PayloadAction<TaurusQuerySqlPreview>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusQuerySqlPreview, setTaurusQuerySqlPreview } = taurusQuerySqlPreviewSlice.actions;

export default taurusQuerySqlPreviewSlice.reducer;
