import { useEffect } from 'react';
import { selectSettings } from 'ducks/settings/selectors';
import { updateSettings } from 'ducks/settings/slices';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLocalStorage } from 'utils';

export enum THEME {
  LIGHT = 'light',
  DARK = 'dark',
}

export const useGlobalTheme = () => {
  const { theme } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();
  const switchTheme = () => {
    const new_theme = theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT;
    dispatch(updateSettings({ theme: new_theme }));
    setLocalStorage('settings', { theme: new_theme });
  };

  useEffect(() => {
    const html = document.querySelector('html');
    (html as HTMLElement).dataset.theme = theme;
  }, [theme]);

  return { switchTheme };
};
