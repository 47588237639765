import { TaurusFilterOperatorType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusFilterOperatorType[] = [];

export const taurusFilterOperatorTypesListSlice = createSlice({
  name: SLICE.TAURUS_FILTER_OPERATOR_TYPES,
  initialState,
  reducers: {
    clearTaurusFilterOperatorTypesList: () => initialState,
    setTaurusFilterOperatorTypesList: (_, action: PayloadAction<TaurusFilterOperatorType[]>) => action.payload,
  },
});

export const { clearTaurusFilterOperatorTypesList, setTaurusFilterOperatorTypesList } = taurusFilterOperatorTypesListSlice.actions;

export default taurusFilterOperatorTypesListSlice.reducer;
