import { TaurusPipelineQueryExecution } from '@cognitiv/galaxy-api';
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPipelineQueryExecution[] = [];

export const taurusItemQueuesSlice = createSlice({
  name: SLICE.TAURUS_ITEM_QUEUES,
  initialState,
  reducers: {
    clearTaurusItemQueues: () => initialState,
    setTaurusItemQueues: (_, { payload }) => {
      return payload;
    },
  },
});

export const { clearTaurusItemQueues, setTaurusItemQueues } = taurusItemQueuesSlice.actions;

export default taurusItemQueuesSlice.reducer;
