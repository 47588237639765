import { TaurusPipelineSchedule } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { taurus_pipeline_schedule_default } from 'products/taurus/operators/pipeline-schedule/defaults';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusPipelineSchedule = { ...taurus_pipeline_schedule_default };

export const taurusPipelineScheduleSlice = createSlice({
  name: SLICE.TAURUS_PIPELINE_SCHEDULE,
  initialState,
  reducers: {
    clearTaurusPipelineSchedule: () => initialState,
    setTaurusPipelineSchedule: (_, action: PayloadAction<TaurusPipelineSchedule>) => {
      return action.payload;
    },
  },
});

export const { clearTaurusPipelineSchedule, setTaurusPipelineSchedule } = taurusPipelineScheduleSlice.actions;

export default taurusPipelineScheduleSlice.reducer;
