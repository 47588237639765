import { STATE_THEME } from 'theme/theme';

export const TAURUS_ROOT = 1;

export enum TAURUS_ITEM_STATE {
  INITIALIZED = 1,
  QUEUED_FOR_START = 2,
  STARTING = 3,
  RUNNING = 4,
  SUCCEEDED = 5,
  QUEUED_FOR_RESET = 90,
  RESETTING = 91,
  QUEUED_FOR_STOP = 96,
  STOPPING = 97,
  STOPPED = 98,
  FAILED = 99,
}

export const TAURUS_ITEM_STATE_NAME = {
  [TAURUS_ITEM_STATE.INITIALIZED]: 'Initialized',
  [TAURUS_ITEM_STATE.QUEUED_FOR_START]: 'Queued',
  [TAURUS_ITEM_STATE.STARTING]: 'Starting',
  [TAURUS_ITEM_STATE.RUNNING]: 'Running',
  [TAURUS_ITEM_STATE.SUCCEEDED]: 'Succeeded',
  [TAURUS_ITEM_STATE.QUEUED_FOR_RESET]: 'Queued (Reset)',
  [TAURUS_ITEM_STATE.RESETTING]: 'Resetting',
  [TAURUS_ITEM_STATE.QUEUED_FOR_STOP]: 'Queued (Stopping)',
  [TAURUS_ITEM_STATE.STOPPING]: 'Stopping',
  [TAURUS_ITEM_STATE.STOPPED]: 'Stopped',
  [TAURUS_ITEM_STATE.FAILED]: 'Failed',
};

export const TAURUS_ITEM_STATISTIC_STATE_NAME = {
  [TAURUS_ITEM_STATE.INITIALIZED]: 'Initialized (Statistics)',
  [TAURUS_ITEM_STATE.QUEUED_FOR_START]: 'Queued (Statistics)',
  [TAURUS_ITEM_STATE.STARTING]: 'Starting (Statistics)',
  [TAURUS_ITEM_STATE.RUNNING]: 'Running (Statistics)',
  [TAURUS_ITEM_STATE.SUCCEEDED]: 'Succeeded (Statistics)',
  [TAURUS_ITEM_STATE.QUEUED_FOR_RESET]: 'Queued (Statistics)',
  [TAURUS_ITEM_STATE.RESETTING]: 'Resetting (Statistics)',
  [TAURUS_ITEM_STATE.QUEUED_FOR_STOP]: 'Queued (Stopping)',
  [TAURUS_ITEM_STATE.STOPPING]: 'Stopping (Statistics)',
  [TAURUS_ITEM_STATE.STOPPED]: 'Stopped (Statistics)',
  [TAURUS_ITEM_STATE.FAILED]: 'Failed (Statistics)',
};

export enum TAURUS_GRAPH_BORDER_COLOR {
  ACTIVE = '#e3ddf2',
  INACTIVE = '#9279f4',
}

export enum TAURUS_ITEM_TYPE {
  CUSTOM = 1,
  CUSTOM_ETL = 2,
  PIPELINE_CREATOR = 3,
  QUERY = 4,
  PROJECTION = 5,
  LABEL = 6,
  SPLIT = 7,
  TRANSFORM = 8,
  IMPORT = 9,
  UNION = 10,
  PIVOT = 11,
  STATISTIC_AGGREGATOR = 12,
  TRAIN = 13,
  EVALUATE = 14,
  PUBLISH = 15,
  DATASET_READ = 16,
  DATASET_WRITE = 17,
  NON_ETL_QUERY = 18,
  PREDICT = 19,
  STRATEGY_PUBLISHER = 20,
}

export const TAURUS_ETL_ITEM_TYPE = [
  TAURUS_ITEM_TYPE.CUSTOM_ETL,
  TAURUS_ITEM_TYPE.QUERY,
  TAURUS_ITEM_TYPE.PROJECTION,
  TAURUS_ITEM_TYPE.TRANSFORM,
  TAURUS_ITEM_TYPE.IMPORT,
  TAURUS_ITEM_TYPE.DATASET_READ,
  TAURUS_ITEM_TYPE.PREDICT,
];

export enum TAURUS_PIPELINE_STATE {
  INCOMPLETE = 1,
  RUNNING = 4,
  SUCCEEDED = 5,
  FAILED = 99,
}

export const TAURUS_PIPELINE_STATE_NAME = {
  [TAURUS_PIPELINE_STATE.INCOMPLETE]: 'Incomplete',
  [TAURUS_PIPELINE_STATE.RUNNING]: 'Running',
  [TAURUS_PIPELINE_STATE.SUCCEEDED]: 'Succeeded',
  [TAURUS_PIPELINE_STATE.FAILED]: 'Failed',
};

export const TAURUS_ITEM_QUERY_RUNNING_STATE = [TAURUS_ITEM_STATE.RUNNING];

export const TAURUS_ITEM_RUNNING_STATE = [
  TAURUS_ITEM_STATE.STARTING,
  TAURUS_ITEM_STATE.RUNNING,
  TAURUS_ITEM_STATE.RESETTING,
  TAURUS_ITEM_STATE.STOPPING,
];

export const TAURUS_READ_ONLY_STATE = [TAURUS_ITEM_STATE.INITIALIZED, TAURUS_ITEM_STATE.QUEUED_FOR_RESET, TAURUS_ITEM_STATE.RESETTING];

export const TAURUS_ITEM_TERMINAL_STATE = [TAURUS_ITEM_STATE.STOPPED, TAURUS_ITEM_STATE.FAILED, TAURUS_ITEM_STATE.SUCCEEDED];

export const TAURUS_ITEM_QUEUED_STATE = [TAURUS_ITEM_STATE.QUEUED_FOR_START, TAURUS_ITEM_STATE.QUEUED_FOR_RESET, TAURUS_ITEM_STATE.QUEUED_FOR_STOP];

export const TAURUS_ITEM_STATE_COLOR = {
  [TAURUS_ITEM_STATE.INITIALIZED]: STATE_THEME.initialized,
  [TAURUS_ITEM_STATE.QUEUED_FOR_START]: STATE_THEME.queued_for_start,
  [TAURUS_ITEM_STATE.STARTING]: STATE_THEME.starting,
  [TAURUS_ITEM_STATE.RUNNING]: STATE_THEME.running,
  [TAURUS_ITEM_STATE.SUCCEEDED]: STATE_THEME.succeeded,
  [TAURUS_ITEM_STATE.QUEUED_FOR_RESET]: STATE_THEME.queued_for_reset,
  [TAURUS_ITEM_STATE.RESETTING]: STATE_THEME.resetting,
  [TAURUS_ITEM_STATE.QUEUED_FOR_STOP]: STATE_THEME.queued_for_stop,
  [TAURUS_ITEM_STATE.STOPPING]: STATE_THEME.stopping,
  [TAURUS_ITEM_STATE.STOPPED]: STATE_THEME.stopped,
  [TAURUS_ITEM_STATE.FAILED]: STATE_THEME.failed,
};

export const TARUS_PIPELINE_STATE_COLOR = {
  [TAURUS_PIPELINE_STATE.INCOMPLETE]: STATE_THEME.incomplete,
  [TAURUS_PIPELINE_STATE.RUNNING]: STATE_THEME.running,
  [TAURUS_PIPELINE_STATE.SUCCEEDED]: STATE_THEME.succeeded,
  [TAURUS_PIPELINE_STATE.FAILED]: STATE_THEME.failed,
};
