import React from 'react';
import { ThemeProvider, useRootTheme } from '@cognitiv/cassiopeia-ui';
import { Loading } from 'components/layout/loading/Loading';
import { LOADING_VARIANTS } from 'components/layout/loading/types';
import { Snackbar } from 'components/layout/snackbar/Snackbar';
import { MenuContext } from 'components/menu/context/MenuContext';
import { selectSettings } from 'ducks/settings/selectors';
import { selectUserAuthentication } from 'ducks/user/selectors';
import { useTheme } from 'hooks/useTheme';
import { Confirm } from 'modals/utility/Confirm';
import { Error } from 'modals/utility/Error';
import { Outlet } from 'react-router';
import { Navigate, useLocation } from 'react-router-dom';
import { PUBLIC } from 'routes';
import { useAppSelector } from 'store/hooks';

import cn from 'templates/Template.module.scss';

export const PrivateTemplate = () => {
  const location = useLocation();
  const authenticated = useAppSelector((state) => selectUserAuthentication(state));
  const { theme: theme_variant } = useAppSelector(selectSettings);

  const {
    theme: { framework },
  } = useTheme();
  useRootTheme(theme_variant, 'taurus');

  if (!authenticated) {
    return <Navigate to={PUBLIC.LOGIN.path} state={{ referer: `${location.pathname}${location.search}` }} />;
  }

  return (
    <ThemeProvider theme_variant={theme_variant} theme={framework}>
      <MenuContext>
        <div className={cn.private}>
          <Outlet />
          <Snackbar />
          <Error />
          <Confirm />
          <Loading variant={LOADING_VARIANTS.DARK} />
        </div>
      </MenuContext>
    </ThemeProvider>
  );
};
