import { TaurusCustomEtlItemColumn, TaurusPartitionedBaseColumn, TaurusQueryItemColumn } from '@cognitiv/galaxy-api';

export const taurus_partitioned_column_default: TaurusPartitionedBaseColumn = {
  feature_name: '',
  unique_feature_name: '',
  column_id: 0,
  dataset_id: null,
  column_index: null,
  column_type_id: 0,
  column_type_name: '',
  column_name: '',
  feature_identifier: '',
  extended_data: '',
  label_type_id: null,
  label_type_name: '',
  label_group: '',
  data_type_id: null,
  data_type_name: '',
  array_length: null,
  partition_on: false,
  partition_name: null,
  partition_count: null,
  is_array: false,
  array_length_formatted: '',
  column_index_formatted: '',
  partition_count_formatted: '',
  saved: false,
  uuid: '',
};

export const taurus_custom_etl_column_default: TaurusCustomEtlItemColumn = { ...taurus_partitioned_column_default };

export const taurus_query_column_default: TaurusQueryItemColumn = { ...taurus_partitioned_column_default };
