import { selectSettings } from 'ducks/settings/selectors';
import { THEME } from 'hooks/useGlobalTheme';
import { useAppSelector } from 'store/hooks';
import { colors } from 'theme/colors';

const light = {
  framework: {
    primary: {
      color: {
        disabled: colors.text_light.invert,
      },
      background: {
        normal: colors.purple[6],
        hover: colors.purple[5],
        focus: colors.purple[6],
        pressed: colors.purple[7],
        active: colors.purple[7],
        disabled: colors.purple[2],
      },
    },
    secondary: {
      color: {
        normal: colors.purple[6],
        hover: colors.purple[6],
        pressed: colors.purple[6],
        active: colors.purple[6],
      },
      background: {
        normal: colors.purple[1],
        hover: colors.purple[2],
        focus: colors.purple[1],
        pressed: colors.purple[3],
        active: colors.purple[3],
      },
    },
    quaternary: {
      background: {
        hover: colors.purple[6],
        focus: colors.purple[4],
        pressed: colors.purple[4],
        active: colors.purple[4],
      },
    },
    subtle: {
      background: {
        focus: colors.neutral[1],
      },
    },
    plain: {
      color: {
        hover: colors.blue[8],
        focus: colors.blue[6],
        pressed: colors.blue[5],
        active: colors.blue[5],
      },
    },
    switch: {
      outline: `1px solid ${colors.purple[6]}`,
    },
    progress: {
      bar_background: colors.purple[6],
    },
  },
  banner: {
    banner: {
      general: {
        color: colors.neutral[10],
        description_color: colors.neutral[10],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.neutral[3],
      },
      border: {
        normal: `1px solid ${colors.neutral[3]}`,
      },
    },
  },
  cell_remove: {
    variant: { color: { normal: colors.red[6], hover: colors.red[4], focus: colors.red[6], active: colors.red[7] } },
  },
  cell_remove_disabled: {
    variant: { color: { normal: colors.platinum[8], hover: colors.platinum[8], focus: colors.platinum[8], active: colors.platinum[8] } },
  },
  button_navigation: {
    variant: {
      background: { normal: colors.navy[8], hover: colors.navy[7], active: colors.navy[10] },
      color: { normal: colors.text_light.invert, hover: colors.text_light.invert, active: colors.text_light.invert },
    },
  },
  button_navigation_active: {
    variant: {
      background: { normal: colors.navy[8], hover: colors.navy[7], active: colors.navy[10] },
      color: { normal: colors.purple[6], hover: colors.purple[6], active: colors.purple[6] },
    },
  },
  action: {
    label: {
      font: { general: { font_size_normal: '15px', font_weight: 500 } },
      variant: { color: { normal: colors.neutral[10], hover: colors.purple[5], focus: colors.purple[6], active: colors.purple[6] } },
    },
    link: { action: { gap: '4px' }, variant: { color: { normal: colors.purple[6], hover: colors.purple[5], active: colors.purple[6] } } },
    normal: { action: { gap: '4px' }, variant: { color: { hover: colors.purple[5], active: colors.purple[6] } } },
  },
  dropdown_menu: {
    field: {
      border: {
        normal: `1px solid ${colors.neutral[3]}`,
        hover: `1px solid ${colors.neutral[4]}`,
      },
      background: { normal: colors.neutral[3], hover: colors.neutral[4], focus: colors.neutral[4] },
    },
    select: { background: colors.neutral[1] },
    option: { general: { padding: '8px' } },
  },
  dropdown_icon_subtle: {
    field: {
      border: {
        normal: 'none',
        hover: 'none',
        active: 'none',
        disabled: 'none',
        focus: `1px solid ${colors.blue[6]}`,
      },
      background: { normal: 'transparent', hover: colors.neutral[3], active: colors.neutral[5], disabled: colors.neutral[1], focus: 'transparent' },
    },
  },
  checkbox: {
    background: colors.general.white,
  },
  generic: {
    on_color: colors.platinum[9],
    off_color: colors.navy[7],
    row_disabled_color: colors.blue[1],
    row_warning_color: colors.neutral[4],
    banner_primary: colors.blue[6],
    banner_secondary: colors.neutral[10],
  },
  bar_chart: {
    axis_color: colors.neutral[9],
    grid_color: colors.neutral[3],
  },
  ellipsis_menu: {
    field: {
      color: { hover: colors.blue[8], focus: colors.blue[6], pressed: colors.blue[5] },
      background: { normal: 'transparent', hover: 'transparent', pressed: 'transparent', selected: 'transparent', focus: 'transparent' },
    },
  },
  breadcrumb: {
    field: {
      color: {
        normal: colors.neutral[1],
        hover: colors.neutral[1],
        focus: colors.neutral[1],
      },
      background: { normal: 'transparent', hover: colors.navy[7], focus: colors.navy[10], pressed: colors.navy[10], active: colors.navy[9] },
      border: { normal: '1px solid transparent', focus: `1px solid ${colors.blue[6]}`, hover: `1px solid ${colors.blue[6]}` },
    },
  },
  breadcrumb_active: {
    field: {
      color: {
        normal: colors.purple[6],
        hover: colors.purple[6],
        focus: colors.purple[6],
      },
      background: { normal: 'transparent', hover: colors.navy[7], focus: colors.navy[10], pressed: colors.navy[10], active: colors.navy[9] },
      border: { normal: '1px solid transparent', focus: `1px solid ${colors.blue[6]}`, hover: `1px solid ${colors.blue[6]}` },
    },
  },
};
const dark = {
  framework: {
    primary: {
      color: {
        focus: colors.blue[6],
        disabled: colors.purple[8],
      },
      background: {
        normal: colors.purple[6],
        hover: colors.purple[7],
        focus: colors.purple[6],
        pressed: colors.purple[5],
        active: colors.purple[5],
        disabled: colors.purple[10],
      },
    },
    secondary: {
      color: {
        normal: colors.neutral[1],
        hover: colors.neutral[1],
        focus: colors.blue[6],
        pressed: colors.neutral[1],
        active: colors.neutral[1],
        disabled: colors.platinum[5],
      },
      background: {
        normal: colors.purple[9],
        hover: colors.purple[8],
        focus: colors.purple[9],
        pressed: colors.purple[6],
        active: colors.purple[6],
        disabled: colors.platinum[10],
      },
    },
    tertiary: {
      color: {
        focus: colors.blue[6],
        disabled: colors.platinum[5],
      },
      background: {
        focus: colors.platinum[9],
        disabled: colors.platinum[10],
      },
    },
    quaternary: {
      background: {
        hover: colors.purple[6],
        focus: colors.purple[4],
        pressed: colors.purple[4],
        active: colors.purple[4],
      },
    },
    subtle: {
      color: {
        focus: colors.blue[6],
        disabled: colors.platinum[5],
      },
      background: {
        normal: 'transparent',
        hover: colors.platinum[10],
        focus: colors.neutral[10],
        pressed: colors.platinum[9],
        active: colors.platinum[9],
        disabled: 'transparent',
      },
    },
    switch: {
      outline: `1px solid ${colors.purple[6]}`,
    },
    progress: {
      bar_background: colors.purple[6],
    },
    option: {
      background: {
        normal: 'transparent',
      },
    },
  },
  banner: {
    banner: {
      general: {
        color: colors.neutral[3],
        description_color: colors.neutral[3],
        font_size: '13px',
        font_weight: 400,
        padding: '8px',
        box_shadow: 'none',
        border_radius: '4px',
      },
      background: {
        normal: colors.platinum[9],
      },
      border: {
        normal: `1px solid ${colors.platinum[9]}`,
      },
    },
  },
  cell_remove: {
    variant: { color: { normal: colors.red[6], hover: colors.red[4], focus: colors.red[6], active: colors.red[7] } },
  },
  cell_remove_disabled: {
    variant: { color: { normal: colors.platinum[8], hover: colors.platinum[8], focus: colors.platinum[8], active: colors.platinum[8] } },
  },
  button_navigation: {
    variant: {
      background: { normal: colors.platinum[9], hover: colors.platinum[8], active: colors.neutral[10] },
      color: { normal: colors.text_light.invert, hover: colors.text_light.invert, active: colors.text_light.invert },
    },
  },
  button_navigation_active: {
    variant: {
      background: { normal: colors.platinum[9], hover: colors.platinum[8], active: colors.neutral[10] },
      color: { normal: colors.purple[6], hover: colors.purple[5], active: colors.purple[6] },
    },
  },
  action: {
    label: {
      font: { general: { font_size_normal: '15px' } },
      variant: { color: { normal: colors.neutral[3], hover: colors.purple[5], focus: colors.purple[6], active: colors.purple[6] } },
    },
    link: { action: { gap: '4px' }, variant: { color: { normal: colors.purple[6], hover: colors.purple[5], active: colors.purple[6] } } },
    normal: { action: { gap: '4px' }, variant: { color: { hover: colors.purple[5], active: colors.purple[6] } } },
  },
  dropdown_menu: {
    field: {
      border: {
        normal: `1px solid ${colors.platinum[9]}`,
        hover: `1px solid ${colors.platinum[8]}`,
      },
      background: { normal: colors.platinum[9], hover: colors.platinum[8], focus: colors.platinum[9] },
    },
    option: {
      background: {
        normal: colors.neutral[10],
        hover: colors.platinum[10],
        focus: colors.platinum[10],
        pressed: colors.platinum[7],
        selected: colors.blue[10],
      },
      general: { padding: '8px' },
    },
    select: { background: colors.neutral[10] },
  },
  dropdown_icon_subtle: {
    field: {
      border: {
        normal: 'none',
        hover: 'none',
        active: 'none',
        disabled: 'none',
        focus: `1px solid ${colors.blue[6]}`,
      },
      background: { normal: 'transparent', hover: colors.platinum[10], active: colors.platinum[9], disabled: 'transparent', focus: 'transparent' },
    },
  },
  checkbox: {
    background: colors.neutral[10],
  },
  generic: {
    on_color: colors.platinum[9],
    off_color: colors.navy[7],
    row_disabled_color: colors.blue[10],
    row_error_color: colors.platinum[10],
    row_warning_color: colors.platinum[8],
    banner_primary: colors.blue[6],
    banner_secondary: colors.neutral[1],
  },
  bar_chart: {
    axis_color: colors.neutral[3],
    grid_color: colors.platinum[8],
  },
  ellipsis_menu: {
    field: {
      color: { hover: colors.blue[5], focus: colors.blue[6], pressed: colors.blue[8] },
      background: { normal: 'transparent', hover: 'transparent', pressed: 'transparent', selected: 'transparent', focus: 'transparent' },
    },
  },
  breadcrumb: {
    field: {
      background: {
        normal: 'transparent',
        hover: colors.platinum[8],
        focus: colors.platinum[9],
        pressed: colors.platinum[9],
        active: colors.platinum[9],
      },
      border: { normal: '1px solid transparent', focus: `1px solid ${colors.blue[6]}`, hover: `1px solid ${colors.blue[6]}` },
    },
  },
  breadcrumb_active: {
    field: {
      color: {
        normal: colors.purple[6],
        hover: colors.purple[6],
        focus: colors.purple[6],
      },
      background: {
        normal: 'transparent',
        hover: colors.platinum[8],
        focus: colors.platinum[9],
        pressed: colors.platinum[9],
        active: colors.platinum[9],
      },
      border: { normal: '1px solid transparent', focus: `1px solid ${colors.blue[6]}`, hover: `1px solid ${colors.blue[6]}` },
    },
  },
};

export const useTheme = () => {
  const { theme } = useAppSelector(selectSettings);

  return { theme: theme === THEME.LIGHT ? light : dark, theme_variant: theme, is_dark: theme === THEME.DARK };
};
