import { TaurusItem } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusItem[] = [];

export const taurusItemsSlice = createSlice({
  name: SLICE.TAURUS_ITEMS,
  initialState,
  reducers: {
    updateTaurusItem: (state, action) => {
      const { item_id, item_name } = action.payload;
      return state.map((i) => {
        if (i.item_id === item_id) {
          return {
            ...i,
            item_name,
          };
        }
        return i;
      });
    },
    clearTaurusItems: () => initialState,
    setTaurusItems: (_, action: PayloadAction<TaurusItem[]>) => {
      return action.payload;
    },
  },
});

export const { updateTaurusItem, clearTaurusItems, setTaurusItems } = taurusItemsSlice.actions;

export default taurusItemsSlice.reducer;
