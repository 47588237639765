import { TaurusDatasetPartition } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusDatasetPartition[] = [];

export const taurusDatasetPartitionsSlice = createSlice({
  name: SLICE.TAURUS_DATASET_PARTITIONS,
  initialState,
  reducers: {
    clearTaurusDatasetPartitions: () => initialState,
    setTaurusDatasetPartitions: (_, action: PayloadAction<TaurusDatasetPartition[]>) => action.payload,
  },
});

export const { clearTaurusDatasetPartitions, setTaurusDatasetPartitions } = taurusDatasetPartitionsSlice.actions;

export default taurusDatasetPartitionsSlice.reducer;
