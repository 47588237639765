import { TaurusWorkspace } from '@cognitiv/galaxy-api';

export const taurus_workspace_default: TaurusWorkspace = {
  workspace_id: 0,
  workspace_name: '',
  workspace_path: '',
  workspace_id_path: '',
  parent_workspace_id: null,
  workspace_path_split: [],
  workspace_id_path_split: [],
  parameters: [],
  inherited_parameters: [],
  cumulative_parameters: [],
  created_by: '',
  created_date: '',
  is_archived: false,
  created_datetime_formatted: '',
  uuid: '',
};
