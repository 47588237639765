import { TaurusOutputType } from '@cognitiv/galaxy-api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE } from 'products/taurus/operators/slice';

const initialState: TaurusOutputType[] = [];

export const taurusOutputTypesListSlice = createSlice({
  name: SLICE.TAURUS_OUTPUT_TYPES_LIST,
  initialState,
  reducers: {
    clearTaurusOutputTypesList: () => initialState,
    setTaurusOutputTypesList: (_, action: PayloadAction<TaurusOutputType[]>) => action.payload,
  },
});

export const { clearTaurusOutputTypesList, setTaurusOutputTypesList } = taurusOutputTypesListSlice.actions;

export default taurusOutputTypesListSlice.reducer;
