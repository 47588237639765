import { TaurusMaxResult } from 'products/taurus/operators/max-results-list/types';

export const taurus_max_results_list_default: TaurusMaxResult[] = [
  {
    max_row: 50,
    uuid: 1,
  },
  {
    max_row: 100,
    uuid: 2,
  },
  {
    max_row: 200,
    uuid: 3,
  },
  {
    max_row: 500,
    uuid: 4,
  },
];
